import React, { useState } from 'react';
import styled from 'styled-components';
import Section1 from './Section1';
import corporate from './assets/corporate.jpg';
import TopBar from 'ui-components/TopBar';
import List from './List';
import Contact from './Contact';
import { sendCorporate } from 'api';

const Root = styled.div`
  padding-top: 25px;
  @media portrait{
    padding: 0;
  }
`;
const P = styled.p`
  max-width: ${610/20}em;
`;
export default function Corporate({...props}){
  
  const [topBar, setTopBar] = useState(null);
  
  return (
    <Root {...props}>
      <TopBar ref={setTopBar}/>
      <Section1 title="Corporate" src={corporate}>
        Asseguramos a prestação de serviços de excelência aos nossos clientes empresariais, através da identificação inicial cuidada dos fatores chave de cada negócio, para uma perfeita adequação dos serviços a oferecer e uma otimização dos respetivos custos. 
        <br/><br/>
        As nossas competências incluem reservas de avião, comboio, rent-a-car transferes e hotel, com um serviço 24hrs para apoio personalizado em situações imprevistas. Dispomos de uma grande experiência e de inúmeras parcerias comerciais com alguns dos maiores e melhores fornecedores nacionais e internacionais, o que nos permite negociar não só excelentes acordos, como apresentar as melhores soluções para a sua empresa, com garantia de qualidade e segurança.
      </Section1>
      <List topBar={topBar} title="As vantagens de usar a nossa agência para os seus negócios:">
        <li>Encontre tudo o que precisa para as viagens de negócios num só local.</li>
        <li>Atendimento personalizado e exclusivo para as necessidades de cada cliente.</li>
        <li>Faça a gestão das viagens dos seus colaboradores num único sítio.</li>
        <li>Reserve a qualquer hora do dia e em qualquer lugar.</li>
        <li>Todos os serviços da mesma viagem numa única fatura à sua empresa.</li>
      </List>
      <Contact
        formTitle="Nós entramos em contacto"
        formFields={[
          {placeholder:'Nome*',name:'nome',required:true},
          {placeholder:'E-mail*',name:'email',required:true},
          {placeholder:'Telefone*',name:'telefone',required:true},
          {placeholder:'Empresa*',name:'empresa',required:true},
        ]}
        endpoint={sendCorporate}
      >
        <P>
          A nossa equipa possui um profundo conhecimento do mercado, que lhe permite identificar e apresentar as melhores soluções, de acordo com as expectativas de cada cliente e as características de cada viagem. A nossa filosofia de serviço altamente personalizado, com grande atenção ao detalhe, resulta num elevado padrão de qualidade e de satisfação por parte dos nossos clientes.
        </P>
        <P><strong>
          Fazemos um orçamento de viagem à medida da sua empresa. Por favor preencha o formulário ou ligue-nos para o <a href="tel:+351255814009">+351 255 814 009</a>.
        </strong></P>
      </Contact>
    </Root>
  );
}
