import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TopBar from 'ui-components/TopBar';
import Header from './Header/Header';
import HoneyMoon from './HoneyMoon';
import Section1 from './Section1';
import Testimonials from './Testimonials';
import SpecialOffers from './SpecialOffers';
import CustomOffer from './CustomOffer';
import CustomTripForm from 'ui-components/CustomTripForm';
import CookiesPopup from 'ui-components/CookiesPopup';

const Root = styled.div`
  
`;
const Spacer = styled.div`
  height: 50pxva;
  @media portrait{
    height: 30px;
  }
`;

export default function Home({...props}){
  
  const [topBar, setTopBar] = useState(null);

  const [customTripFormOpen, setCustomTripFormOpen] = useState(false);

  const panelColor = '#2F0283';

  const [panelOpen, setPanelOpen] = useState(false);

  useEffect(()=>{
    if(customTripFormOpen)
      setPanelOpen(true);
    else
      setTimeout(()=>{
        setPanelOpen(false);
      },[300]);
  },[customTripFormOpen]);

  return (
    <Root {...props}>
      <Header/>
      <CookiesPopup/>
      <Spacer/>
      <TopBar panelColor={panelColor} panelOpen={panelOpen} ref={setTopBar}/>
      <Section1/>
      <CustomTripForm open={customTripFormOpen} setOpen={setCustomTripFormOpen}/>
      <HoneyMoon topBar={topBar}/>
      <Testimonials/>
      <SpecialOffers/>
      <CustomOffer setCustomTripFormOpen={setCustomTripFormOpen}/>
    </Root>
  );
}
