import colors from 'colors';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TopBar from 'ui-components/TopBar';
import arrow from 'ui-components/assets/arrow-right.svg';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import { useParams } from 'react-router-dom';
import GenericForm from 'ui-components/GenericForm';
import InputText from 'ui-components/InputText';
import InputNumber from 'ui-components/InputNumber';
import Button from 'ui-components/Button';
import portugal from 'temp-data/portugal';
import espanha from 'temp-data/espanha';
import europa from 'temp-data/europa';
import InputRadio from 'ui-components/InputRadio';
import colorOpacity from 'color-opacity';
import { sendPrebook } from 'api';
import plus from 'ui-components/assets/plus.svg';
import minus from 'ui-components/assets/minus.svg';

const Root = styled.div`
  padding-top: 25px;
  @media portrait{
    padding: 0;
  }
`;
const Layout = styled.div`
  background: ${colors.background2};

  display: grid;
  grid-template-columns: 1fr 980pxva;

  @media landscape{
    margin-top: 25px;
    & > div:nth-child(2){
      padding: 35pxva 85pxva 85pxva;
      & > div{
        position: sticky;
        top: ${77+35}pxva;
      }
    }
  }

  @media portrait{
    grid-template-columns: 1fr;
    & > div:nth-child(1){
      height: 100vw;
      overflow-y: scroll;
    }
  }
  @media tablet{
    & > div:nth-child(1){
      height: 50vw;
    }
  }
`;
const Picture = styled.div`
  padding-top: 56%;
  background: url(${p=>p.$src}) center / cover no-repeat;

  @media mobile{
    padding-top: 100%;
  }
  @media tablet{
    padding-top: 50%;
  }
`;
const Back = styled.div`
  font-size: 20pxva;

  display: inline-block;
  width: ${28/20}em;
  height: ${20/20}em;
  background: url(${arrow}) center / contain no-repeat;
  transform: scaleX(-1);

  @media portrait{
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 18px;
  }

  cursor: pointer;
  
  //todo: put in a separate file
  &:hover{
    filter: brightness(120%);
  }
`;
const Title = styled.div`
  font-family: ${secondaryFontFamily};
  font-size: 50pxva;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: -0.025em;
  color: ${colors.primary};

  margin-top: ${30/50}em;

  @media portrait{
    font-size: 50px;
    margin-left: 16px;
  }
`;
const FlightAndHotel = styled.div`
  font-size: 20px;
  line-height: 1.35;

  color: ${colors.primary};
  margin-top: 15px;
  
  ${Root}[data-alt=true] & br:last-of-type{
    display: none;
  }

  @media portrait{
    margin-left: 16px;
  }
`;
const Stars = styled.span`
  content: '★★★★★';
  color: ${colors.secondary};
  ${Root}[data-alt=true] &{
    margin-left: 0.5em;
  }
`;
const Price = styled.div`
  font-size: 16px;
  line-height: 1.19;

  margin-top: 12px;

  color: ${colors.primary};
  
  & strong{
    font-weight: 700;
  }

  @media portrait{
    margin-left: 16px;
  }
`;
const Tabs = styled.div`
  @media portrait{
    display: none;
  }

  margin-top: 35px;

  display: grid;
  grid-template-columns: repeat(4,1fr);
  &[data-threecols=true]{
    grid-template-columns: repeat(3,1fr);
  }

  margin-bottom: 37px;
`;
const Tab = styled.div`
  @media portrait{
    display: none;
  }

  font-weight: 700;
  text-align: center;
  font-size: 16pxva;
  line-height: ${52/16};
  height: ${52/16}em;

  text-transform: uppercase;

  color: ${colors.primary};
  border-bottom: ${2/16}em solid ${colors.background};

  &[data-selected=true]{
    color: ${colors.secondary};
    background: ${colors.background};
  }

  cursor: pointer;
  &:hover{
    color: ${colors.secondary};
  }
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  @media not_mobile{
    &[data-double]{
      grid-template-columns: 1fr 1fr;
    }
  }
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 141px 1fr;
  column-gap: 20px;

  border-bottom: 2px solid ${colors.background};

  & > div{
    font-size: 16pxva;
    line-height: 1.2;
    padding: 15px 0;
    color: ${colors.foreground};
    &:nth-child(2){
      font-weight: 600;
    }
  }

  @media portrait{
    column-gap: 20px;
    grid-template-columns: 1fr 2.2fr;
    & > div{
      font-size: 16px;
    }
  }

`;
const Text = styled.p`
  font-size: 20pxva;
  line-height: 1.35;
  white-space: pre-wrap;

  color: ${colors.foreground};

  @media portrait{
    font-size: 20px;
  }
`;
const TextTitle = styled(Text).attrs({
  as: 'h4',
})`
  font-weight: 600;
  margin-bottom: 0.75em;

  ${Table} + &{
    margin-top: 45px;
  }
`;
const BookForm = styled(GenericForm)`
  position: relative;
`;
const FormFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 26pxva;

  margin-top: 40pxva;

  & > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & button{
    width: 292pxva;
  }

  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 28px;
    & button{
      margin-top: 20px;
    }
  }
  @media mobile{
    & button{
      width: 100%;
    }
  }
`;
const Error = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  text-transform: uppercase;
  color: ${colors.secondary};
`;
const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.background};

  color: ${colors.foreground};
  font-size: 14px;
  line-height: 1.57;
  text-transform: uppercase;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
const SubText = styled.div`
  font-weight: 700;
  font-size: 10px;
  margin-top: 9px;
  margin-bottom: -2px;
  padding-left: 16px;
  text-transform: uppercase;
  color: ${colorOpacity(colors.foreground,0.3)};
`;
const InputDateHalfWidth = styled(InputText).attrs({
  type: 'date',
})`
  display: inline-block;
  width: 230px;
  @media portrait{
    width: 100%;
    & ~ &{
      width: 100%;
    }
  }
`;
const InputPaddingHalfWidth = styled(InputText).attrs({
  readOnly: true,
})`
  pointer-events: none;
  width: calc(100% - 460px);
  @media portrait{
    display: none;
  }
`;
const Desktop = styled.div`
  @media portrait{
    display: none;
  }
`;
const MobileBook = styled.div`
  @media landscape{
    display: none;
  }
  padding: 22px 16px 40px;

  & > div{
    border: none;
  }
`;
const MobileBookButton = styled(Button)`
  width: 100%;
  @media portrait{
    margin-bottom: -20px;
    [data-open=true] &{
      background: ${colorOpacity(colors.foreground,0.1)};
    }
  }
`;
const MobileTab = styled.div`
  border-top: 1px solid ${colorOpacity(colors.foreground,0.3)};

  height: 58px;
  overflow-y: hidden;
  transition: height 500ms ease-in-out;
  &[data-open=true]{
    height: ${p => p.$height ? p.$height+'pxc' : 'auto'};
  }

  @media landscape{
    display: none;
  }
`;
const MobileTabContent = styled.div`
  padding: 24px 16px 56px;
`;
const TabState = props=>{
  const [open, setOpen] = useState(true);

  const handleClick = useCallback(()=>{
    setOpen(x => !x);
  },[setOpen]);

  const [height, setHeight] = useState(null);

  const tabRef = useRef();
  useEffect(()=>{
    const tabTitle = tabRef.current.firstElementChild;
    tabTitle.addEventListener('click',handleClick);
    return ()=>{
      tabTitle.removeEventListener('click',handleClick);
    };
  },[tabRef,handleClick]);
  
  useLayoutEffect(()=>{
    if(height)
      return; 
    setHeight(tabRef.current.offsetHeight);
    setOpen(false);
  },[setHeight,setOpen,open,height,tabRef]);

  return <MobileTab
    ref={tabRef}
    $height={height}
    data-open={open}
    {...props}
  />
};
const TabTitle = styled.h3`
  font-family: ${secondaryFontFamily};
  font-size: 30px;
  line-height: 58px;
  font-weight: 500;
  letter-spacing: -0.015em;
  color: ${colors.secondary};
  padding-left: 16px;

  background: url(${plus}) center right 16px / 16px no-repeat;
  transition: background-image 200ms 200ms;
  [data-open=true] > &{
    background-image: url(${minus});
  }

  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

const destinations = {
  portugal,
  espanha,
  europa,
};

export default function Destination({
  ...props
}){
  
  const {id,category} = useParams();

  const destination = destinations[category].filter(x => x.id === id)[0];

  const [tab, setTab] = useState('about');

  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const about = <>
    <Text>
      {destination.about}
    </Text>
  </>;
  const hotel = <>
    <Table>
      {destination.hotel.map((row,idx) => (
        <TableRow key={idx}>
          <div>{row[0]}</div>
          <div>{row[1]}</div>
        </TableRow>
      ))}
    </Table>
    {destination.notesHotel ? <>
      <TextTitle>Notas adicionais:</TextTitle>
      <Text>{destination.notesHotel}</Text>
    </>:null}
  </>;
  const flight = destination.flight && <>
    <Table data-double>
      {destination.flight.map((row,idx) => (
        <TableRow key={idx}>
          <div>{row[0]}</div>
          <div>{row[1]}</div>
        </TableRow>
      ))}
    </Table>
    {destination.notesFlight ? <>
      <TextTitle>Notas adicionais:</TextTitle>
      <Text>{destination.notesFlight}</Text>
    </>:null}
  </>;
  const book = <>
    <BookForm
      setError={setError}
      setSent={setSent}
      setSending={setSending}
      endpoint={sendPrebook}
    >
      <InputText name="nome"
        placeholder="Nome do responsável pela reserva*" 
        required={true}
        variant="ultralight"
      />
      <InputText name="email"
        placeholder="E-mail*" 
        type="email"
        required={true}
        variant="ultralight"
      />
      <InputText name="telefone"
        placeholder="Telefone*" 
        required={true}
        variant="ultralight"
      />
      <InputNumber name="numeroPessoas"
        placeholder="Número de pessoas" 
        variant="ultralight"
        min={0}
        max={12}
      />
      <InputRadio name="transferes"
        placeholder="Transferes" 
        variant="ultralight"
        options={[
          {
            label: 'Sim',
            value: 'Sim',
          },{
            label: 'Não',
            value: 'Não',
          },
        ]}
      />
      <SubText>
        Verificar disponibilidade para outras datas?
      </SubText>
      <InputDateHalfWidth name="partida" placeholder="Partida" variant="ultralight"/>
      <InputDateHalfWidth name="chegada" placeholder="Chegada" variant="ultralight"/>
      <InputPaddingHalfWidth variant="ultralight"/>
      <input type="hidden" name="destino" value={destination.title}/>
      <input type="hidden" name="info1" value={destination.info[0]}/>
      <input type="hidden" name="info2" value={destination.info[1]}/>
      <input type="hidden" name="details1" value={destination.details[0]}/>
      <input type="hidden" name="details2" value={destination.details[1]}/>
      
      <FormFooter>
        <div>
          <Button disabled={sending}>Enviar</Button>
        </div>
        <div>
          {error ? (
            <Error>
              Falha no envio, tente novamente mais tarde.
            </Error>
          ):null}
        </div>
      </FormFooter>
      {sent ? <Success>
        Mensagem enviada com sucesso.<br/>
        Em breve entramos em contato.
      </Success> : null}
    </BookForm>
  </>;

  return destination ? (
    <Root {...props}>
      <TopBar/>
      <Layout>
        <div>
          {destination.pictures.map((src,idx) => <Picture key={idx} $src={src}/>)}
          {destination.hotelPictures.map((src,idx) => <Picture key={idx} $src={src}/>)}
        </div>
        <div>
          <div>
            <Back onClick={()=>window.history.go(-1)}/>
            <Title>{destination.title}</Title>
            <FlightAndHotel>
              {destination.info.map((text,idx) => <Fragment key={idx}>
                {text}{
                 idx === 0 ? <>
                  <span> </span><Stars>
                    {[...Array(
                      destination.stars
                    ).keys()].map(()=>'★').join('')}
                  </Stars>
                 </>:null
                }<br/>
              </Fragment>)}
            </FlightAndHotel>
            <Price>
              Desde <strong>{destination.price}</strong>
            </Price>
            <Tabs data-threecols={!destination.flight}>
              <Tab onClick={()=>setTab('about')} data-selected={tab==='about'}>Sobre</Tab>
              <Tab onClick={()=>setTab('hotel')} data-selected={tab==='hotel'}>Hotel</Tab>
              {destination.flight ? <Tab onClick={()=>setTab('flight')} data-selected={tab==='flight'}>Voo</Tab> : null}
              <Tab onClick={()=>setTab('book')} data-selected={tab==='book'}>Pré-reserva</Tab>
            </Tabs>
            <Desktop>
              {tab==='about'?<>
                {about}
              </>:tab==='hotel'?<>
                {hotel}
              </>:tab==='flight'&&destination.flight?<>
                {flight}
              </>:tab==='book'?<>
                {book}
              </>:null}
            </Desktop>
            <MobileBook>
              <TabState>
                <MobileBookButton>
                  Pré-reserva
                </MobileBookButton>
                <MobileTabContent>
                  {book}
                </MobileTabContent>
              </TabState>
            </MobileBook>
            <TabState>
              <TabTitle>Sobre</TabTitle>
              <MobileTabContent>
                {about}
              </MobileTabContent>
            </TabState>
            <TabState>
              <TabTitle>Hotel</TabTitle>
              <MobileTabContent>
                {hotel}
              </MobileTabContent>
            </TabState>
            {destination.flight ?(
              <TabState>
                <TabTitle>Voo</TabTitle>
                <MobileTabContent>
                  {flight}
                </MobileTabContent>
              </TabState>
            ):null}
          </div>
        </div>
      </Layout>
    </Root>
  ) : null;
}
