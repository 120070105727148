import mediaQueries from '../media-queries';

export default function mediaQueryPlugin(context,content){
  if(context !== -1)
    return content;

  var newContent = content;

  newContent = content.replace(/@media [^{]+{/g,str => {
    for(const name in mediaQueries)
      str = str.replace(new RegExp(`\\b${name}\\b`),mediaQueries[name]);
    return str;
  });
  
  return newContent;
}
