import { useState } from "react";

export default function useExternalState(value,setValue,defaultValue){
  const [internalValue,internalSetValue] = useState(defaultValue);
  
  const externalIsValid = Boolean(setValue);
  return externalIsValid ? [
    value,setValue
  ] : [
    internalValue,internalSetValue
  ];
}
