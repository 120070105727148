import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, { Fragment, useCallback, useRef } from 'react';
import styled from 'styled-components';
import cursor from './assets/destination-thumb-cursor.svg';
import useAnimationFrame from 'use-animation-frame';
import { Link } from 'react-router-dom';

const Root = styled(Link)`
  position: relative;
  display: block;

  cursor: pointer;
`;
const PictureWrapper = styled.div`
  position: relative;
  overflow: hidden;
  /* width: max-content; */
`;
const Picture = styled.div`
  position: relative;
  background: url(${p=>p.$src}) center / cover no-repeat, #17013f4d;
  filter: brightness(60%) contrast(105%);
  padding-top: 56%;

  transition: opacity 500ms, filter 500ms;
  ${Root}[data-alt=true] &{
    /* opacity: 0.9; */
    /* filter: brightness(90%) contrast(90%); */
  }
  ${Root}[data-alt=true]:hover &{
    opacity: 1;
  }

  
`;
const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1;

  font-family: ${secondaryFontFamily};
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: ${colors.background};

  ${Root}[data-alt=true] &{
    font-size: 50px;
  }
`;
const Cursor = styled.div`
  position: absolute;
  width: 72px;
  height: 72px;
  background: url(${cursor}) center / contain no-repeat;
  opacity: 0;
  z-index: 1;
  transition: opacity 50ms;
  ${Root}:hover &{
    opacity: 1;
    transition: opacity 500ms;
  }
  ${Root}[data-alt=true] &{
    width: 110px;
    height: 110px;
    transition: opacity 200ms;
  }
`;
const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 90px;

  @media portrait{
    grid-template-columns: 1fr;
  }

  & > *{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
    &:nth-child(2){
      /* padding-top: 17px; */
      padding-top: 6px;
    }
  }

  color: ${colors.primary};
  ${Root}[data-alt=true] &{
    color: ${colors.background};
    margin-top: -90px;
    padding-bottom: 25px;
    & > *{
      padding: 0 30px;
      justify-content: flex-end;
    }
    & > :nth-child(2){
      text-align: right;
    }
  }
`;
const FlightAndHotel = styled.div`
  font-size: 20px;
  line-height: 1.35;
  
  ${Root}[data-alt=true] & br:last-of-type{
    display: none;
  }

  @media portrait{
    font-size: 17px;

    & br:last-of-type{
      display: none;
    }
  }
`;
const Stars = styled.span`
  content: '★★★★★';
  color: ${colors.secondary};
  ${Root}[data-alt=true] &{
    margin-left: 0.5em;
  }
  @media portrait{
    margin-left: 0.5em;
  }
`;
const Price = styled.div`
  font-size: 16px;
  line-height: 1.19;
  
  & strong{
    font-weight: 700;
  }
  
  @media portrait{
    font-size: 14px;
  }
`;
const Details = styled.div`
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.1;

  
  @media portrait{
    font-size: 12px;
  }
`;

// title,picture,price,info,stars,details

export default function DestinationThumb({
  destination,
  alt=false,
  ...props
}){

  const cursorPos = useRef({x:-100,y:-100});
  const cursorRef = useRef();

  const handleMouseMove = useCallback(ev=>{
    const rect = ev.currentTarget.getBoundingClientRect();
    cursorPos.current.x = ev.clientX - rect.left;
    cursorPos.current.y = ev.clientY - rect.top;
  },[cursorPos]);

  useAnimationFrame(()=>{
    if(!cursorRef.current)
      return;
      cursorRef.current.style.left = `${cursorPos.current.x - cursorRef.current.offsetWidth/2}px`;
      cursorRef.current.style.top = `${cursorPos.current.y - cursorRef.current.offsetHeight*0.75}px`;
  },[cursorPos,cursorRef]);

  const url = destination.id ? `/destinos/${destination.category}/${destination.id}` : '/';
  
  return destination ? (
    <Root
      to={url}
      data-alt={alt}
      onMouseMove={handleMouseMove}
      {...props}
    >
      <PictureWrapper>
        <Picture $src={destination.pictures[0]}/>
        <Title>{destination.title}</Title>
        <Cursor ref={cursorRef}/>
      </PictureWrapper>
      <Grid>
        <div>
          <FlightAndHotel>
            {destination.info.map((text,idx) => <Fragment key={idx}>
              {text}<br/>
            </Fragment>)}
            <Stars>
              {[...Array(
                destination.stars
              ).keys()].map(()=>'★').join('')}
            </Stars>
          </FlightAndHotel>
        </div>
        <div>
          <Price>
            Desde: <strong>{destination.price}</strong>
          </Price>
          <Details>
            {destination.details.map((text,idx) => <Fragment key={idx}>
              {text}<br/>
            </Fragment>)}
          </Details>
        </div>
      </Grid>
    </Root>
  ) : null;
}
