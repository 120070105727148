import colors from 'colors';
import mediaQueries from 'css-toolkit/media-queries';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from 'use-media-query';
import {useSwipeable} from 'react-swipeable';

import arrow from './assets/arrow-right.svg';

const Root = styled.div`
  position: relative;
  height: 1000px;
  margin-top: -82px;
  width: 100%;
  overflow: hidden;

  @media portrait{
    height: 1024px;
    margin: 0;
  }
`;
const Title = styled.h2`
  display: inline-block;

  font-size: 25px;
  font-weight: 500;
  color: ${colors.secondary};
  filter: brightness(50%);
  text-transform: uppercase;
  letter-spacing: 0.6em;

  position: absolute;
  left: ${-170+76}px;
  top: 350px;
  transform: rotate(-90deg);

  @media portrait{
    display: block;

    font-size: 22px;
    line-height: 1.86;
    letter-spacing: 0.355em;
    text-align: center;

    position: absolute;
    top: 98px;
    left: 0;
    width: 100%;
    transform: none;
  }
`;
const Indicators = styled.div`
  position: absolute;
  width: 100%;
  top: 765px;
  text-align: center;

  @media portrait{
    top: 1000px;
  }
  @media tablet{
    top: 920px;
  }

  & > div{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${colors.secondary};
    cursor: pointer;
    & + div{
      margin-left: 20px;
    }
    opacity: 0.2;
    transition: opacity 1s;
    &[data-selected=true]{
      opacity: 1;
    }
  }
`;
const transitionSlide = (idx,current,distance=1) => ({
  transform: `translateX(${(idx-current)*distance*100}vw)`,
  transition: `transform 1.2s cubic-bezier(.5,0,.5,1)`,
});
const PictureWrapper = styled.div`
  position: absolute;
  height: 698px;
  width: 100%;
  top: 0;
  left: 0;

  @media portrait{
    height: 800px;
    top: 181px;
  }
`;
const Picture = styled.div`
  width: 1240px;
  height: 100%;

  position: absolute;
  left: 340px;

  background:
    url(${p => p.$src}) center / cover no-repeat,
    #17013f4d;
    /* linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%), */

  filter: brightness(60%) contrast(120%);

  @media portrait{
    width: 100vw;
    height: 100vw;
    left: 0;
    top: 38px;
  }
  @media tablet{
    height: 50vw;
    width: 660px;
    left: 54px;
  }
`;
const Destination = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  left: 235px;
  top: 0;

  font-size: 90px;
  line-height: 1;
  letter-spacing: -0.035em;
  color: ${colors.secondary};

  @media portrait{
    width: 100%;
    height: auto;
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    font-size: 75px;
    font-weight: 500;
    line-height: 0.87;
    text-align: center;
    letter-spacing: -0.045em;
  }
`;
const TextBox = styled.div`
  width: 615px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 1129px;

  @media portrait{
    width: 100%;
    height: 241px;
    top: 512px;
    left: 0;
  }
  @media tablet{
    top: 445px;
    width: 550px;
    left: 109px;
    justify-content: flex-start;
  }
  
  & > div{
    width: 100%;
    padding: 50px 50px 60px 60px;
    background: ${colors.secondary};
    color: ${colors.background};
    & > span{
      font-size: 20px;
      line-height: 1.35;
      & strong{
        font-weight: 600;
      }
    }
    & > p{
      font-family: 'Georgia','Source Serif Pro', serif;
      font-size: 30px;
      line-height: 1.23;
      font-style: italic;
      margin-top: 38px;
    }
  }

  @media portrait{
    & > div{
      padding: 0 16px;
      background: transparent;
      & > span{
        display: block;
        width: 100%;

        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        color: ${colors.primary};
        & strong{
          font-size: 25px;
          line-height: 1.08;
          font-weight: 700;
        }
      }
      & > p{
        font-size: 20px;
        line-height: 1.3;
        font-weight: 400;
        text-align: center;
        color: ${colors.foreground};
        
        margin-top: 20px;
        padding: 40px 16px;

        border-top: 1px solid ${colors.secondary};
        border-bottom: 3px solid ${colors.secondary};

        &:before{
          content: '“ ';
          font-size: 1.2em;
        }
        &:after{
          content: ' ”';
        }
        
        
      }
    }
  }
`;
const decorationSize = (idx,current)=>{
  const baseSize = 530;
  const baseTop = 698;
  const baseRight = 340;
  
  const x = current-idx;
  const size = baseSize * (x===0 ? 1 : x < 0 ? 0 : 8);
  const top = baseTop - size/2;
  const right = baseRight - size/2;
  // const timing = x==0 ? 'ease-out' : x < 0 ? 'ease-out' : 'ease-in-out';
  
  const opacity = 1 - Math.max(0,Math.min(1,Math.abs(idx-current)));

  return ({
    top: top+'em',
    right: right+'em',
    width: size+'em',
    height: size+'em',
    transition: `1.2s cubic-bezier(.5,0,.5,1)`,
    // transform: `translateX(${(current+idx)*distance*100}vw)`,
    opacity: opacity,
    // transitionTimingFunction: timing,
  });
};
const Decoration = styled.div`
  position: absolute;
  font-size: 1px;
  top: ${698-265}px;
  right: ${340-265}px;
  width: 530px;
  height: 530px;
  border-radius: 50%;
  border: 1px solid ${colors.secondary};

  transition-duration: 1.6s;

  /* transform: scale(0.5); */

  @media portrait{
    display: none;
  }
`;
const Arrow = styled.div`
  width: 61px;
  height: 22px;
  background: url(${arrow}) center / contain no-repeat;

  position: absolute;
  right: 340px;
  top: 755px;
  cursor: pointer;

  /* &[data-last=true]{
    transform: scaleX(-1);
  }
  transition: transform 1.2s; */
  
  //todo: put in a separate file
  &:hover{
    filter: brightness(120%);
  }

  @media portrait{
    display: none;
  }
`;  


const list = [
  {
    destination: 'Veneza',
    picture: require('./temp-pics/viagens-lousada-testemunho-1.jpg').default,
    name: 'Daniel Silva',
    text1: 'Visitou Veneza, Itália',
    text2: 'Foi uma experiência fantástica proporcionada pela disponibilidade e profissionalismo da equipa de:Lousada. Estamos muito gratos!'
  },{
    destination: 'Punta Cana',
    picture: require('./temp-pics/viagens-lousada-testemunho-2.jpg').default,
    name: 'Liliana Sousa Santos',
    text1: 'Visitou Punta Cana, República Dominicana',
    text2: 'Excelente atendimento e excelentes opções para viajar. Obrigado por nos receberem tão bem e por conseguirmos viajar em segurança e com confiança! Até breve!'
  },{
    destination: 'Recife',
    picture: require('./temp-pics/viagens-lousada-testemunho-3.jpg').default,
    name: 'Paula Pinto',
    text1: 'Visitou Recife, Brasil',
    text2: 'A equipa de:Lousada superou as nossas espectativas. Trataram de todos os detalhes da nossa viagem com muita dedicação e profissionalismo.'
  }, 
];



export default function Testimonials({...props}){

  const portrait = useMediaQuery(mediaQueries.portrait);

  const distances = {
    picture: portrait ? 1.1 : 0.83,
    destination: portrait ? 2 : 1.5,
    textbox: portrait ? 1.5 : 0.92,
  };

  const [current, setCurrent] = useState(0);

  const repeat = [0];

  const next = ()=>setCurrent(x => (x+1)%(list.length*repeat.length));
  const prev = ()=>setCurrent(x => (x-1+list.length)%(list.length*repeat.length));

  const handle = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
  });
  
  return (
    <Root {...handle} {...props}>
      <Title>Testemunhos</Title>
      {repeat.map(x => list.map((data,idx)=><Fragment key={idx+x*list.length}>
        <Decoration style={decorationSize(idx+x*list.length,current)}/>
        <PictureWrapper>
          <Picture style={transitionSlide(idx+x*list.length,current,distances.picture)} $src={data.picture}/>
          <Destination style={transitionSlide(idx+x*list.length,current,distances.destination)}>{data.destination}</Destination>
          <TextBox style={transitionSlide(idx+x*list.length,current,distances.textbox)}>
            <div>
              <span>
                <strong>{data.name}</strong><br/>
                {data.text1}
              </span>
              <p>{data.text2}</p>
            </div>
          </TextBox>
        </PictureWrapper>
      </Fragment>))}
      <Indicators>
        {list.map((_,idx)=>(
          <div
            key={idx}
            data-selected={idx===current%list.length}
            onClick={()=>setCurrent(idx)}
          />
        ))}
      </Indicators>
      <Arrow data-last={current===list.length-1} onClick={next}/>
    </Root>
  );
}
