import { useState, useMemo, useEffect } from "react";

export default function useMediaQuery(query){
  const mediaQuery = useMemo(() => matchMedia(query), [query]);
  const [matches, setMatch] = useState(mediaQuery.matches);
  useEffect(() => {
    const listener = e => setMatch(e.matches);
    mediaQuery.addEventListener('change',listener);
    return () => mediaQuery.removeEventListener('change',listener);
  },[mediaQuery]);
  return matches;
};

