import React, { useState } from 'react';
import styled from 'styled-components';
import Section1 from './Section1';
import corporate from './assets/affiliates.jpg';
import TopBar from 'ui-components/TopBar';
import List from './List';
import Contact from './Contact';
import { sendAffiliates } from 'api';

const Root = styled.div`
  padding-top: 25px;
  @media portrait{
    padding: 0;
  }
`;

export default function Affiliates({...props}){

  const [topBar, setTopBar] = useState(null);

  return (
    <Root {...props}>
      <TopBar ref={setTopBar}/>
      <Section1 title="Afiliados" src={corporate}>
        O programa de afiliados de:Lousada foi pensado para todas as pessoas que são recorrentemente os responsáveis por organizar as férias da família ou do seu grupo de amigos, assim como para decisores de associações e/ou entidades que promovem eventos sazonalmente. 
        <br/><br/>
        Ser afiliado da nossa agência atribui-lhe várias vantagens que pode aplicar na compra de viagens a nível pessoal ou na aquisição de produtos e serviços da nossa rede de parceiros. 
        <br/><br/>
        Se quando é para organizar uma viagem, umas férias ou um evento é a si que o(a) chamam, então seja recompensado por isso!
      </Section1>
      <List topBar={topBar} title="As vantagens de ser um afiliado:">
        <li>Programa de afiliação completamente gratuito.</li>
        <li>Acesso a uma completa montra de viagens e férias: hotéis, cruzeiros, pacotes, circuitos, comboios, ferries, rent-a-car, actividades, etc.</li>
        <li>Atribuição de um(a) gestor(a) de conta para um atendimento mais personalizado e sempre disponível para o(a) ajudar a planificar a viagem ou evento.</li>
        <li>Atribuição de pontos que pode descontar em viagens, férias, serviços e produtos dentro da nossa rede de parceiros.</li>
        <li>Acesso a condições exclusivas para viagens e férias.</li>
      </List>
      <Contact
        formTitle="Pré-inscrição"
        formFields={[
          {placeholder:'Nome*',name:'nome',required:true},
          {placeholder:'E-mail*',name:'email',required:true,type:"email"},
          {placeholder:'Telefone*',name:'telefone',required:true},
        ]}
        endpoint={sendAffiliates}
      >
        <h2>Como funciona</h2>
        <p>
          O programa de afiliados da agência de viagens de:Lousada está disponível para todas as pessoas maiores de idade (+18). 
          <br/><br/>
          Depois de preencher o formulário de pré-inscrição, será contactado(a) pelos nossos serviços para explicar todas as condições do programa de afiliados. Será sempre avaliada cada candidatura e se se reunirem todas as condições será atribuído um código único de afiliado.  
          <br/><br/>
          Sempre que se confirme uma venda com o seu código de afiliado serão atribuídos os pontos correspondentes na sua conta. Estes pontos não caducam nem são transmissíveis. 
          <br/><br/>
          Pode extender as vantagens de afiliado a uma rede de contactos maior, por exemplo, pode divulgar as nossas ofertas nas suas redes sociais indicando o seu código de afiliado. Sempre que ele for indicado numa venda efectiva, soma pontos para si. 
          <br/><br/>
          Por fim, é só escolher onde gastar os pontos acumulados. 
        </p>
      </Contact>
    </Root>
  );
}
