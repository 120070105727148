import colors from 'colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'ui-components/Button';
import GenericForm from 'ui-components/GenericForm';
import InputText from 'ui-components/InputText';

const Root = styled.div`
  padding: 140px 180px;
  background: ${colors.background2};
  display: grid;
  grid-template-columns: 766px 1fr;
  column-gap: 182px;

  @media portrait{
    display: block;
    padding: 80px 16px 50px;
  }
`;
const Text = styled.div`
  & > p{
    font-size: 20px;
    line-height: 1.5;
    color: ${colors.foreground};
  }
  & > h2,
  & strong{
    font-weight: 500;
    font-size: 25px;
    line-height: 1.28;
    color: ${colors.primary};
    @media portrait{
      font-size: 20px;
      font-weight: 600;
    }
  }
  & > * + *{
    margin-top: 26px;
  }
`;
const Form = styled(GenericForm)`
  position: relative;

  & > h2{
    font-size: 25px;
    line-height: 1.28;
    font-weight: 700;
    color: ${colors.primary};
  }
  & > h2 + div{
    margin-top: 30px;
  }

  @media portrait{
    margin-top: 60px;
    & > h2{
      font-size: 34px;
      line-height: 1.15;
    }
  }
`;
const FormFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 26px;

  margin-top: 30px;

  & > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
`;
const Error = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  text-transform: uppercase;
  color: ${colors.secondary};
`;
const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.background};

  color: ${colors.foreground};
  font-size: 14px;
  line-height: 1.57;
  text-transform: uppercase;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export default function Contact({
  children,
  formTitle,
  formFields,
  endpoint,
  ...props
}){

  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  
  return (
    <Root {...props}>
      <Text>
        {children}
      </Text>
      <Form
        setError={setError}
        setSent={setSent}
        setSending={setSending}
        endpoint={endpoint}
      >
        {formTitle ? <h2>{formTitle}</h2> : null}
        <div style={{position:'relative'}}>
          {formFields.map(field => (
            <InputText key={field.name} variant="ultralight" {...field}/>
          ))}
          <FormFooter>
            <div>
              <Button disabled={sending}>Enviar</Button>
            </div>
            <div>
              {error ? (
                <Error>
                  Falha no envio, tente novamente mais tarde.
                </Error>
              ):null}
            </div>
          </FormFooter>
          {sent ? <Success>
            Mensagem enviada com sucesso.<br/>
            Em breve entramos em contato.
          </Success> : null}
        </div>
      </Form>
    </Root>
  );
}
