import React from 'react';
import styled from 'styled-components';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import colors from 'colors';

const Root = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.foreground};

  & > h1{
    font-weight: 600;
    font-family: ${secondaryFontFamily};
    font-size: 50px;
    line-height: 0.9;
    letter-spacing: -0.025em;
    color: ${colors.primary};

    margin-bottom: 60px;

    @media mobile{
      font-size: 40px;
    }
  }
  & > strong{
    text-transform: uppercase;
    color: ${colors.primary};
  }
`;

export default function LegalText({...props}){
  
  return (
    <Root {...props}>

    </Root>
  );
}
