import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import mediaQueries from 'css-toolkit/media-queries';
import React, { useRef } from 'react';
import styled from 'styled-components';
import useMediaQuery from 'use-media-query';
import useAnimationFrame from 'use-animation-frame';
import Button from 'ui-components/Button';

const Root = styled.div`
  position: relative;

  background: ${colors.background2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 208px 0 222px;

  @media portrait{
    padding: 115px 28px 140px;
  }
  @media tablet{
    display: block;
  }
`;
const Title = styled.h2`
  font-family: ${secondaryFontFamily};
  font-size: 80px;
  font-weight: 300;
  line-height: 1.10;
  text-align: center;
  letter-spacing: -0.025em;
  color: ${colors.primary};
  
  width: 1240px;

  & > [data-mobile]{
    display: none;
  }
  @media portrait{
    width: 100%;
    font-size: 42px;
    line-height: 1;
    text-align: left;
    font-weight: 400;
    & > [data-mobile]{
      display: unset;
    }
  }
  @media tablet{
    & > [data-mobile],
    & > br{
      display: none;
    }
    line-height: 1.2;
    max-width: ${500/42}em;
  }
`;
const Text = styled.div`
  font-size: 20px;
  line-height: 1.35;
  text-align: center;
  color: ${colors.foreground};

  margin-top: 74px;

  @media portrait{
    text-align: left;
    font-size: 19px;
    margin-top: 30px;
    & > span{
      display: none;
    }
    & > br{
      display: none;
    }
  }
  @media tablet{
    font-size: 20px;
    max-width: ${450/20}em;
  }
`;
const Decoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  overflow: hidden;
  font-size: 0.7vw;

  pointer-events: none;

  @media portrait{
    /* padding-top: ${77 + 56/2 - 4}px; */
    display: none;
  }
  
  & > div{
    position: relative;
    border: 4px solid ${colors.background};
    border-radius: 50%;
    width: 80em;
    height: 80em;
    top: calc(50% - 40em);
    left: calc(50% - 40em);
    /* background: green; */


    @media portrait{
      /* font-size: 3vw; */
    }

    // js animated: width, height, top
  }
`;
const CustomizeOfferButton = styled(Button)`
  width: 292px;
  margin-top: 74px;

  @media portrait{
    margin-top: 30px;
  }
  @media mobile{
    width: 100%;
  }
`;

export default function CustomOffer({
  setCustomTripFormOpen,
  ...props
}){
  
  const decorationRef = useRef();

  const portrait = useMediaQuery(mediaQueries.portrait);

  useAnimationFrame(()=>{
    if(!decorationRef.current)
      return;

    const scrollTop = document.scrollingElement.scrollTop;
    const offsetTop = decorationRef.current.parentElement.offsetTop;

    // const ease = bezier(0.91,0.31,.77,.3);
    const ease = x => x;

    const rawProgress  = (
      Math.max(0,Math.min(1,(scrollTop + window.innerHeight - offsetTop)/(window.innerHeight*2)))
    );
    const progress = ease(rawProgress);

    const minSize = portrait ? 0.6 : 0.6;
    const maxSize = portrait ? 1.55 : 1.55;
    const size = minSize + progress*(maxSize-minSize);

    Object.assign(decorationRef.current.style,{
      fontSize: `${size}vw`,
    });

  },[decorationRef,portrait]);

  return (
    <Root {...props}>
      <Decoration ref={decorationRef}><div/></Decoration>
      <Title>
        Defina <br data-mobile/>
        um orçamento, <br/>
        escolha um <br data-mobile/>
        destino e nós <br/>
        tratamos <br data-mobile/>
        do resto.
      </Title>
      <Text>
        Se já imaginou a sua viagem e se já pensou num destino <br/>
        permita-nos apresentar-lhe a nossa proposta. <br/>
        <span>
          <br/>
          Vamos procurar as melhores ofertas na relação qualidade, preço, segurança<br/>
          e higiene para garantir a melhor experiência na sua viagem de sonho. <br/>
          <br/>
          Tratamos de todo o processo de reserva com os hotéis, companhias <br/>
          aéreas, renting, seguradoras e prestaremos um acompanhamento <br/>
          personalizado em caso de imprevistos.
        </span>
      </Text>
      <CustomizeOfferButton onClick={()=>setCustomTripFormOpen(true)}>Personalizar viagem</CustomizeOfferButton>
    </Root>
  );
}
