import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import espanha from 'temp-data/espanha';
import europa from 'temp-data/europa';
import portugal from 'temp-data/portugal';
import Button from 'ui-components/Button';
import DestinationThumb from 'ui-components/DestinationThumb';
import TopBar from 'ui-components/TopBar';
import Filters from './Filters';

const Root = styled.div`

`;
const Cover = styled.div`
  height: 530px;
  background: 
    url(
      ${({$category}) => require(`temp-data/cover/${$category}.jpg`).default}
    ) center / cover no-repeat,
    url(
      ${({$category}) => require(`temp-data/cover/thumb_${$category}.jpg`).default}
    ) center / cover no-repeat,
    #444;

  margin-top: -77pxva;
  @media portrait{
    margin-top: -56px;
    height: 414px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > span{
    font-family: ${secondaryFontFamily};
    color: ${colors.secondary};
    font-weight: 500;
    font-size: 80px;
    line-height: 0.85;
    letter-spacing: -0.035em;
  }
`;
const Content = styled.div`
  background: ${colors.background2};
  padding: 100px 0 150px;
  @media portrait{
    padding: 86px 0 80px;
  }
`;
const Grid = styled.div`
  padding: 0 24px;

  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 86px 24px;

  @media portrait{
    grid-template-columns: 1fr;
    gap: 81px 0;
  }
`;
const Center = styled.div`
  text-align: center;
`;
const LoadMore = styled(Button)`
  margin-top: 100px;
  width: 292px;

  /* ----------- */
  display: none;
`;


const categories = {
  'portugal': portugal,
  'espanha': espanha,
  'europa': europa,
  'lua-de-mel': [],
  'mundo': [],
  'ofertas-especiais': [],
}
const categoryData = {
  'espanha':{
    name: 'Espanha'
  },
  'europa':{
    name: 'Europa'
  },
  'lua-de-mel':{
    name: 'Lua de mel'
  },
  'mundo':{
    name: 'Mundo'
  },
  'ofertas-especiais':{
    name: 'Ofertas especiais'
  },
  'portugal':{
    name: 'Portugal'
  },
}


export default function Listing({...props}){
  
  const {category} = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    if(!((category in categories)))
      navigate('/');
  },[category,navigate]);

  const [customTripFormOpen, setCustomTripFormOpen] = useState(false);
  
  const panelColor = '#2F0283';

  const [panelOpen, setPanelOpen] = useState(false);

  useEffect(()=>{
    if(customTripFormOpen)
      setPanelOpen(true);
    else
      setTimeout(()=>{
        setPanelOpen(false);
      },[300]);
  },[customTripFormOpen]);

  return (category in categories) ? (
    <Root {...props}>
      <TopBar key={category} inverse={true}  panelOpen={panelOpen} panelColor={panelColor}/>
      <Cover $category={category}>
        <span>{categoryData[category].name}</span>
      </Cover>
      <Filters
        customTripFormOpen={customTripFormOpen}
        setCustomTripFormOpen={setCustomTripFormOpen}
      />
      <Content>
        <Grid>
          {categories[category].map(destination => (
            <DestinationThumb key={destination.id} destination={destination}/>
          ))}
        </Grid>
        <Center>
          <LoadMore>Carregar mais</LoadMore>
        </Center>
      </Content>
    </Root>
  ) : null;
}
