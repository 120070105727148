import colors from 'colors';
import colorOpacity from 'color-opacity';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import calendar from 'ui-components/assets/calendar.svg';

const Error = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${colors.secondary};
  text-transform: uppercase;
  
  height: 24px;
  line-height: 26px;

  margin-top: -24px;
  padding-left: 0.5em;
  position: relative;
  text-align: right;
  top: -15px;
  float: right;

  pointer-events: none;
  display: none;

  backdrop-filter: blur(10px);
  width: max-content;
`;
const Input = styled.input`
  width: 100%;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colors.background};
  &[data-variant=light],
  &[data-variant=ultralight]{
    color: ${colors.foreground};
  }
  line-height: 1;
  &::placeholder{
    color: ${colorOpacity(colors.background,0.6)};
    [data-variant=light]&{
      color: ${colorOpacity(colors.foreground,0.6)};
    }
    [data-variant=ultralight]&{
      color: ${colorOpacity(colors.foreground,0.3)};
    }
    line-height: 1;
  }
  padding: 16px 0 16px 14px;
  border-bottom: 2px solid ${colorOpacity(colors.background,0.3)};
  &[data-single=true]{
    border-top: 2px solid ${colorOpacity(colors.background,0.3)};
  }
  &[data-variant=light]{
    border-top-color:  ${colorOpacity(colors.foreground,0.3)};
    border-bottom-color:  ${colorOpacity(colors.foreground,0.3)};
  }
  &[data-variant=ultralight]{
    border-top-color: ${colors.background};
    border-bottom-color: ${colors.background};
  }
  line-height: 1;
  &:active,&:focus{
    border-top-color: ${colors.background};
    border-bottom-color: ${colors.background};
    &[data-variant=light]{
      border-top-color: ${colors.foreground};
      border-bottom-color: ${colors.foreground};
    }
    &[data-variant=ultralight]{
      border-top-color: ${colorOpacity(colors.foreground,0.3)};
      border-bottom-color: ${colorOpacity(colors.foreground,0.3)};
    }
  }

  &[data-required-empty=true],
  form[data-validate=true] &:invalid{
    border-top-color: ${colors.secondary};
    border-bottom-color: ${colors.secondary};
    &:not(:focus) + ${Error}{
      display: block;
    }
  }

  textarea&{
    line-height: 1.43;
    height: 100px;
    min-height: 100px;
    resize: vertical;
    &::placeholder{
      line-height: 1.43;
      max-width: 80%;
    }
  }

  &[data-type=date]{
    padding-left: 57px;
    background: url(${calendar}) center left 16px / 25px no-repeat;
  }
`;

const InputText = React.forwardRef(function InputText({
  type="text",
  required=false,
  validate=false,
  variant="dark",
  single="false",
  ...props
},ref){

  // true if input is both required and empty
  const [requiredEmpty, setRequiredEmpty] = useState(false);
  
  const focus = useCallback(({target})=>{
    if(!target.dataset.placeholder)
      target.dataset.placeholder = target.placeholder;
    if(target.dataset.type === 'date')
      target.placeholder = 'DD/MM/AAAA';

    if(!target.required)
      return;
    if(!target.showRequiredWarning)
      target.showRequiredWarning = true;
  },[]);
  const blur = useCallback(({target})=>{
    if(target.dataset.type === 'date')
      target.placeholder = target.dataset.placeholder;

    if(!target.required)
      return;
    if(!target.showRequiredWarning)
      return;
    setRequiredEmpty(!target.value);
  },[setRequiredEmpty]);
  const input = blur;

  return <>
    <Input
      as={
        type==='textarea' ? 'textarea' : 
        type==='date' ? NumberFormat : 'input'
      }
      format={type==='date'?'##/##/####':null}
      ref={ref}
      type={type==='date'?'text':type}
      data-type={type}
      required={required}
      data-required-empty={requiredEmpty}
      data-variant={variant}
      data-single={single}
      onFocus={focus}
      onBlur={blur}
      onInput={input}
      {...props}
    />
    <Error>
      Campo obrigatório
    </Error>
  </>;
});

export default InputText;
