const portugal = [
  {
    id: 'porto',
    category: 'portugal',
    pictures: [
      require('temp-data/places/Porto/AVL005.jpg').default,
      require('temp-data/places/Porto/AVL006.jpg').default,
      require('temp-data/places/Porto/AVL007.jpg').default,
      require('temp-data/places/Porto/AVL008.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Hotel Carris Porto Ribeira/PT003-A.jpg').default,
      require('temp-data/hotels/Hotel Carris Porto Ribeira/PT003-B.jpg').default,
      require('temp-data/hotels/Hotel Carris Porto Ribeira/PT003-C.jpg').default,
      require('temp-data/hotels/Hotel Carris Porto Ribeira/PT003-D.jpg').default,
    ],
    title: 'Porto',
    info: [
      '1 Noite',
      'Hotel Carris Porto Ribeira',
    ],
    price: '70,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'A cidade que deu o nome ao país (e ao vinho do Porto), o Porto é a segunda maior metrópole de Portugal, a seguir a Lisboa. Por vezes designada Oporto em inglês, é uma cidade antiga com um pé firmemente assente no presente industrial.\n\nA zona histórica, centrada na Ribeira, foi construída nas encostas viradas para o rio Douro e é atualmente património mundial da UNESCO. A Igreja de São Francisco, do século XIV, é uma das principais atrações, assim como as adegas de vinho do Porto, localizadas na sua maioria em Vila Nova de Gaia, do outro lado do rio.',
    hotel:[
      ['Datas','A partir de Junho de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
      ['Preço por noite','Desde 70,00€ por pessoa'],
    ],
    notesHotel: 'Preços sujeitos a alterações, mediante disponibilidade ',
    flight: null,
    notesFlight: '',
  },
  {
    id: 'lisboa',
    category: 'portugal',
    pictures: [
      require('temp-data/places/Lisboa/AVL0017.jpg').default,
      require('temp-data/places/Lisboa/AVL0018.jpg').default,
      require('temp-data/places/Lisboa/AVL0019.jpg').default,
      require('temp-data/places/Lisboa/AVL0020.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Radisson Blu Hotel Lisbon/PT005-A.jpg').default,
      require('temp-data/hotels/Radisson Blu Hotel Lisbon/PT005-B.jpg').default,
      require('temp-data/hotels/Radisson Blu Hotel Lisbon/PT005-C.jpg').default,
      require('temp-data/hotels/Radisson Blu Hotel Lisbon/PT005-D.jpg').default,
    ],
    title: 'Lisboa',
    info: [
      '3 Noites',
      'Radisson Blu Hotel Lisbon',
    ],
    price: '190,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Com mais de 20 séculos de história, a capital portuguesa está situada junto ao rio Tejo. Com o sol quase sempre presente, a sua beleza única e singularidade arquitetónica são aclamadas além fronteiras.\n\nRica em monumentos, bairros típicos (Baixa Pombalina, Belém, Bairro Alto, Chiado, Bica, Alfama e Mouraria), zona ribeirinha, casas de Fado, parques, jardins e miradouros, Lisboa oferece várias possibilidades para descobrir, visitar e desfrutar do vasto património natural, histórico e cultural da cidade.',
    hotel:[
      ['Datas','A partir de Setembro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
      ['Preço por noite','Desde 63,00€ por pessoa'],
    ],
    notesHotel: 'Preços sujeitos a alterações, mediante disponibilidade ',
    flight:null,
    notesFlight: '',
  },
  {
    id: 'ponta-delgada',
    category: 'portugal',
    pictures: [
      require('temp-data/places/Ponta Delgada/AVL0033.jpg').default,
      require('temp-data/places/Ponta Delgada/AVL0034.jpg').default,
      require('temp-data/places/Ponta Delgada/AVL0035.jpg').default,
      require('temp-data/places/Ponta Delgada/AVL0036.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/The Lince Azores Great Hotel/PT009-A.jpg').default,
      require('temp-data/hotels/The Lince Azores Great Hotel/PT009-B.jpg').default,
      require('temp-data/hotels/The Lince Azores Great Hotel/PT009-C.jpg').default,
      require('temp-data/hotels/The Lince Azores Great Hotel/PT009-D.jpg').default,
    ],
    title: 'Ponta Delgada',
    info: [
      'Voo + 4 Noites',
      'The Lince Azores Great Hotel',
    ],
    price: '380,00 €',
    details: [
      'MP',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Ponta Delgada é uma cidade portuguesa localizada na ilha de São Miguel e pertencente à Região Autónoma dos Açores.\n\nComo capital dos Açores, Ponta Delgada é a porta de entrada para os famosos lagos de crateras, os vulcões e a costa escarpada do arquipélago. A cidade oferece uma mistura de confortos modernos com igrejas góticas, fortes históricos e tradicionais tavernas portuguesas.',
    hotel:[
      ['Datas','A partir de Novembro de 2021'],
      ['Regime','MP (Meia Pensão)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data e hora da partida','a definir'],
      ['Aeroporto partida','Porto - Ponta Delgada'],
      ['Data e hora do regresso','a definir'],
      ['Aeroporto regresso','Ponta Delgada - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto ou aluguer de viatura, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'funchal',
    category: 'portugal',
    pictures: [
      require('temp-data/places/Funchal/AVL0037.jpg').default,
      require('temp-data/places/Funchal/AVL0038.jpg').default,
      require('temp-data/places/Funchal/AVL0039.jpg').default,
      require('temp-data/places/Funchal/AVL0040.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Four Views Baia/PT010-A.jpg').default,
      require('temp-data/hotels/Four Views Baia/PT010-B.jpg').default,
      require('temp-data/hotels/Four Views Baia/PT010-C.jpg').default,
      require('temp-data/hotels/Four Views Baia/PT010-D.jpg').default,
    ],
    title: 'Funchal',
    info: [
      'Voo + 4 Noites',
      'Four Views Baia',
    ],
    price: '340,00 €',
    details: [
      'MP',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'A cidade está inserida num grandioso anfiteatro natural, de frente para o Atlântico e contrastando com as imponentes montanhas.\n\nSituado no sul da ilha da Madeira, na costa mais soalheira implantada no meio das plantações de bananas e dos maravilhosos jardins onde despontam flores ao longo de todo o ano, abrigadas nas montanhas verdejantes, o Funchal é uma cidade viçosa e descontraída durante o dia e com uma vida nocturna vibrante e ecléctica, com numerosas personalidades.',
    hotel:[
      ['Datas','A partir de Setembro de 2021'],
      ['Regime','MP (Meia Pensão)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data e hora da partida','a definir'],
      ['Aeroporto partida','Porto - Funchal'],
      ['Data e hora do regresso','a definir'],
      ['Aeroporto regresso','Funchal - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto ou aluguer de viatura, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
];
export default portugal;
