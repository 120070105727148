import { useEffect } from "react";

export function disableBodyScroll(){
  document.body.style.width='100%';
  document.body.style.overflowY = 'scroll';
  document.body.style.top = `${-document.scrollingElement.scrollTop}px`;
  document.body.previousScrollPosition = document.scrollingElement.scrollTop;
  document.body.style.position = 'fixed';
}
export function enableBodyScroll(reset=false){
  document.body.style.position = 'initial';
  document.body.style.overflowY = 'initial';
  document.body.style.top = 'initial';
  document.body.style.width = 'initial';
  if(reset)
    document.body.previousScrollPosition = 0;
  else
    document.scrollingElement.scrollTop = document.body.previousScrollPosition;
}
export function getBodyScrollPosition(){
  return (
    document.body.style.top
    ? parseInt((document.body.style.top.match(/^-([0-9.]+)px$/)||[0,document.scrollingElement.scrollTop])[1])
    : document.scrollingElement.scrollTop
  );
}

export function useLockBodyScroll(lock=false){
  useEffect(()=>{
    if(lock)
      disableBodyScroll();
    else
      enableBodyScroll();
  },[lock]);
}

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;
