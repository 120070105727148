import { sendContact } from 'api';
import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import Contact from 'pages/CorporateAffiliates/Contact';
import React from 'react';
import styled from 'styled-components';
import TopBar from 'ui-components/TopBar';

const Root = styled.div`
  @media landscape{
    padding-top: 25px;
  }
`;
const Content = styled.div`
  margin-top: 25px;
  background: ${colors.background2};

  padding: 145px 340px 174px;

  @media portrait{
    padding: 75px 16px;
    margin: 0;
  }
`;
const Title = styled.h1`
  font-family: ${secondaryFontFamily};
  font-weight: 400;
  font-size: 90px;
  line-height: 0.79;
  letter-spacing: -0.035em;

  color: ${colors.primary};

  @media mobile{
    font-size: 40px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 450px 1fr;
  column-gap: 26px;
  row-gap: 71px;

  margin-top: 89px;

  @media portrait{
    grid-template-columns: 1fr;
    margin-top: 60px;
  }
`;
const ContactTitle = styled.h2`
  font-size: 25px;
  font-weight: 700;
  line-height: 1.28;
  color: ${colors.primary};
`;
const ContactText = styled.p`
  margin-top: 31px;

  font-size: 20px;
  line-height: 1.35;
  color: ${colors.foreground};

  @media portrait{
    margin-top: 20px;
  }
`;
const WorkingHoursGrid = styled(ContactText)`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 60px;
  @media portrait{
    column-gap: 30px;
    row-gap: 15px;
  }
`;
const MapsPlaceholder = styled.div`
  height: 489px;
  background: url(${require('./maps-placeholder.svg').default}) center / cover;
  @media portrait{
    display: none;
  }
`;
const ContactFormTitle = styled.h2`
  &&{
    font-size: 50px;
    max-width: ${400/50}em;
    font-weight: 600;
  }
`;

export default function ContactUs({...props}){
  
  return (
    <Root {...props}>
      <TopBar/>
      <Content>
        <Title>Contactos</Title>
        <Grid>
          <div>
            <ContactTitle>Morada</ContactTitle>
            <ContactText>
              Praça das Pocinhas nº 9  <br/>
              4620-657 Lousada<br/>
              Portugal <br/>
            </ContactText>
          </div>
          <div>
            <ContactTitle>Horário</ContactTitle>
            <WorkingHoursGrid>
              <span>De 2ª a 6ª feira: </span>
              <span>das 09h30 às 13h e das 14h às 18h30</span>
              <span>Sábados: </span>
              <span>das 10h às 13h</span>
              <span>Domingos: </span>
              <span>encerrados</span>
            </WorkingHoursGrid>
          </div>
          <div>
            <ContactTitle>Telefone</ContactTitle>
            <ContactText>
              <a href="tel:+351255814009">+351 255 814 009</a>
            </ContactText>
          </div>
          <div>
            <ContactTitle>E-mail</ContactTitle>
            <ContactText>
              <a href="mailto:geral@viagenslousada.pt">geral@viagenslousada.pt</a>
            </ContactText>
          </div>
        </Grid>
      </Content>
      <MapsPlaceholder/>
      <Contact
        formFields={[
          {placeholder:'Nome*',name:'nome',required:true},
          {placeholder:'E-mail*',name:'email',required:true,type:"email"},
          {placeholder:'Telefone*',name:'telefone',required:true},
          {placeholder:'Mensagem',name:'mensagem',type:"textarea"},
        ]}
        endpoint={sendContact}
      >
        <ContactFormTitle>Nós entramos em contacto</ContactFormTitle>
      </Contact>
    </Root>
  );
}
