import colors from 'colors';
import React from 'react';
import styled from 'styled-components';

const Root = styled.button`
  height: 52px;
  line-height: 52px;
  text-align: center;
  padding: 0 20px;
  border-radius: 6px;

  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${colors.primary};
  background: ${colors.secondary};
  &[data-variant=transparent]{
    color: ${colors.secondary};
    background: transparent;
    transition: color 300ms, background-color 300ms;
    &:hover{
      color: ${colors.primary};
      background: ${colors.secondary};
    }
  }

  &:disabled{
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  // todo: put in a separate file 
  transition: background-color 300ms;
  &:hover{
    filter: brightness(105%);
  }
  &:active{
    filter: brightness(95%);
  }

  cursor: pointer;
`;

export default function Button({
  variant="light",
  ...props
}){
  
  return (
    <Root data-variant={variant} {...props}/>
  );
}
