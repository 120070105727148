import { useEffect, useState } from "react";

export default function useWindowHeight(){
  const [windowHeight, setWindowHeight] = useState(null);
  useEffect(()=>{
    const resize = (ev)=>{
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize',resize);
    resize();
    return ()=>{
      window.removeEventListener('resize',resize);
    };
  },[setWindowHeight]);
  return windowHeight;
}
