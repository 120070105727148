import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, { useRef } from 'react';
import styled from 'styled-components';
import colorOpacity from 'color-opacity';
import useAnimationFrame from 'use-animation-frame';

const Root = styled.div`
  padding: 145px 180px;
  background: linear-gradient(180deg, #2F0283 0%, #4E04D8 100%);

  @media portrait{
    padding: 70px 54px;
  }
`;
const Title = styled.h2`
  font-family: ${secondaryFontFamily};
  font-weight: 600;
  font-size: 50px;
  letter-spacing: -0.025em;
  color: ${colors.secondary};
  @media portrait{
    font-size: 34px;
    letter-spacing: 0;
    line-height: 1.25;
  }
`;
const UnorderedList = styled.ul`
  display: block;
  margin-top: 72px;
  & > li{
    font-size: 25px;
    line-height: 1.25;
    color: ${colors.background};
    border-bottom: 2px solid ${colorOpacity(colors.background,0.3)};
    padding: 15px 0 15px 21px;
    position: relative;
    &:before{
      display: block;
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 4.5px;
      background: ${colors.secondary};

      position: absolute;
      left: 0;
      top: 27px;
    }

    @media portrait{
      font-size: 20px;
      padding: 15px 0 15px 0px;
      &:last-child{
        border-bottom: none;
      }
      &:before{
        left: -21px;
        top: 24px;
      }
    }
  }
`;


export default function List({
  children,
  title,
  src,
  topBar,
  ...props
}){

  const rootRef = useRef();

  // topbar color animation
  useAnimationFrame(()=>{
    // console.log({topBar,rootRef});
    if(!topBar)
      return;
    if(!rootRef.current)
      return;

    const initial = [0x2F,0x02,0x83];
    const final = [0x4E,0x04,0xD8];

    const top = rootRef.current.offsetTop;
    const topbarHeight = topBar.firstElementChild.offsetHeight; 
    const scrollTop = document.scrollingElement.scrollTop;
    const height = rootRef.current.offsetHeight;
    const windowHeight = window.innerHeight;

    const progress = (
      Math.min(1,Math.max(0,(scrollTop - top + topbarHeight)/height))
      + Math.min(1,Math.max(0,(top - scrollTop - topbarHeight)/windowHeight))
    );

    const color = initial.map((_,i)=>(
      initial[i] + (final[i]-initial[i])*progress
    ));

    if(scrollTop > 0 && scrollTop > top-windowHeight && scrollTop < top+height){
      topBar.firstElementChild.style.background = `rgb(${color.join()})`;
      if(scrollTop > top-windowHeight*0.5)
        topBar.firstElementChild.style.transition = 'none';
    }else{
      topBar.firstElementChild.style.background = '';
      topBar.firstElementChild.style.transition = '';
    }
  },[topBar]);

  return (
    <Root ref={rootRef} {...props}>
      <Title>{title}</Title>
      <UnorderedList>
        {children}
      </UnorderedList>
    </Root>
  );
}
