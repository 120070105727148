export const viewPortSizes = {
  default: [1920,720],
  mobile: [414,800],
  tablet: [768,1024],
};

export const pxToViewportAutoVhRatio = 0.7;

export const preserveAspectRatio = false;

