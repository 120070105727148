import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import mediaQueryPlugin from 'css-toolkit/src/media-query-plugin';
import pxToViewportPlugin from 'css-toolkit/src/px-to-viewport-plugin';
import { pxToRemPlugin } from 'css-toolkit/src/px-to-rem';
import { GlobalStyle } from 'css-toolkit/src/global-style';

import { HelmetProvider } from 'react-helmet-async';
import { StyleSheetManager } from 'styled-components';

import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

Object.defineProperty(mediaQueryPlugin, 'name', { value: 'mediaQuery' });
Object.defineProperty(pxToViewportPlugin, 'name', { value: 'pxToViewport' });
Object.defineProperty(pxToRemPlugin, 'name', { value: 'pxToRem' });

const content = (
  <React.StrictMode>
    <HelmetProvider>
      <StyleSheetManager stylisPlugins={[
        mediaQueryPlugin,
        pxToViewportPlugin,
        pxToRemPlugin,
      ]}>
        <>
          <GlobalStyle/>
          <App/>
        </>
      </StyleSheetManager>
    </HelmetProvider>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

ReactDOM.render(content,rootElement);

serviceWorkerRegistration.register();
