import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import HeaderAnimation from './HeaderAnimation3';
import logo from 'assets/logo.svg';
import pictureMobile from './assets/picture-mobile.jpg';
import picture from './assets/picture.jpg';
import gradient from './assets/gradient.png';
import arrow from './assets/arrow-down.svg';
import useMediaQuery from 'use-media-query';
import mediaQueries from 'css-toolkit/media-queries';
import useAnimationFrame from 'use-animation-frame';
import { secondaryFontFamily } from 'css-toolkit/fonts';
// import text from './assets/text.svg';

const animationMult = 5;

const Root = styled.div`
  height: ${100 * (1+2.5/animationMult)}vh;
  /* height: 100vh; */
  position: relative;
  
  @media portrait{
    /* height: ${100 * (1+2/5)}vh; */
    height: 100vh;
  }
`;
const Wrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & > *:not(:first-child){
    z-index: 2;
    pointer-events: none;
    
    transition: opacity 100ms;
    &[data-loaded=false]{
      opacity: 0;
    }
  }

`;
const Logo = styled.img.attrs({
  alt: 'De Lousada',
  src: logo,
})`
  margin-top: calc(70px - 200pxva);
  width: 292pxva;
  @media portrait{
    width: 182px;
    margin-top: -100px;
  }
`;
const Text = styled.h1`
  font-family: ${secondaryFontFamily};
  font-weight: 200;
  font-size: 140pxva;
  /* font-size: 90pxva; */
  line-height: 0.9;
  color: #FFAD00;
  text-align: center;
  letter-spacing: -0.05em;

  max-width: 7.8em;
  margin-top: calc(188pxva - 100px);

  @media tablet{
    font-size: 80px;
    margin-top: 80px;
  }
  @media portrait{
    margin-top: 50px;
    font-size: 68px;
    /* font-size: 48px; */
    max-width: 4.5em;
    line-height: 1;
  }
`;

const bounce = keyframes`
  0%{
    transform: translateY(0px);
  }
  10%{
    transform: translateY(10px);
  }
  20%{
    transform: translateY(0px);
  }
  30%{
    transform: translateY(10px);
  }
  40%{
    transform: translateY(0px);
  }
`;
const Arrow = styled.div`
  position: absolute;
  bottom: 85pxva;

  width: 100%;
  height: 60pxva;
  background: url(${arrow}) center / contain no-repeat;

  animation:  ${bounce} 3s infinite;
  
  transition: opacity 300ms;
  opacity: 0;

  @media portrait{
    bottom: unset;
    top: calc(100vh - 160pxvh);
    height: 40px;
  }
`;


export default function Header({...props}){

  const [logoLoaded, setLogoLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  useEffect(()=>{
    document.fonts.ready.then(()=>{
      setTimeout(()=>{
        setFontsLoaded(true);
      },100);
    })
  },[setFontsLoaded]);

  const portrait = useMediaQuery(mediaQueries.portrait);
  const wrapperRef = useRef();
  const arrowRef = useRef();
  // url(${gradient}) top center / 100% ${px}px no-repeat,
  const wrapperBackground = useCallback(px => `
      url(${gradient}) top center / 100% 100% no-repeat,
      url(${portrait ? pictureMobile : picture}) top center / cover
  `,[portrait]);
  useAnimationFrame(()=>{
    if(!wrapperRef.current)
      return;
    if(!arrowRef.current)
      return;

    const scrollTop = document.scrollingElement.scrollTop;
    const height = window.innerHeight;
    const mult = 5;

    if(scrollTop > 0 && !portrait)
      arrowRef.current.style.opacity = (
        ( scrollTop > 0.7*height/animationMult
          && scrollTop < 1.2*height/animationMult
        ) || scrollTop > 2.6*height/animationMult
      ) ? 0 : 1;
    else if(portrait){
      arrowRef.current.style.opacity = (
        scrollTop < 2*height/mult
      ) ? 1 : 0;
    }

    if(scrollTop > 2*height/mult)
      return;

    // const gradientHeight = Math.max(0,height - mult*scrollTop);
    // wrapperRef.current.style.background = wrapperBackground(gradientHeight);
  },[portrait,wrapperBackground]);

  const handleMouseEnter = useCallback(()=>{
    if(portrait)
      return;
    setTimeout(()=>{
      arrowRef.current.style.opacity = 0.5;
    },2000);
  },[portrait,arrowRef]);

  return (
    <Root onMouseEnter={handleMouseEnter} {...props}>
      <Wrapper ref={wrapperRef} style={{background:wrapperBackground(window.innerHeight)}}>
        <HeaderAnimation/>
        <Logo onLoad={()=>setLogoLoaded(true)} data-loaded={logoLoaded}/>
        <Text data-loaded={fontsLoaded && logoLoaded}>
          O mundo espera por nós
          {/* Novo website em breve */}
        </Text>
        <Arrow ref={arrowRef}/>
      </Wrapper>
    </Root>
  );
}
