import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, {  } from 'react';
import styled from 'styled-components';
import espanha from 'temp-data/espanha';
import portugal from 'temp-data/portugal';
import DestinationThumb from 'ui-components/DestinationThumb';
import MiniDestinationThumb from 'ui-components/MiniDestinationThumb';
import VerMais from 'ui-components/VerMais';

const Root = styled.div`
  position: relative;
  /* margin-top: -127pxva; */
  padding: 0 0 190px;
  @media portrait{
    margin: 0;
    padding: 0;
    padding: 80px 0;
  }
`;
const TextBox = styled.div`
  margin-left: 335px;

  width: 611px;
  
  & > h1{
    font-family: ${secondaryFontFamily};
    color: ${colors.primary};
    font-weight: 500;
    font-size: 80px;
    line-height: 0.85;
    letter-spacing: -0.035em;
  }
  & > p{
    font-size: 20px;
    line-height: 1.35;
    color: ${colors.foreground};

    margin-top: 40px;
  }

  @media portrait{
    margin: 0;
    margin-left: 24px;
    width: auto;
    & > h1{
      font-size: 45px;

      max-width: 80%;
    }
  }
`;
const Grid = styled.div`
  margin-top: 90px;

  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 24px;

  &:hover > *{
    opacity: 0.6;
  }
  & > *{
    transition: opacity 500ms;
    opacity: 1;
    &:hover{
      opacity: 1;
    }
  }

  @media portrait{
    display: none;
  }
`;
const LinkVerMais = styled(VerMais)`
  margin-right: 26px;

    @media portrait{
      display: none;
    }
`;
const Carousel = styled.div`
  height: 235px;
  overflow-y: hidden;
  & > div{
    height: 251px;
    max-width: 100vw;
    overflow-x: scroll;
    & > div{
      padding: 0 5px;
      height: 100%;
      width: min-content;
      white-space: nowrap;
    }
  }

  margin-top: 30px;

  @media landscape{
    display:none;
  }
`;

export default function SpecialOffers({...props}){
  
  const thumbStyle = {style:{opacity:1}};

  return (
    <Root {...props}>
      <TextBox>
        <h1>Ofertas especiais</h1>
      </TextBox>
      <Grid data-selected={true}>
        <DestinationThumb {...thumbStyle} destination={portugal[0]}/>
        <DestinationThumb {...thumbStyle} destination={portugal[1]}/>
        <DestinationThumb {...thumbStyle} destination={espanha[0]}/>
        <DestinationThumb {...thumbStyle} destination={espanha[1]}/>
      </Grid>
      <LinkVerMais/>
      <Carousel>
        <div>
          <div>
            <MiniDestinationThumb destination={portugal[0]}/>
            <MiniDestinationThumb destination={portugal[1]}/>
            <MiniDestinationThumb destination={espanha[0]}/>
            <MiniDestinationThumb destination={espanha[1]}/>
          </div>
        </div>
      </Carousel>
    </Root>
  );
}
