import colors from 'colors';
import React from 'react';
import styled from 'styled-components';
import LegalText from 'ui-components/LegalText';
import TopBar from 'ui-components/TopBar';

const Root = styled.div`
  @media landscape{
    padding-top: 25px;
  }
`;
const Content = styled.div`
  margin-top: 25px;
  background: ${colors.background2};

  padding: 145px 340px 174px;

  @media portrait{
    padding: 75px 16px;
    margin: 0;
  }
`;

export default function Privacy({...props}){
  
  return (
    <Root {...props}>
      <TopBar/>
      <Content>
        <LegalText>
          <h1>Política de privacidade</h1>
          A Agência respeita a sua privacidade. Pelo que, esta Política de Privacidade descreve quem somos e, para que finalidades podemos usar os seus dados, como os tratamos, com quem os partilhamos, qual o período que os conservamos, a finalidade, assim como as formas de entrar em contacto connosco e de exercer os seus devidos direitos plasmados no Regulamento Geral de Proteção de Dados. <br/>
          <br/>
          Os seus dados serão tratados pela Agência de Viagens e Turismo de Lousada, Lda. pessoa coletiva n.º 503 111 627, com sede na Praça das Pocinhas nº9 Silvares Lousada, número de telefone +(351) 21 +351 255 814 009, doravante designada de “Agência ”.<br/>
          <br/>
          <br/>
          <strong>PORQUE TRATAMOS A SUA INFORMAÇÃO?</strong><br/>
          <br/>
          A Agência apenas tratará os seus dados pessoais quando:<br/>
          <br/>
          Tiver um interesse legítimo para o fazer, nomeadamente para:<br/>
          <br/>
          Dados de identificação e de contato;<br/>
          Emissão de fatura pelos serviços vendidos;<br/>
          Assegurar a segurança da rede e de informação;<br/>
          Prestação de serviços e envio de newslwtters.<br/>
          <br/>
          Desde que, em cada caso, o nosso interesse se encontre em conformidade com a legislação aplicável e os seus direitos, bem como,<br/>
          <br/>
          <br/>
          <strong>O TRATAMENTO DA INFORMAÇÃO FORNECIDA À AGÊNCIA</strong><br/>
          <br/>
          Quando preenche os formulários disponibilizados no nosso site, os seus dados pessoais (nome, morada, e-mail, contacto telefónico etc), são recolhidos pela Agência e tratados de forma a responder às suas questões, pedidos ou a prestar-lhe a devida informação.<br/>
          <br/>
          <br/>
          <strong>PERÍODO DE CONSERVAÇÃO DA SUA INFORMAÇÃO</strong><br/>
          <br/>
          A nossa política consiste na conservação dos seus dados apenas durante o período necessário para a observância dos fins para os quais estes foram recolhidos. No entanto, e por decurso da lei em determinadas situações poderemos ter que conservar os dados durante períodos mais longos.<br/>
          <br/>
          <br/>
          <strong>OS SEUS DIREITOS</strong><br/>
          <br/>
          A qualquer momento, poderá:<br/>
          <br/>
          1.Solicitar o acesso à informação dos dados que nos forneceu. Como titular dos dados pessoais, tem o direito a obter a confirmação de que os dados fornecidos e que lhe digam respeito são ou não objeto de tratamento e, se for o caso, de aceder aos seus dados pessoais e aceder às informações previstas na lei.<br/>
          2.Solicitar a retificação dos seus dados caso estejam inexatos ou incompletos. Como titular dos dados pessoais, tem o direito a que a Agência sem demora injustificada, retifique os seus dados, em conformidade com a inexatidão dos mesmos.<br/>
          3.Solicitar o apagamento dos seus dados pessoais.<br/>
          4.Solicitar a limitação do tratamento dos seus dados pessoais.<br/>
          5.Opor-se ao tratamento dos seus dados pessoais<br/>
          6.Se o tratamento depender do seu consentimento, tem direito a retirá-lo.<br/>
          <br/>
          Caso pretenda exercer qualquer um dos seus direitos, deverá contactar-nos, por escrito, através do e-mail geral@viagenslousada.pt ou de carta, enviado para a morada supra referida.<br/>
          <br/>
          Os seus pedidos serão tratados com especial cuidado de forma a que possamos assegurar a eficácia dos seus direitos. Poderá ser-lhe pedido que faça prova da sua identidade de modo a assegurar que a partilha dos dados pessoais é apenas feita com o seu titular.<br/>
          <br/>
          Tem ainda o direito de apresentar uma reclamação à Comissão Nacional de Proteção de Dados https://www.cnpd.pt/.<br/>
          <br/>
          <br/>
          <strong>SEGURANÇA E CONFIDENCIALIDADE</strong><br/>
          <br/>
          Empenhamo-nos em manter os seus dados seguros, sendo que empregamos medidas de segurança apropriadas para assegurar que a proteção dos seus dados pessoais e impedir o acesso a pessoas não autorizadas. Submetemos os nossos sistemas e políticas de segurança a análises periódicas de forma a garantir que os dados estão seguros e protegidos. No entanto, a transmissão de informação através de Internet não é completamente segura, e não podemos garantir absolutamente a segurança da informação transmitida através do nosso sítio.<br/>
          <br/>
          Respeitamos também a confidencialidade da sua informação. Como tal, não vendemos, distribuímos ou de qualquer outro modo disponibilizamos comercialmente a sua informação a qualquer terceiro. A Agência assume o compromisso de manter a sua informação confidencial de acordo com esta Política de Privacidade e legislação aplicável.<br/>
          <br/>
        </LegalText>
      </Content>
    </Root>
  );
}
