import colors from 'colors';
import React from 'react';
import styled from 'styled-components';
import LegalText from 'ui-components/LegalText';
import TopBar from 'ui-components/TopBar';

const Root = styled.div`
  @media landscape{
    padding-top: 25px;
  }
`;
const Content = styled.div`
  margin-top: 25px;
  background: ${colors.background2};

  padding: 145px 340px 174px;

  @media portrait{
    padding: 75px 16px;
    margin: 0;
  }
`;

export default function Terms({...props}){
  
  return (
    <Root {...props}>
      <TopBar/>
      <Content>
        <LegalText>
          <h1>Condições Gerais</h1><br/>
          <br/>
          O presente programa da Viagem Organizada é o documento informativo no qual se inserem as presentes condições gerais, dele fazendo parte integrante e que constituem, caso não exista, o Contrato de Viagem Organizada, nos termos do art. 20.º do DL n.º 17/2018 de 8 de Março.<br/>
          A presente informação é vinculativa para a agência, nos termos do art. 24º do DL nº 17/2018 de 8 de Março, salvo se, cumulativamente:<br/>
          •	O programa o prever expressamente;<br/>
          •	As alterações ao mesmo sejam insignificantes;<br/>
          •	A informação da alteração seja prestada ao viajante em suporte duradouro.<br/>
          As presentes condições gerais obedecem ao disposto no DL nº 17/2018 de 08 de Março. As Condições Gerais cujo objecto seja uma Viagem Organizada ou Serviço de Viagem Conexo constante do presente programa, as correspondentes Fichas de Informação Normalizada e as Condições Particulares que constam da documentação de viagem facultada ao Viajante no momento de reserva da viagem e, consubstanciam o contrato de viagem que vincula as partes. <br/>
          <br/>
          <strong>ORGANIZAÇÃO</strong><br/>
          <br/>
          A organização e comercialização da/s viagem/ns incluída/s no/a presente programa/brochura é da Agência de Viagens e Turismo de Lousada,  com sede em Praça das Pocinhas 9 4620-657 Lousada, NIPC 503 111 627, RNAVT Nº  2064, com o capital social realizado de €  249 398,94, telefone 255 814 009 , e-mail geral@viagenslousada.pt.<br/>
          <br/>
          <strong>INSCRIÇÕES</strong><br/>
          <br/>
          i. No acto da inscrição o Viajante deverá depositar 40% do preço do serviço, liquidando os restantes 60% até 21 (vinte e um) dias antes do início do serviço.<br/>
          ii. Se a inscrição tiver lugar a 21 (vinte e um) dias ou menos da data do início do serviço, o preço total do mesmo deverá ser pago no acto da inscrição.<br/>
          iii. A       reserva-se no direito de anular qualquer inscrição cujo pagamento não tenha sido efectuado nas condições acima mencionadas. <br/>
          iv. As reservas encontram-se condicionadas à obtenção da confirmação de todos os serviços por parte dos fornecedores.<br/>
          <br/>
          <strong>ENTIDADES DE RESOLUÇÃO ALTERNATIVA DE LITÍGIOS DE CONSUMO</strong><br/>
          <br/>
          Nos termos da Lei nº 144/2015 de 8 de Setembro, na sua redacção actual, informamos que o viajante poderá recorrer às seguintes Entidades de Resolução Alternativa de Litígios de Consumo:<br/>
          i. Comissão Arbitral do Turismo de Portugal in www.turismodeportugal.pt <br/>
          ii. Outras Entidades de Resolução Alternativa de Litígios de Consumo, que constem na lista disponibilizada pelo portal do consumidor in www.consumidor.pt <br/>
          iii. Provedor do Cliente das Agências de Viagens (quando a agência de viagens é associada da APAVT – Associação Portuguesa das Agências de Viagens e Turismo) in www.provedorapavt.com<br/>
          <br/>
          <strong>RECLAMAÇÔES</strong><br/>
          <br/>
          i. Qualquer desconformidade na execução de um serviço incluído no contrato de viagem organizada tem de ser comunicada à agência de viagens organizadora ou retalhista por escrito ou outra forma adequada logo que tal desconformidade ocorra, ou seja, sem demora injustificada, nos termos do art.º 28 nº1 do DL nº17/2018 de 8 de Março.<br/>
          ii. O direito a apresentar reclamações para efeitos de redução de preço ou direito a indemnização por falta de conformidade dos serviços de viagem incluídos na viagem organizada prescreve no prazo de 2 (dois) anos, nos termos do art.º 28 nº5 do DL nº 17/2018 de 8 de Março.<br/>
          <br/>
          <strong>BAGAGEM</strong><br/>
          <br/>
          i. A agência é responsável pela bagagem do Viajante nos termos legais;<br/>
          ii. O Viajante tem obrigação de reclamar junto da entidade prestadora dos serviços no momento de subtracção, deterioração ou destruição de bagagem. <br/>
          iii. No transporte internacional, em caso de dano na bagagem, a reclamação deverá ser feita por escrito ao transportador imediatamente após a verificação do dano, e no máximo 7 (sete) dias a contar da sua entrega. Estando em caso o mero atraso na entrega da bagagem a reclamação deverá ser feita dentro de 21 (vinte e um) dias a contar da data de entrega da mesma.<br/>
          iv. A apresentação de tal reclamação será fundamento essencial para o accionamento da responsabilidade da       sobre a entidade prestadora do serviço.<br/>
          <br/>
          <strong>LIMITES DE RESPONSABILIDADE</strong><br/>
          <br/>
          i. A responsabilidade da agência terá como limite o montante máximo exigível às entidades prestadoras dos serviços, nos termos da Convenção de Montreal, de 28 de maio de 1999, sobre Transporte Aéreo Internacional, e da Convenção de Berna, de 1961, sobre Transporte Ferroviário.<br/>
          ii. No que concerne aos transportes marítimos, a responsabilidade das agências de viagens, relativamente aos seus Viajantes, pela prestação de serviços de transporte, ou alojamento, quando for caso disso, por empresas de transportes marítimos, no caso de danos resultantes de dolo ou negligência destas terá, nos termos do art.º 36. nº 2 do DL n.º 17/2018 de 8 de Março, como limites os seguintes montantes:<br/>
          a) € 441.436 (quatrocentos e quarenta e um mil quatrocentos e trinta e seis euros), em caso de morte ou danos corporais;<br/>
          b) € 7.881 (sete mil oitocentos e oitenta e um euros), em caso de perda total ou parcial de bagagem ou da sua danificação;<br/>
          c) € 31.424 (trinta e um mil quatrocentos e vente e quatro), em caso de perda de veículo automóvel, incluindo a bagagem nele contida;<br/>
          d) €10.375 (dez mil trezentos e setenta e cinco euros), em caso de perda de bagagem, acompanhada ou não, contida em veículo automóvel;<br/>
          e) € 1.097 (mil e noventa e sete euros), por danos na bagagem, em resultado da danificação do veículo automóvel.<br/>
          iii. Quando exista, a responsabilidade das agências de viagens e turismo pela deterioração, destruição e subtracção de bagagens ou outros artigos, em estabelecimentos de alojamento turístico, enquanto o Viajante aí se encontrar alojado tem, nos termos do art.º 36. Nº 3 do DL n.º 17/2018 de 8 de Março, como limites:<br/>
          a) € 1.397,00 (mil trezentos e noventa e sete euros), globalmente;<br/>
          b) € 449,00 (quatrocentos e quarenta e nove euros) por artigo;<br/>
          c) O valor declarado pelo Viajante, quanto aos artigos depositados à guarda do estabelecimento de alojamento turístico.<br/>
          iv. A responsabilidade da agência por danos não corporais está contratualmente limitada ao valor correspondente a três vezes o preço do serviço vendido.<br/>
          <br/>
          <strong>DOCUMENTAÇÃO</strong><br/>
          <br/>
          i. O Viajante deverá possuir válida toda a sua documentação pessoal ou familiar:<br/>
          •	Cartão de cidadão.           <br/>
          •	Passaporte<br/>
          •	Autorização para menores (quando necessária)<br/>
          •	Visto (quando necessário)<br/>
          •	Certificado de vacinas (Quando necessário)<br/>
          •	E outros eventualmente exigidos<br/>
          •	A agência declina qualquer responsabilidade pela recusa de concessão de vistos ou a não permissão de entrada ao Viajante em país estrangeiro; sendo ainda da conta do Viajante todo e qualquer custo que tal situação acarretar.<br/>
          ii. Viagens dentro da União Europeia:<br/>
            Os Viajantes (independentemente da idade) que se desloquem dentro da União Europeia deverão ser possuidores do respetivo documento de identificação civil (Passaporte, Cartão do Cidadão);<br/>
            Para obtenção de assistência médica devem ser portadores do respetivo Cartão Europeu do Seguro de Doença; <br/>
            Os nacionais de países não comunitários devem consultar informação específica quanto à documentação necessária para realização de viagem junto das embaixadas/ consulados dos países de origem.<br/>
          iii. Viagens fora da União Europeia: <br/>
            Os Viajantes (independentemente da idade) que se desloquem para fora da União Europeia deverão ser possuidores do respetivo documento de identificação civil (passaporte) bem como do visto se necessário (obtenha tal informação junto da agência no momento da reserva);<br/>
            Os nacionais de países não comunitários devem consultar informação específica quanto à documentação necessária para realização de viagem junto das embaixadas/consulados dos países de origem.<br/>
          <br/>
          <strong>DESPESAS DE RESERVAS E DE ALTERAÇÃO</strong><br/>
          <br/>
          i. Por cada reserva serão cobradas as seguintes verbas sob o conceito de Despesas de Reserva:<br/>
            Portugal: € 20 <br/>
            Restantes Países: € 20 <br/>
          ii. Despesas de Alterações: <br/>
          Por cada alteração (nomes, datas, tipo de apartamento ou quarto, viagem, etc): € 20. <br/>
          A aceitação de tais alterações depende de aceitação por parte dos respectivos fornecedores.<br/>
          <br/>
          <strong>ALTERAÇÕES SOLICITADAS PELO VIAJANTE</strong><br/>
          <br/>
          i. Caso os fornecedores da viagem em causa permitam, sempre que um Viajante, inscrito para uma determinada viagem, desejar mudar a sua inscrição para uma outra viagem ou para a mesma com partida em data diferente, ou outra eventual alteração, deverá pagar a taxa acima referida, como despesas de alteração. Contudo, quando a mudança tiver lugar com 21 (vinte e um) dias ou menos de antecedência em relação à data da partida da viagem, para a qual o Viajante se encontra inscrito, ou se os fornecedores de serviços não aceitarem a alteração, fsujeito às despesas e encargos previstos na cláusula “Rescisão do Contrato pelo Viajante”. <br/>
          ii. Após iniciada a viagem, se solicitada a alteração dos serviços contratados por motivos não imputáveis à agência (ex. ampliação das noites de estada, alteração de voo) os preços dos serviços turísticos poderão não corresponder aos publicados no folheto que motivou a contratação.<br/>
          <br/>
          <strong>ALTERAÇÕES A EFECTUAR PELA AGÊNCIA</strong><br/>
          <br/>
          i. Sempre que, antes do início da viagem organizada, <br/>
          (i) a agência de viagens e turismo se veja obrigada a alterar significativamente alguma das características principais dos serviços de viagem, <br/>
          (ii) ou não consiga ir de encontro às exigências especiais solicitada pelo Viajante; <br/>
          (iii) ou propuser o aumento do preço da viagem organizada em mais de 8%, o viajante pode, no prazo de 02  (dois) dias: <br/>
          a) Aceitar a alteração proposta;<br/>
          b) Rescindir o contrato, sem qualquer penalização, sendo reembolsado das quantias pagas;<br/>
          c) Aceitar uma viagem organizada de substituição proposta pela agência de viagens e turismo, sendo reembolsado em caso de diferença de preço.<br/>
          ii. A ausência de resposta por parte do viajante no prazo fixado pela agência de viagens e turismo implicará a aceitação tácita da alteração proposta / o cancelamento da viagem com a aplicação das respetivas taxas de rescisão previstas na cláusula (“RESCISÃO DO CONTRATO DE VIAGEM PELO VIAJANTE”)<br/>
          <br/>
          <strong>CESSÃO DA POSIÇÃO CONTRATUAL</strong><br/>
          <br/>
          i. O Viajante pode, nos termos do art.º 22 nº 1 do DL n.º 17/2018 de 8 de Março, ceder a sua posição, fazendo-se substituir por outra pessoa que preencha todas as condições requeridas para a viagem organizada, desde que informe a agência de viagens e turismo, por forma escrita, até sete dias seguidos antes da data prevista para a partida.<br/>
          ii. O cedente e o cessionário, nos termos do art.º 22. Nº 2 do DL n.º 17/2018 de 8 de Março, são solidariamente responsáveis pelo pagamento do saldo em dívida e pelas taxas, os encargos ou custos adicionais originados pela cessão, os quais serão devidamente informados e comprovados pela agência de viagens e turismo.<br/>
          <br/>
          <strong>RESCISÃO DO CONTRATO DE VIAGEM ORGANIZADA PELA AGÊNCIA</strong><br/>
          <br/>
          i. Quando a viagem esteja dependente de um número mínimo de participantes a Agência reserva-se o direito de cancelar a viagem organizada caso o número de participantes alcançado seja inferior ao mínimo. Nestes casos, o viajante será informado por escrito do cancelamento no prazo de: <br/>
          a) 20 (vinte) dias antes do início da viagem organizada, no caso de viagens com duração superior a seis dias;<br/>
          b) 7 (sete) dias antes do início da viagem organizada, no caso de viagens com duração de dois a seis dias;<br/>
          c) 48 (quarenta e oito) horas antes do início da viagem organizada, no caso de viagens com duração inferior a dois dias.<br/>
          ii. Antes do início da viagem organizada a agência de viagens e turismo poderá ainda rescindir o contrato se for impedida de executar o mesmo devido a circunstâncias inevitáveis e excepcionais.<br/>
          iii. A rescisão do contrato de viagem pela agência nos termos acima referidos apenas confere ao viajante o direito ao reembolso integral dos pagamentos efectuados no prazo máximo de 14 (catorze) dias após a rescisão do contrato de viagem, nos termos do art.º 27 nº 5 do DL n.º 17/2018 de 8 de Março.<br/>
          <br/>
          <strong>REEMBOLSOS</strong><br/>
          <br/>
          Depois de iniciada a viagem não é devido qualquer reembolso por serviços não utilizados pelo Viajante por motivos de força maior ou por causa imputável ao Viajante, salvo reembolso pelos respectivos fornecedores. A não prestação de serviços previstos no programa de viagem por causas imputáveis à agência organizadora, e caso não seja possível a substituição por outros equivalentes, confere ao Viajante o direito a ser reembolsado pela diferença entre o preço dos serviços previstos e o dos efectivamente prestados.<br/>
          <br/>
          <br/>
          <strong>ALTERAÇÃO DO PREÇO</strong><br/>
          <br/>
          i. Os preços constantes do programa estão baseados nos custos dos serviços e taxas de câmbio vigentes à data de criação/impressão deste programa, pelo que estão sujeitos a alterações (aumento ou redução de preço), nos termos do art.º 29. do DL n.º 17/2018 de 8 de Março, que resultem de variações no custo dos    <br/>
          transportes ou do combustível, impostos, taxas e flutuações cambiais até 20 (vinte) dias antes da data de viagem. <br/>
          ii. Caso o aumento em causa exceda 8% (oito por cento) do preço total da viagem organizada, aplicar- se-á o disposto na cláusula “ALTERAÇÕES A EFECTUAR PELA AGÊNCIA”.<br/>
          iii. Em caso de redução de preço a agência de viagens e turismo reserva-se ao direito de deduzir ao reembolso a efectuar ao viajante as correspondentes despesas administrativas, que a pedido do viajante serão justificadas. <br/>
          <br/>
          <strong>RESCISÃO DO CONTRATO DE VIAGEM PELO VIAJANTE</strong><br/>
          <br/>
          i. O viajante pode rescindir o contrato de viagem a todo o tempo antes do início da viagem, nos termos do art.º 25. nº 1 do DL n.º 17/2018 de 8 de Março.<br/>
          ii. No caso de rescisão do contrato nos termos da alínea anterior, nos termos do art.º 25. Nº 2 do DL n.º 17/2018 de 8 de Março, o viajante pagará uma taxa de rescisão nos seguintes termos:<br/>
          a) (a definir consoante a data de antecedência da rescisão, as economias de custos e receitas em resultado da reafectação dos serviços de viagem).<br/>
          iii. Tal rescisão implica que o mesmo seja responsável pelo pagamento de todos os encargos a que o início do cumprimento do contrato e a sua desistência dêem lugar, menos a reafectação de serviços e as economias de custos.<br/>
          iv. Quando seja caso disso, o Viajante será reembolsado pela diferença entre a quantia paga e os montantes acima referidos. Na presente situação o reembolso será efectuado, deduzido da taxa de rescisão, no prazo máximo de 14 (catorze) dias após a rescisão do contrato de viagem, nos termos do art.º 25. nº7 do DL n.º 17/2018 de 8 de Março. <br/>
          v. O Viajante tem ainda direito a rescindir o contrato de viagem antes do início da mesma sem pagar qualquer taxa de rescisão, caso se verifiquem circunstâncias inevitáveis e excepcionais no local de destino ou na sua proximidade imediata que afectem conside-ravelmente a realização da mesma ou o transporte dos passageiros para o destino, nos termos do art.º 25. nº4 do DL n.º 17/2018 de 8 de Março. <br/>
          vi. A rescisão do contrato de viagem nos termos supra-referidos, apenas confere ao viajante o direito ao reembolso integral dos pagamentos efectuados, nos termos do art.º 25. Nº 5 do DL n.º 17/2018 de 8 de Março.<br/>
          <br/>
          <strong>ASSISTÊNCIA AOS VIAJANTES</strong><br/>
          <br/>
          i. Em caso de dificuldades do Viajante, ou quando por razões que não lhe forem imputáveis, este não possa terminar a viagem organizada, a agência de viagens e turismo é obrigada a dar-lhe assistência, nomeadamente: <br/>
          a) Disponibilizando informações adequadas sobre os serviços de saúde, as autoridades locais e a assistência consular; e<br/>
          b) Auxiliando o viajante na realização de comunicações à distância e a encontrar soluções alternativas de viagem.<br/>
          ii. A agência de viagens e turismo pode cobrar uma taxa no valor dos custos em que incorreu em virtude da prestação dessa assistência, caso a dificuldade que fundamenta o pedido de de assistência tenha sido causada pelo viajante de forma deliberada ou por<br/>
          negligência, não podendo, contudo, exceder os custos efetivamente incorridos pela agência.<br/>
          iii. Se devido a circunstâncias inevitáveis e excepcionais, o viajante não puder regressar, a agência de viagens e turismo organizadora é responsável por assegurar os custos de alojamento necessários, se possível de categoria equivalente, por um período não superior a três noites por viajante.<br/>
          iv. A agência de viagens e turismo retalhista é solidariamente responsável pela obrigação em causa, sem prejuízo do direito de regresso, nos termos gerais aplicáveis.<br/>
          v. A limitação dos custos prevista supra não se aplica às pessoas com mobilidade reduzida, nem aos respetivos acompanhantes, às grávidas e às crianças não acompanhadas, nem às pessoas que necessitem de cuidados médicos específicos, desde que a agência de viagens e turismo tenha sido notificada dessas necessidades específicas pelo menos 48 horas antes do início da viagem organizada.<br/>
          <br/>
          <strong>RESPONSABILIDADE DA AGÊNCIA DE VIAGENS</strong><br/>
          <br/>
          i. A agência de viagens e turismo é responsável pela correcta execução de todos os serviços de viagem incluídos no contrato de viagem.<br/>
          ii. Quando se tratar de viagens organizadas, as agências de viagens e turismo são responsáveis perante os Viajantes, ainda que os serviços devam ser executados por terceiros e sem prejuízo do direito de regresso, nos termos gerais aplicáveis.<br/>
          iii. As agências de viagens e turismo organizadoras respondem solidariamente com as agências retalhistas, no caso de viagens organizadas.<br/>
          iv. Nos restantes serviços de viagens, a agência de viagens e turismo responde pela correcta emissão dos títulos de alojamento e de transporte e ainda pela escolha culposa dos prestadores de serviços, caso estes não tenham sido sugeridos pelo viajante.<br/>
          v. A agência de viagens e turismo que intervenha como intermediária em vendas ou reservas de serviços de viagem avulsos é responsável pelos erros de emissão dos respectivos títulos, mesmo nos casos decorrentes de deficiências técnicas nos sistemas de reservas que lhes sejam imputáveis.<br/>
          vi. A agência de viagens e turismo é responsável por quaisquer erros devido a deficiências técnicas no sistema de reservas que lhe sejam imputáveis e, se tiver aceite proceder à reserva de uma viagem organizada ou de serviços de viagem que façam parte de serviços de viagem conexos, pelos erros cometidos durante o processo de reserva.<br/>
          vii. A agência de viagens e turismo não é responsável por erros na reserva que sejam imputáveis ao viajante ou que sejam causados por circunstâncias inevitáveis e excepcionais.<br/>
          <br/>
          <strong>INSOLVÊNCIA</strong><br/>
          <br/>
          Em caso de insolvência da agência de viagens e turismo o viajante pode recorrer ao Fundo de Garantia de Viagens e Turismo, devendo para tal recorrer ao Turismo de Portugal I.P., entidade responsável pelo respetivo accionamento: Turismo de Portugal, I.P.<br/>
          Rua Ivone Silva, Lote 6, 1050-124 Lisboa <br/>
          Tel. 211 140 200 | Fax. 211 140 830 E-mail: info@turismodeportugal.pt<br/>
          <br/>
          <strong>SEGUROS</strong><br/>
          <br/>
          A responsabilidade da agência de viagens organizadora/vendedora deste programa e emergentes das obrigações assumidas, encontra-se garantida por seguro de responsabilidade civil na Companhia Zurich, apólice n.º 004906881, no montante de € 75 000,00 e nos termos da legislação em vigor.<br/>
          A agência disponibiliza ainda a venda de seguros que poderão ser adquiridos em função da viagem para garantia de situações de assistência e despesas de cancelamento. <br/>
          <br/>
          <strong>IMPOSTOS</strong><br/>
          <br/>
          Os preços mencionados neste programa reflectem o previsto no DL 221/85 de 3 de julho, I. V. A. na margem.<br/>
          <br/>
          <strong>VALIDADE DO PROGRAMA</strong><br/>
          <br/>
          Este programa é válido no período em que a viagem se realiza.     <br/>
          <br/>
        </LegalText>
      </Content>
    </Root>
  );
}
