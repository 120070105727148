import colors from 'colors';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import useAnimationFrame from 'use-animation-frame';
import Button from 'ui-components/Button';
import useLocalStorage from 'use-local-storage';
import useMediaQuery from 'use-media-query';
import mediaQueries from 'css-toolkit/media-queries';
import useWindowHeight from 'use-window-height';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  transition: opacity 200ms;
  &[data-visible=false]{
    opacity: 0;
    pointer-events: none;
  }
`;
const Content = styled.div`
  position: absolute;
  bottom: 16px;
  left: 290px;
  width: 1340px;
  padding: 24px 50px;
  background: ${colors.background};

  display: grid;
  grid-template-columns: auto 134px;

  @media portrait{
    width: calc(100% - 32px);
    padding: 18px 16px;
    left: 16px;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  & > :first-child{
    font-size: 16px;
    line-height: 1.25;
    color: ${colors.foreground};

    display: flex;
    flex-direction: column;
    justify-content: center;

    & a{
      color: ${colors.primary};
      &:hover{
        color: ${colors.secondary};
      }
    }

    @media portrait{
      text-align: center;
      & br{
        display: none;
      }
    }
  }
`;

export default function CookiesPopup({...props}){

  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage('cookies-accepted',false);
  
  const [scrolled, setScrolled] = useState(true);

  const portrait = useMediaQuery(mediaQueries.portrait);

  useAnimationFrame(()=>{
    const scrollTop = document.scrollingElement.scrollTop;
    const windowHeight = window.innerHeight;
    setScrolled(scrollTop <= (portrait ? 3 : windowHeight / 30));
  },[portrait]);

  const visible = !cookiesAccepted && scrolled;

  const acceptCookies = useCallback(()=>{
    setCookiesAccepted(true);
  },[setCookiesAccepted]);

  const windowHeight = useWindowHeight();

  return (
    <Root
      style={{maxHeight: windowHeight ? `${windowHeight}px` : 'none'}}
      data-visible={visible}
      {...props}
    >
      <Content>
        <div>
          <span>
            Este website utiliza cookies para proporcionar uma melhor experiência aos seus utilizadores. <br/>
            Ao navegar no nosso website concorda com a utilização dos cookies nos termos definidos na nossa
            <a href="/cookies"> Política de Cookies</a>.
          </span>
        </div>
        <Button onClick={acceptCookies}>Aceitar</Button>
      </Content>
    </Root>
  );
}
