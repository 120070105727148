import colorOpacity from 'color-opacity';
import colors from 'colors';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import useExternalState from 'use-external-state';
import Dropdown from './Dropdown';

const Root = styled.div`
  width: 100%;
  overflow: hidden;
  & > div{
    width: calc(100% + 30px);
    max-height: 400px;
    overflow-y: scroll;
    @media portrait{
      max-height: 300px;
    }
  }
`;
const Option = styled.div`
  height: 39px;

  padding: 0 19px;

  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 39px;
  text-align: left;
  white-space: nowrap;

  @media portrait{
    height: 60px;
    line-height: 60px;
    padding: 0 24px;
  }
  
  color: ${colors.primary};
  background: ${colors.background};
  @media landscape{
    &:hover[data-separator=false]&[data-selected=false]{
      color: ${colors.primary};
      background: ${colorOpacity(colors.secondary,0.2)};
    }
  }
  &[data-selected=true]{
    color: ${colors.primary};
    background: ${colors.secondary};
  }

  cursor: pointer;

  &[data-separator=true]{
    height: 20px;
    cursor: default;
  }
`;

export default function Select({
  label,
  options=[],
  name,
  selectedOption: extSelectedOption=null,
  setSelectedOption: extSetSelectedOption=null,
  ...props
}){

  const [selectedOption,setSelectedOption] = useExternalState(
    extSelectedOption,
    extSetSelectedOption,
    null
  );

  const [open,setOpen] = useState(false);

  const handleOptionClick = useCallback(ev=>{
    const optionValue = ev.target.dataset.value;
    setOpen(false);
    setSelectedOption(optionValue);
  },[setSelectedOption,setOpen]);

  const displayLabel = selectedOption ? <>
    <span>{label}: </span>
    <strong>{options.filter(option => (
      option.value === selectedOption 
    ))[0].label}</strong>
  </> : label;

  return <>
    {name ? <input type="hidden" name={name} value={selectedOption || ""}/> : null}
    <Dropdown
      {...props}
      label={displayLabel}
      open={open}
      setOpen={setOpen}
      placeholderShown={selectedOption === null}
    >
      <Root>
        <div>
          {options.map((option,idx) => (
            <Option
              data-selected={option.value && option.value === selectedOption}
              key={idx}
              onClick={option ? handleOptionClick : undefined}
              data-separator={!option}
              data-value={option.value}
            >
              {option.label}
            </Option>
          ))}
        </div>
      </Root>
    </Dropdown>
  </>;
}
