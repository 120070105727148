import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Root = styled(Link)`
  display: inline-block;
  width: 183px;
  height: 100%;
  
  & + &{
    margin-left: 5px;
  }
`;
const PictureWrapper = styled.div`
  position: relative;
`;
const Picture = styled.div`
  height: 183px;
  [data-large=true] &{
    height: 322px;
  }
  background: url(${p => p.$src}) center / cover no-repeat;
  filter: brightness(60%) contrast(105%);
`;
const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  white-space: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  [data-large=true] &{
    justify-content: flex-start;
    padding-top: 36px;
  }

  z-index: 1;

  font-family: ${secondaryFontFamily};
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: ${colors.background};
  text-align: center;
`;
const Info1 = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  [data-large=true] &{
    bottom: 30px;
  }

  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: ${colors.background};
`;
const Price = styled.div`
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: ${colors.primary};
  font-weight: 500;
  & > *{
    font: inherit;
  }
  & strong{
    font-weight: 600;
  }
  [data-large=true] &{
    color: ${colors.background};
  }

  margin-top: 10px;
`;
export default function MiniDestinationThumb({
  destination,
  large,
  ...props
}){

  const url = destination.id ? `/destinos/${destination.category}/${destination.id}` : '/';

  return (
    <Root data-large={large} to={url} {...props}>
      <PictureWrapper>
        <Picture $src={destination.pictures[0]}/>
        <Title>
          {destination.title}
        </Title>
        <Info1>
          {destination.info[0]}
        </Info1>
      </PictureWrapper>
      <Price>
        <span>Desde:</span><br/>
        <strong>{destination.price}</strong>
      </Price>
    </Root>
  );
}
