const colors = {
  primary: '#4E04D8',
  secondary: '#FFAD00',
  secondaryHover: '#FFD478',
  background: '#fff',
  background2: '#F7F6F9',
  foreground: '#000',
};

export default colors;
