import colors from 'colors';
import colorOpacity from 'color-opacity';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import plus from './assets/plus.svg';
import minus from './assets/minus.svg';
import useExternalState from 'use-external-state';

// const Error = styled.div`
//   font-size: 10px;
//   font-weight: 600;
//   line-height: 1;
//   color: ${colors.secondary};
//   text-transform: uppercase;
  
//   margin-top: -10px;
//   position: relative;
//   text-align: right;
//   top: -22px;

//   pointer-events: none;
//   display: none;
// `;
const Root = styled.div`
  width: 100%;

  user-select: none;

  padding: 16px 0 16px 14px;
  border-bottom: 2px solid ${colorOpacity(colors.background,0.3)};
  &[data-single=true]{
    border-top: 2px solid ${colorOpacity(colors.background,0.3)};
  }
  &[data-variant=light]{
    border-top-color: ${colors.foreground};
    border-bottom-color: ${colors.foreground};
  }
  &[data-variant=ultralight]{
    border-top-color: ${colors.background};
    border-bottom-color: ${colors.background};
  }

  & > *{
    vertical-align: center;
    /* background: green !important; */
  }
`;
const Placeholder = styled.div`
  display: inline-block;

  height: 100%;

  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colorOpacity(colors.background,0.6)};
  [data-variant=light] > &{
    color: ${colorOpacity(colors.foreground,0.6)};
  }
  [data-variant=ultralight] > &{
    color: ${colorOpacity(colors.foreground,0.3)};
  }

  margin-right: 18px;
`;
const ControlButton = styled.div`
  display: inline-block;
  position: relative;
  top: 1px;
  
  height: 14px;
  width: 14px;

  margin: 0 8px;

  background: url(${minus}) center / contain no-repeat;
  &:last-of-type{
    background-image: url(${plus});
  }
  
  //todo: put in a separate file
  &:hover{
    filter: brightness(120%);
  }

  cursor: pointer;
`;
const Display = styled.div`
  display: inline-block;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.69;
  color: ${colors.background};
  [data-variant=light] > &,
  [data-variant=ultralight] > &{
    color: ${colors.foreground};
  }

  width: 1.5em;
  text-align: center;
`;

const InputNumber = React.forwardRef(function InputNumber({
  variant="dark",
  single="false",
  placeholder=null,
  value: extValue=null,
  setValue: extSetValue=null,
  min=-Infinity,
  max=+Infinity,
  name,
  ...props
},ref){

  const [value,setValue] = useExternalState(
    extValue,
    extSetValue,
    0
  );

  const increase = useCallback(()=>{
    setValue(x => x<max ? x+1 : x);
  },[max,setValue]);
  const decrease = useCallback(()=>{
    setValue(x => x>min ? x-1 : x);
  },[min,setValue]);

  return <>
    <Root
      data-variant={variant}
      data-single={single}
      {...props}
    >
      {name?<input type="hidden" name={name} value={value} />:null}
      <Placeholder>{placeholder}</Placeholder>
      <ControlButton onClick={decrease}/>
      <Display>{value}</Display>
      <ControlButton onClick={increase}/>
    </Root>
    {/* <Error>
      Campo obrigatório
    </Error> */}
  </>;
});

export default InputNumber;
