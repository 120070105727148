import colorOpacity from 'color-opacity';
import colors from 'colors';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import useExternalState from 'use-external-state';
import Dropdown from './Dropdown';

const Root = styled.div`
  width: 100%;
  overflow: hidden;
  & > div:first-child{
    display: grid;
    grid-template-columns: repeat(6,52px);
    padding: 5px 20px;
    gap: 5px;
  }
`;
const Option = styled.div`
  height: 52px;
  border-radius: 6px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  
  color: ${colors.primary};
  background: ${colors.background2};
  @media landscape{
    &:hover[data-separator=false]&[data-selected=false]{
      color: ${colors.primary};
      background: ${colorOpacity(colors.secondary,0.2)};
    }
  }
  &[data-selected=true]{
    color: ${colors.primary};
    background: ${colors.secondary};
  }

  cursor: pointer;

  &[data-separator=true]{
    height: 20px;
    cursor: default;
  }
`;
const Clear = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 47px;

  & > div{
    display: inline-block;
    padding: 0 22px;
    font: inherit;
    line-height: inherit;
    color: ${colors.primary};
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
      color: ${colors.secondary};
    }
  }
`;

export default function SelectMonth({
  label,
  name,
  selectedOption: extSelectedOption=null,
  setSelectedOption: extSetSelectedOption=null,
  ...props
}){

  const options = [
    {label:'JAN',value:"1"},
    {label:'FEV',value:"2"},
    {label:'MAR',value:"3"},
    {label:'ABR',value:"4"},
    {label:'MAI',value:"5"},
    {label:'JUN',value:"6"},
    {label:'JUL',value:"7"},
    {label:'AGO',value:"8"},
    {label:'SET',value:"9"},
    {label:'OUT',value:"10"},
    {label:'NOV',value:"11"},
    {label:'DEZ',value:"12"},
  ];

  const [selectedOption,setSelectedOption] = useExternalState(
    extSelectedOption,
    extSetSelectedOption,
    null
  );

  const [open,setOpen] = useState(false);

  const handleOptionClick = useCallback(ev=>{
    const optionValue = ev.target.dataset.value;
    setOpen(false);
    setSelectedOption(optionValue);
  },[setSelectedOption,setOpen]);

  const clear = useCallback(()=>{
    setSelectedOption(null);
    setOpen(false);
  },[setSelectedOption,setOpen]);

  const displayLabel = selectedOption ? <>
    <span>{label}: </span>
    <strong>{options.filter(option => (
      option.value === selectedOption 
    ))[0].label}</strong>
  </> : label;

  return <>
    {name ? <input type="hidden" name={name} value={selectedOption || ""}/> : null}
    <Dropdown
      {...props}
      label={displayLabel}
      open={open}
      setOpen={setOpen}
      placeholderShown={selectedOption === null}
    >
      <Root>
        <div>
          {options.map((option,idx) => (
            <Option
              data-selected={option.value && option.value === selectedOption}
              key={idx}
              onClick={option ? handleOptionClick : undefined}
              data-separator={!option}
              data-value={option.value}
            >
              {option.label}
            </Option>
          ))}
        </div>
        <Clear><div onClick={clear}>Limpar</div></Clear>
      </Root>
    </Dropdown>
  </>;
}
