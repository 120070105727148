const espanha = [
  {
    id: 'barcelona',
    category: 'espanha',
    pictures: [
      require('temp-data/places/Barcelona/AVL0041.jpg').default,
      require('temp-data/places/Barcelona/AVL0042.jpg').default,
      require('temp-data/places/Barcelona/AVL0043.jpg').default,
      require('temp-data/places/Barcelona/AVL0044.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Ayre Hotel Rosellon/ES011-A.jpg').default,
      require('temp-data/hotels/Ayre Hotel Rosellon/ES011-B.jpg').default,
      require('temp-data/hotels/Ayre Hotel Rosellon/ES011-C.jpg').default,
      require('temp-data/hotels/Ayre Hotel Rosellon/ES011-D.jpg').default,
    ],
    title: 'Barcelona',
    info: [
      'Voo + 3 Noites',
      'Ayre Hotel Rosellón',
    ],
    price: '330,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'A cidade de Barcelona, Espanha, possui uma atmosfera própria e cheia de personalidade.\n\nCuriosamente, a cidade consegue misturar o novo e as tradições que carregam a cultura espanhola, mantendo suas raízes históricas enquanto se prepara para o futuro. Venha descobrir tudo sobre Barcelona, na bela costa mediterrânea, e explorar os seus encantos!',
    hotel:[
      ['Datas','A partir de Outubro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','a definir'],
      ['Aeroporto','Porto - Barcelona'],
      ['Data de regresso','a definir'],
      ['Aeroporto','Barcelona - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'salamanca',
    category: 'espanha',
    pictures: [
      require('temp-data/places/Salamanca/AVL0045.jpg').default,
      require('temp-data/places/Salamanca/AVL0046.jpg').default,
      require('temp-data/places/Salamanca/AVL0047.jpg').default,
      require('temp-data/places/Salamanca/AVL0048.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Parador de Salamanca/ES012-A.jpg').default,
      require('temp-data/hotels/Parador de Salamanca/ES012-B.jpg').default,
      require('temp-data/hotels/Parador de Salamanca/ES012-C.jpg').default,
      require('temp-data/hotels/Parador de Salamanca/ES012-D.jpg').default,
    ],
    title: 'Salamanca',
    info: [
      '2 Noites',
      'Parador de Salamanca',
    ],
    price: '124,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Salamanca é um município da província homônima, na comunidade autónoma de Castela e Leão, em Espanha.\n\nÉ uma das cidades espanholas mais ricas em monumentos da Idade Média, do Renascimento e das épocas barroca e neoclássica. Destacam-se as catedrais velha e nova, o Palácio da Salina, o Palácio de Anaya, o Palácio de Monterrey, a Casa das Conchas, a Casa Lis, o Convento das Senhoras  e a Torre do Cravo).',
    hotel:[
      ['Datas','A partir de Agosto de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
      ['Preço por noite','Desde 62,00€ por pessoa'],
    ],
    notesHotel: 'Preços sujeitos a alterações, mediante disponibilidade',
    flight:null,
    notesFlight: '',
  },
  {
    id: 'madrid',
    category: 'espanha',
    pictures: [
      require('temp-data/places/Madrid/AVL0049.jpg').default,
      require('temp-data/places/Madrid/AVL0050.jpg').default,
      require('temp-data/places/Madrid/AVL0051.jpg').default,
      require('temp-data/places/Madrid/AVL0052.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Hotel Avenida Gran Via/ES013-A.jpg').default,
      require('temp-data/hotels/Hotel Avenida Gran Via/ES013-B.jpg').default,
      require('temp-data/hotels/Hotel Avenida Gran Via/ES013-C.jpg').default,
      require('temp-data/hotels/Hotel Avenida Gran Via/ES013-D.jpg').default,
    ],
    title: 'Madrid',
    info: [
      'Voo + 3 Noites',
      'Hotel Avenida Gran Via',
    ],
    price: '275,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 3,
    about: 'Madrid é a capital da Espanha, assim como a cidade espanhola que mais visitantes atrai, com mais de 10 milhões de visitantes em 2019, sendo uma das mais populares da Europa.\n\nO motivo pelo qual o número de visitantes em Madrid aumenta a cada ano é a perfeita combinação de oferta cultural, monumental e de lazer. Passeando por Madrid, você poderá sentir a pura essência espanhola, presente nos seus edifícios cheios de elegância, na vida animada de suas praças e na gastronomia que encanta. ',
    hotel:[
      ['Datas','A partir de Setembro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','a definir'],
      ['Aeroporto','Porto - Madrid'],
      ['Data de regresso','a definir'],
      ['Aeroporto','Madrid - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'malaga',
    category: 'espanha',
    pictures: [
      require('temp-data/places/Malaga/AVL0053.jpg').default,
      require('temp-data/places/Malaga/AVL0054.jpg').default,
      require('temp-data/places/Malaga/AVL0055.jpg').default,
      require('temp-data/places/Malaga/AVL0056.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Sol Guadalmar/ES014-A.jpg').default,
      require('temp-data/hotels/Sol Guadalmar/ES014-B.jpg').default,
      require('temp-data/hotels/Sol Guadalmar/ES014-C.jpg').default,
      require('temp-data/hotels/Sol Guadalmar/ES014-D.jpg').default,
    ],
    title: 'Málaga',
    info: [
      '2 Noites',
      'Sol Guadalmar',
    ],
    price: '170,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Málaga é uma cidade da Andaluzia, na Espanha. Localiza-se na costa sul do país, no Mediterrâneo. A cidade originou-se da uma colônia grega na antiguidade clássica.\n\nMuitos procuram a Costa del Sol, uma região turística do sul de Espanha, na província de Málaga. Esta região dispõe de uma grande oferta hoteleira e de lazer, tanto de sol e praia como rural, mais de 70 campos de golf, três parques zoológicos e um parque de atrações.',
    hotel:[
      ['Datas','A partir de Agosto de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
      ['Preço por noite','Desde 85,00€ por pessoa'],
    ],
    notesHotel: 'Preços sujeitos a alterações, mediante disponibilidade ',
    flight:null,
    notesFlight: '',
  },
];
export default espanha;
