import requestEndpoint from "request-endpoint";

const servers = {
  development: 'http://localhost:5001/host-agencia-de-lousada/europe-west1',
  production: 'https://europe-west1-host-agencia-de-lousada.cloudfunctions.net',
};

const root = servers.production;

const endpoints = {
  sendOrder:{
    method: 'POST',
    resource: `/order`,
  },
  sendContact:{
    method: 'POST',
    resource: `/contact`,
  },
  sendAffiliates:{
    method: 'POST',
    resource: `/affiliates`,
  },
  sendCorporate:{
    method: 'POST',
    resource: `/corporate`,
  },
  sendPrebook:{
    method: 'POST',
    resource: `/prebook`,
  },
  sendHoneyMoon:{
    method: 'POST',
    resource: `/honeymoon`,
  },
  sendCustomTrip:{
    method: 'POST',
    resource: `/customtrip`,
  },
  subscribeNewsletter:{
    method: 'POST',
    resource: `/newsletter`,
  }
};

export function sendContact(form){
  return requestEndpoint(
    root,
    endpoints.sendContact,
    form
  );
}
export function sendAffiliates(form){
  return requestEndpoint(
    root,
    endpoints.sendAffiliates,
    form
  );
}
export function sendCorporate(form){
  return requestEndpoint(
    root,
    endpoints.sendCorporate,
    form
  );
}
export function sendPrebook(form){
  return requestEndpoint(
    root,
    endpoints.sendPrebook,
    form
  );
}
export function sendHoneyMoon(form){
  return requestEndpoint(
    root,
    endpoints.sendHoneyMoon,
    form
  );
}
export function sendCustomTrip(form){
  return requestEndpoint(
    root,
    endpoints.sendCustomTrip,
    form
  );
}
export function subscribeNewsletter({email}){
  return requestEndpoint(
    root,
    endpoints.subscribeNewsletter,
    {email}
  );
}


