import {createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import * as PIXI from 'pixi.js';

const appContext = createContext(null);
export function PixiAppProvider({
  children,
  app
}){
  return <appContext.Provider value={app}>
    {children}
  </appContext.Provider>
};
export function usePixiApp(){
  return useContext(appContext);
}

const containerContext = createContext(null);
function ContainerProvider({container,children}){
  return <containerContext.Provider value={container}>
    {children}
  </containerContext.Provider>
}
function useParentContainer(){
  return useContext(containerContext);
}

function setRef(ref,value){
  if('current' in ref)
    ref.current = value;
  else if(typeof ref === 'function')
    ref(value);
  else throw new Error('Invalid ref object');
}

export const PixiApp = forwardRef(({
  options:{
    width,
    height,
    ...options
  },
  wrapper: Wrapper='div',
  appRef: externalAppRef,
  children,
  loop,
  ...props
},externalCanvasRef)=>{

  const app = useMemo(()=>{
    const app = new PIXI.Application({
      autoStart: false,
    });
    app._render = app.render;
    app.render = (function(){
      if(this.renderer)
        this._render();
    }).bind(app);
    return app;
  },[]);
  
  const wrapperRef = useRef();
  const canvasRef = useRef();

  useEffect(()=>{
    return ()=>{
      app.destroy(true);
    }
  },[app]);

  useEffect(()=>{
    if(app && wrapperRef.current && !wrapperRef.current.firstElementChild)
      setRef(canvasRef,wrapperRef.current.appendChild(app.view));
  });

  useEffect(()=>{
    if(!loop)
      return;

    PIXI.Ticker.shared.add(loop);
    return ()=>{
      PIXI.Ticker.shared.remove(loop);
    }
  },[loop]);

  useEffect(()=>{
    if(!externalAppRef)
      return;
    setRef(externalAppRef,app);
  },[externalAppRef,app]);

  useEffect(()=>{
    if(!externalCanvasRef)
      return;
    setRef(externalCanvasRef,canvasRef.current);
  },[externalCanvasRef,canvasRef]);

  useEffect(()=>{
    for(const key in options)
      app[key] = options[key];
    app.render();
  },[options,app]);

  useEffect(()=>{
    app.renderer.resize(width,height);
    setTimeout(()=>{
      app.render();
    },1);
  },[app,width,height]);

  return <>
    <PixiAppProvider app={app}>
      <ContainerProvider container={app.stage}>
        {children}
      </ContainerProvider>
    </PixiAppProvider>
    <Wrapper ref={wrapperRef} {...props}/>
  </>;
});

function PixiElement({
  element,
  on={},
  ...props
}){
  const app = usePixiApp();

  useEffect(()=>{
    if(!element)
      return;
    for(const key in props)
      element[key] = props[key];
    app.render();
  },[element,props,app]);

  useEffect(()=>{
    for(const eventName in on)
      element.on(eventName,on[eventName]);
    return ()=>{
      for(const eventName in on)
        element.on(eventName,on[eventName]);
    }
  },[on,element]);

  return null;
}

export function PixiStage(props){
  const app = usePixiApp();
  return <PixiDisplayObject element={app.stage} {...props}/>;
}

function PixiDisplayObject({element,children,...props}){
  return <>
    <PixiElement element={element} {...props}/>
    <ContainerProvider container={element}>
      {children}
    </ContainerProvider>
  </>;
}

export function PixiContainer(props){
  return <PixiComponent
    renderComponent={PixiDisplayObject}
    {...props}
  />
}

function PixiComponent({
  constructor,
  constructorArguments,
  elementRef,
  renderComponent: RenderComponent=PixiElement,
  ...props
}){
  const element = useMemo(()=>(
    constructor(constructorArguments)
  ),[constructor,constructorArguments]);

  const parentContainer = useParentContainer();

  useEffect(()=>{
    parentContainer.addChild(element);
    return ()=>{
      if(element.parent)
        element.parent.removeChild(element);
    }
  },[element,parentContainer]);

  useEffect(()=>{
    if(elementRef && parentContainer)
      setRef(elementRef,element);
  },[element,elementRef,parentContainer]);

  return <RenderComponent element={element} {...props}/>
}

export function PixiSprite({
  texture,
  from,
  ...props
}){

  const regularConstructor = useCallback(texture => (
    new PIXI.Sprite(texture)
  ),[]);

  const fromConstructor = useCallback(from => (
    PIXI.Sprite.from(from)
  ),[]);

  return <PixiComponent
    constructor={from ? fromConstructor : regularConstructor}
    constructorArguments={from ? from : texture}
    {...props}
  />;
}
