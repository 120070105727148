import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import mediaQueries from 'css-toolkit/media-queries';
import React, { useRef } from 'react';
import styled from 'styled-components';
import useMediaQuery from 'use-media-query';
import useAnimationFrame from 'use-animation-frame';

const Root = styled.div`
  position: relative;
  background: ${colors.background2};
  padding: 151px 0 187px;
  margin-top: 25px;

  & > *{
    position: relative;
    z-index: 1;
  }

  @media portrait{
    background: ${colors.background};
    padding: 0;
    margin: 0;
  }
`;
const Title = styled.h1`
  font-family: ${secondaryFontFamily};
  font-weight: 400;
  font-size: 90px;
  letter-spacing: -0.035em;
  text-align: center;
  color: ${colors.primary};

  @media portrait{
    height: 242px;
    
    font-size: 45px;
    letter-spacing: -0.025em;
    color: #fff;

    background: 
      linear-gradient(#0004,#0004), 
      url(${p => p.$src}) center / cover no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Layout = styled.div`
  margin-top: 143px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media portrait{
    display: block;
    margin: 0;
  }
`;
const Picture = styled.div`
  background: url(${p => p.$src}) center / cover no-repeat;
  min-height: 533px;
  @media portrait{
    display: none;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p{
    font-size: 20px;
    line-height: 1.35;
    color: ${colors.foreground};
    max-width: ${608/20}em;
  }

  @media portrait{
    padding: 75px 16px;
  }
`;
const Decoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0.7vw;

  z-index: 0;

  pointer-events: none;

  @media portrait{
    display: none;
  }
  
  & > div{
    position: relative;
    border: 4px solid ${colors.background};
    border-radius: 50%;
    width: 40em;
    height: 40em;
    top: calc(200px - 20em);
    left: calc(50% - 20em);
    // js animated: width, height, top
  }
`;

export default function Section1({
  children,
  title,
  src,
  ...props
}){

  const decorationRef = useRef();

  const portrait = useMediaQuery(mediaQueries.portrait);

  useAnimationFrame(()=>{
    if(!decorationRef.current)
      return;
    if(portrait)
      return;

    const scrollTop = document.scrollingElement.scrollTop;
    const offsetTop = decorationRef.current.parentElement.offsetTop;

    // const ease = bezier(0.91,0.31,.77,.3);
    const ease = x => x;

    // const progressOffset = portrait ? 0 : 0;

    const rawProgress  = (
      Math.max(0,Math.min(1,(scrollTop + window.innerHeight - offsetTop)/(window.innerHeight*2.5)))
    );
    const progress = ease(rawProgress);

    const minSize = portrait ? 0.6 : 0.26;
    const maxSize = portrait ? 1.55 : 3;
    const size = minSize + progress*(maxSize-minSize);

    Object.assign(decorationRef.current.style,{
      fontSize: `${size}vw`,
    });

  },[decorationRef,portrait]);

  return (
    <Root {...props}>
      <Decoration ref={decorationRef}><div/></Decoration>
      <Title $src={src}>{title}</Title>
      <Layout>
        <Picture $src={src} />
        <Text>
          <p>{children}</p>
        </Text>
      </Layout>
    </Root>
  );
}
