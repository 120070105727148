import colors from 'colors';
import React from 'react';
import styled from 'styled-components';
import LegalText from 'ui-components/LegalText';
import TopBar from 'ui-components/TopBar';

const Root = styled.div`
  @media landscape{
    padding-top: 25px;
  }
`;
const Content = styled.div`
  margin-top: 25px;
  background: ${colors.background2};

  padding: 145px 340px 174px;

  @media portrait{
    padding: 75px 16px;
    margin: 0;
  }
`;

export default function Cookies({...props}){
  
  return (
    <Root {...props}>
      <TopBar/>
      <Content>
        <LegalText>
          <h1>Política de Cookies</h1>
          <strong>O que são cookies?</strong><br/>
          <br/>
          "Cookies" são pequenas etiquetas de software que são armazenadas no seu computador através do navegador (browser), retendo apenas informação relacionada com as suas preferências, não incluindo, como tal, os seus dados pessoais.<br/>
          <br/>
          <br/>
          <strong>Para que servem os Cookies?</strong><br/>
          <br/>
          Os cookies servem para ajudar a determinar a utilidade, interesse e o número de utilizações dos seus websites, permitindo uma navegação mais rápida e eficiente, eliminando a necessidade de introduzir repetidamente as mesmas informações.<br/>
          <br/>
          <br/>
          <strong>Que tipo de cookies existem?</strong><br/>
          <br/>
          Existem dois grupos cookies que podem ser utilizados:<br/>
          <br/>
          • Cookies permanentes - são cookies que ficam armazenados ao nível do browser nos seus equipamentos de acesso (PC, mobile e tablet) e que são utilizados sempre que faz uma nova visita a um dos nossos websites. São utilizados, geralmente, para direcionar a navegação aos interesses do utilizador, permitindo-nos prestar um serviço mais personalizado.<br/>
          <br/>
          • Cookies de sessão - são cookies temporários que permanecem no arquivo de cookies do seu browser até sair do website. A informação obtida por estes cookies serve para analisar padrões de tráfego na web, permitindo-nos identificar problemas e fornecer uma melhor experiência de navegação.<br/>
          <br/>
          <br/>
          <strong>Para que fins utilizamos cookies?</strong><br/>
          <br/>
          • Cookies estritamente necessários - Permitem que navegue no website e utilize as suas aplicações, bem como aceder a áreas seguras do website. Sem estes cookies, os serviços que tenha requerido não podem ser prestados.<br/>
          <br/>
          • Cookies analíticos - São utilizados anonimamente para efeitos de criação e análise de estatísticas, no sentido de melhorar o funcionamento do website.<br/>
          <br/>
          • Cookies de funcionalidade - Guardam as preferências do utilizador relativamente à utilização do site, para que não seja necessário voltar a configurar o site cada vez que o visita.<br/>
          <br/>
          • Cookies de terceiros - Medem o sucesso de aplicações e a eficácia da publicidade de terceiros. Podem também ser utilizados no sentido de personalizar um widget com dados do utilizador.<br/>
          <br/>
          • Cookies de publicidade - Direcionam a publicidade em função dos interesses de cada utilizador, por forma a direcionar as campanhas publicitárias tendo em conta os gostos dos utilizadores, sendo que, além disso, limitam a quantidade de vezes que vê o anúncio, ajudando a medir a eficácia da publicidade e o sucesso da organização do website.<br/>
          <br/>
          <br/>
          <strong>Como pode gerir os cookies?</strong><br/>
          <br/>
          Todos os browsers permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da seleção das definições apropriadas no respetivo navegador. Pode configurar os cookies no menu "opções" ou "preferências" do seu browser.<br/>
          <br/>
          Note-se, no entanto, que, ao desativar cookies, pode impedir que alguns serviços da web funcionem corretamente, afetando, parcial ou totalmente, a navegação no website.<br/>
          A Agência atualizará a sua Política de Privacidade e Cookies. Pelo que, lhe solicitamos-lhe que reveja periodicamente este documento para se manter atualizada.<br/>
          <br/>
        </LegalText>
      </Content>
    </Root>
  );
}
