import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import useAnimationFrame from 'use-animation-frame';
import logo from 'assets/logo.svg';
import logoAlt from 'assets/logo-alt.svg';
import burger from './assets/burger.svg';
import burgerHover from './assets/burger-hover.svg';
import burgerMobile from './assets/burger-mobile.svg';
import burgerMobileHover from './assets/burger-mobile-hover.svg';
import { Link } from 'react-router-dom';
import colors from 'colors';
import { useSetMenuOpen } from 'App';
import preloadImages from 'preload-images';

preloadImages([
  logo,
  logoAlt,
  burger,
  burgerHover,
  burgerMobile,
  burgerMobileHover,
]);

const Root = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  
  height: 77pxva;

  & > div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: background-color 300ms, height 300ms;
  }

  transition: background-color 300ms;
  &[data-opaque=true]{
    background: transparent;
    & > div{
      
      @media portrait{
        height: 56px;
      }
      background: ${colors.primary};
    }
  }
  
  @media portrait{
    height: 56px;
    &&&&[data-panel-open=true]{
      position: fixed;
      & > div{
        background: ${p => p.$panelColor};
      }
    }
  }
`;

const Logo = styled(Link).attrs({
  to: '/',
  'aria-label': 'Home page',
})`
  display: block;
  width: 140pxva;
  height: 48pxva;
  background: url(${logoAlt}) center / contain no-repeat;

  position: relative;
  top: 10pxva;

  @media portrait{
    width: 108px;
    height: 36px;
    top: 7px;
  }

  [data-opaque=true]  &,
  [data-inverse=true] &{
    background-image: url(${logo});
  }
  @media landscape{
    [data-inverse-desktop=true] &{
      background-image: url(${logo});
    }
  }
`;
const Burger = styled.div`

  width: 40pxva;
  height: 40pxva;
  background: url(${burger}) center / contain no-repeat;
  
  position: absolute;
  top: 18pxva;
  right: 70px;
  
  @media portrait{
    width: 30px;
    height: 30px;
    background: url(${burgerMobile}) center / contain no-repeat;
    top: 13px;
    right: 26px;
    -webkit-tap-highlight-color: transparent;
  }

  cursor: pointer;

  transition: background-image 300ms;
  &:hover{
    background-image: url(${burgerHover});
    @media portrait{
      background-image: url(${burgerMobileHover});
    }
  }
`;

const TopBar = React.forwardRef(function TopBar({
  inverse=false,
  inverseDesktop=false,
  panelOpen=false,
  panelColor=colors.primary,
  ...props
},ref){

  const setMenuOpen = useSetMenuOpen();
  
  const rootRef = useRef();

  useAnimationFrame(()=>{
    if(!rootRef.current)
      return;
  
    const scrollTop = document.scrollingElement.scrollTop;
    
    const opaque = panelOpen || (
      scrollTop > 0
      && rootRef.current.offsetTop - scrollTop < 3
    );

    rootRef.current.dataset.opaque = opaque;
  },[rootRef,panelOpen]);

  useLayoutEffect(()=>{
    if(!ref)
      return;
    if(typeof ref === 'function')
      ref(rootRef.current);
    else ref.current = rootRef.current;
  },[ref,rootRef]);

  useLayoutEffect(()=>{
    window.scrollTo(0,0);
    setTimeout(()=>{
      window.scrollTo(0,0);
    },1);
  },[]);


  return <>
    <Root
      data-panel-open={panelOpen}
      $panelColor={panelColor}
      data-inverse={inverse}
      data-inverse-desktop={inverseDesktop}
      ref={rootRef}
      {...props}
    >
      <div>
        <Logo/>
        <Burger onClick={()=>{setMenuOpen(true)}}/>
      </div>
    </Root>
  </>;
});

export default TopBar;

