import { subscribeNewsletter } from 'api';
import colors from 'colors';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from './Button';
import GenericForm from './GenericForm';
import InputText from './InputText';
import gea from './assets/gea.svg';
import facebook from './assets/facebook.svg';
import instagram from './assets/instagram.svg';
// import linkedin from './assets/linkedin.svg';

const Root = styled.div`
  padding: 108px 60px 80px;
  @media portrait{
    padding: 80px 28px 60px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 74px;

  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 20px;
    & [data-hide-mobile]{
      display: none;
    }
  }
  @media tablet{
    grid-template-columns: repeat(2,1fr);
    & [data-hide-mobile]{
      display: unset;
    }
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.35;

  color: ${colors.primary};

  margin-bottom: 54px;
  @media portrait{
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;
const Text = styled.div`
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.foreground};
  
  a:hover > &{
    color: ${colors.secondary};
  }
`;
const Links = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 2.19;

  color: ${colors.secondary};
  & a{
    &:hover{
      color: ${colors.secondaryHover};
    }
  }
`;
const NewsletterForm = styled(GenericForm)`
  display: grid;
  grid-template-columns: 1fr 104px;
  column-gap: 25px;
  margin-bottom: 25px;
`;
const Error = styled.div`
  margin-bottom: 25px;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  text-transform: uppercase;
  color: ${colors.secondary};
`;
const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.background};

  color: ${colors.foreground};
  font-size: 14px;
  line-height: 1.57;
  text-transform: uppercase;
  font-weight: 600;

  text-align: center;
`;
const Gea = styled(Text)`
  height: 73px;
  line-height: 73px;
  margin-top: 1em;

  padding-left: ${71+15}px;
  background: url(${gea}) top left / auto 100% no-repeat;
`;
const BottomRow = styled.div`
  margin-top: 60px;

  display: grid;
  grid-template-columns: 1fr auto;
  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 60px;
  }

  & > div:first-child{
    max-width: 700px;
  }
  & > div:last-child{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 22px;
    @media portrait{
      /* padding: 0 75px; */
      /* justify-content: space-between; */
      justify-content: flex-start;
    }
  }

  
`;
const LegalText = styled(Text)`
  display: inline-block;
  line-height: 1.93;
  white-space: pre;
  &[data-mobile-only]{
    display: none;
    @media mobile{
      display: unset;
    }
  }
  @media mobile{
    display: block;
    line-height: 1.5;
  }
`;
const LegalSeparator = styled.span`
  &:after{
    content: '|';
    margin: 0 0.5em;
  }
  @media mobile{
    &:after{
      display: none;
    }
  }
`;
const SocialIcon = styled.img`
  display: block;
  height: 23px;
  width: auto;
`;

export default function Footer({...props}){

  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  
  return (
    <Root {...props}>
      <Grid>
        <div>
          <Title>De: Lousada</Title>
          <Text>
            Praça das Pocinhas nº 9<br/>
            4620-657 Lousada<br/>
            Portugal<br/>
            <br/>
            geral@viagenslousada.pt<br/>
            +351 255 814 009<br/>
            <br/>
            Nº de Registo : 2064<br/>
          </Text>
          <Gea>
            Grupo GEA
          </Gea>
        </div>
        <div>
          <Title>Clean&Safe</Title>
          <Text>
            Esta empresa cumpre os requisitos de higiene e limpeza para prevenção e controlo do vírus COVID-19, de acordo com as recomendações da Direção Geral da Saúde. Mais informações em www.visitportugal.com. 
          </Text>
        </div>
        <div data-hide-mobile>
          <Title>Sobre</Title>
          <Links>
            <Link to="/sobre-nos">Sobre nós</Link><br/>
            <Link to="/contactos">Contactos</Link><br/>
            <Link to="/corporate">Corporate</Link><br/>
            <Link to="/afiliados">Afiliados</Link><br/>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://reservas.viagenslousada.pt"
            >Outras reservas</a><br/>
          </Links>
        </div>
        <div>
          <Title>Newsletter</Title>

          <div style={{position:'relative'}}>
          
            <NewsletterForm setError={setError} setSent={setSent} setSending={setSending} endpoint={subscribeNewsletter}>
              <div>
                <InputText name="email" type="email" required={true} single={true} variant="light" placeholder="E-mail"/>
              </div>
              <Button disabled={sending}>Enviar</Button>
            </NewsletterForm>
            {error ? (
              <Error>
                Falha no envio, tente novamente mais tarde.
              </Error>
            ):null}
            <Text>
              Ao clicar no botão, concordo que os meus dados sejam tratados de acordo com a Política de Privacidade.<br/>
              <br/>
              Este site é protegido por reCAPTCHA e aplicam-se a Política de Privacidade e os Termos de Serviço da Google.<br/>
            </Text>
            {sent ? <Success>
              Enviado com sucesso.
            </Success> : null}
          </div>
        </div>
      </Grid>
      <BottomRow>
        <div>
          <Link to="/privacidade">
            <LegalText>
              Política de privacidade
            </LegalText>
          </Link>
          <LegalSeparator/>
          <Link to="/cookies">
            <LegalText>
              Política de cookies
            </LegalText>
          </Link>
          <LegalSeparator/>
          <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/">
            <LegalText>
              Livro de reclamações
            </LegalText>
          </a>
          <LegalText data-mobile-only>&nbsp;</LegalText>
          <LegalText>Copyright © 2021 De Lousada. </LegalText>
          <LegalText>Todos os direitos reservados. </LegalText>
          {/* <LegalText>Desenvolvido por Webkorp.</LegalText> */}
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/agenciadeviagenseturismodelousada"
          >
            <SocialIcon
              src={facebook}
              alt="Facebook"
            />
          </a>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://linkedin.com"
          >
            <SocialIcon
              src={linkedin}
              alt="LinkedIn"
            />
          </a> */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/viagenslousada/"
          >
            <SocialIcon
              src={instagram}
              alt="Instagram"
            />
          </a>
        </div>
      </BottomRow>
    </Root>
  );
}
