const europa = [
  {
    id: 'paris',
    category: 'europa',
    pictures: [
      require('temp-data/places/Paris/AVL0081.jpg').default,
      require('temp-data/places/Paris/AVL0082.jpg').default,
      require('temp-data/places/Paris/AVL0083.jpg').default,
      require('temp-data/places/Paris/AVL0084.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Hotel Carladez Cambronne/PT021-A.jpg').default,
      require('temp-data/hotels/Hotel Carladez Cambronne/PT021-B.jpg').default,
      require('temp-data/hotels/Hotel Carladez Cambronne/PT021-C.jpg').default,
      require('temp-data/hotels/Hotel Carladez Cambronne/PT021-D.jpg').default,
    ],
    title: 'Paris',
    info: [
      'Voo + 3 Noites',
      'Hôtel Carladez Cambronne',
    ],
    price: '395,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 3,
    about: 'Paris é a capital e a mais populosa cidade da França. Desde o século XVII, Paris é um dos principais centros de finanças, diplomacia, comércio, moda, ciência e artes da Europa.\n\nVisitar a Torre Eiffel, o Arco do Triunfo ou a Catedral de Notre Dame, assistir a um espetáculo de cabaré no clássico Moulin Rouge, ou passear por alguns dos bairros mais charmosos da cidade, como Montmartre ou Montparnasse, são algumas das experiências que todos deveriam ter a oportunidade de viver.',
    hotel:[
      ['Datas','A partir de Setembro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','A definir'],
      ['Aeroporto','Porto - Paris Orly'],
      ['Data de regresso','A definir'],
      ['Aeroporto','Paris Orly - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'londres',
    category: 'europa',
    pictures: [
      require('temp-data/places/Londres/AVL0085.jpg').default,
      require('temp-data/places/Londres/AVL0086.jpg').default,
      require('temp-data/places/Londres/AVL0087.jpg').default,
      require('temp-data/places/Londres/AVL0088.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Qbic Hotel London City/PT022-A.jpg').default,
      require('temp-data/hotels/Qbic Hotel London City/PT022-B.jpg').default,
      require('temp-data/hotels/Qbic Hotel London City/PT022-C.jpg').default,
      require('temp-data/hotels/Qbic Hotel London City/PT022-D.jpg').default,
    ],
    title: 'Londres',
    info: [
      'Voo + 3 Noites',
      'Qbic Hotel London City',
    ],
    price: '390,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 3,
    about: 'Londres tem algo que conquista e encanta a todos. Londres é diversão, cultura, luzes e cor. Uma cidade capaz de surpreender tanto aqueles que a visitam pela primeira vez, como os apaixonados por ela e que não podem deixar de visitá-la com frequência.\n\nEm Londres você poderá se sentir pequeno ao lado do impressionante Big Ben, contemplar o mundo aos  pés do London Eye ou assistir à famosa Troca da Guarda em frente ao Palácio de Buckingham.',
    hotel:[
      ['Datas','A partir de Outubro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','A definir'],
      ['Aeroporto','Porto - Londres Gatwick'],
      ['Data de regresso','A definir'],
      ['Aeroporto','Londres Gatwick - Porto'],
    ],
    notesFlight: 'Programas inclui Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'amesterdao',
    category: 'europa',
    pictures: [
      require('temp-data/places/Amesterdao/AVL0093.jpg').default,
      require('temp-data/places/Amesterdao/AVL0094.jpg').default,
      require('temp-data/places/Amesterdao/AVL0095.jpg').default,
      require('temp-data/places/Amesterdao/AVL0096.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Mercure Hotel Amsterdam West/PT024-A.jpg').default,
      require('temp-data/hotels/Mercure Hotel Amsterdam West/PT024-B.jpg').default,
      require('temp-data/hotels/Mercure Hotel Amsterdam West/PT024-C.jpg').default,
      require('temp-data/hotels/Mercure Hotel Amsterdam West/PT024-D.jpg').default,
    ],
    title: 'Amesterdão',
    info: [
      'Voo + 3 Noites',
      'Mercure Hotel Amsterdam West',
    ],
    price: '380,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Amsterdam é uma das cidades mais animadas da Europa, tem um turismo muito jovem e isso pode ser percebido à noite, com uma vida noturna intensa e longa.\n\nCulturalmente, Amsterdam não fica para trás e a cidade está na vanguarda da Europa no que se refere a espetáculos, arte e museus. Entre os principais museus de Amsterdam estão grandes expoentes, como o Rijksmuseum, o Museu Van Gogh ou a Casa de Anne Frank.',
    hotel:[
      ['Datas','A partir de Setembro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','A definir'],
      ['Aeroporto','Porto - Amesterdão'],
      ['Data de regresso','A definir'],
      ['Aeroporto','Amesterdão - Porto'],
    ],
    notesFlight: 'Programas inclui com Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
  {
    id: 'copenhaga',
    category: 'europa',
    pictures: [
      require('temp-data/places/Copenhaga/AVL0097.jpg').default,
      require('temp-data/places/Copenhaga/AVL0098.jpg').default,
      require('temp-data/places/Copenhaga/AVL0099.jpg').default,
      require('temp-data/places/Copenhaga/AVL00100.jpg').default,
    ],
    hotelPictures: [
      require('temp-data/hotels/Scandic Palace Hotel/PT027-A.jpg').default,
      require('temp-data/hotels/Scandic Palace Hotel/PT027-B.jpg').default,
      require('temp-data/hotels/Scandic Palace Hotel/PT027-C.jpg').default,
      require('temp-data/hotels/Scandic Palace Hotel/PT027-D.jpg').default,
    ],
    title: 'Copenhaga',
    info: [
      '4 Noites',
      'Scandic Palace Hotel',
    ],
    price: '500,00 €',
    details: [
      'APA',
      'Por pessoa.',
    ],
    stars: 4,
    about: 'Copenhaga que significa "porto do mercador" é a capital e maior cidade da Dinamarca.\n\nUm destino de viagem apetecível em todos os sentidos. Monumentos históricos, espaços verdes invejáveis, palácios em jardins exuberantes, canais e lagos espalhados um pouco por toda a cidade são algumas das atrações turísticas que o vão cativar. A capital dinamarquesa orgulha-se ainda da sua modernidade.',
    hotel:[
      ['Datas','A partir de Outubro de 2021'],
      ['Regime','APA (Alojamento e pequeno-almoço)'],
      ['Quarto','Standard (Capacidade: 2 Adultos)'],
      ['Crianças','Não incluído'],
    ],
    notesHotel: '',
    flight:[
      ['Data de partida','A definir'],
      ['Aeroporto','Porto - Copenhaga'],
      ['Data de regresso','A definir'],
      ['Aeroporto','Copenhaga - Porto'],
    ],
    notesFlight: 'Programas inclui com Voo + Hotel + Seguro.\nTransferes aeroporto-hotel-aeroporto, possível mediante custo extra.\nPreços sujeitos a alterações, mediante disponibilidade.',
  },
];
export default europa;
