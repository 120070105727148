import bezier from 'bezier-easing';
import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import mediaQueries from 'css-toolkit/media-queries';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DestinationThumb from 'ui-components/DestinationThumb';
import VerMais from 'ui-components/VerMais';
import useAnimationFrame from 'use-animation-frame';
import useMediaQuery from 'use-media-query';
import portugal from 'temp-data/portugal';
import espanha from 'temp-data/espanha';
import europa from 'temp-data/europa';
import colorOpacity from 'color-opacity';
import plus from 'ui-components/assets/plus.svg';
import minus from 'ui-components/assets/minus.svg';
import MiniDestinationThumb from 'ui-components/MiniDestinationThumb';

const Root = styled.div`
  position: relative;
  margin-top: -127pxva;
  padding: calc(127pxva + 148px) 0 190px;
  @media portrait{
    margin: 0;
    padding: 0;
    margin-top: -132px;
    padding: 200px 0 0;
  }
`;
const Decoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 80pxva;
  z-index: -1;
  overflow: hidden;
  font-size: 1vw;

  @media portrait{
    padding-top: ${77 + 56/2 - 4}px;
  }
  

  & > div{
    position: relative;
    border: 1px solid ${colors.secondary};
    border-radius: 50%;

    @media portrait{
      /* font-size: 3vw; */
    }

    // js animated: width, height, top
  }
`;
const TextBox = styled.div`
  margin-left: 180px;

  width: 611px;
  
  & > h1{
    font-family: ${secondaryFontFamily};
    color: ${colors.primary};
    font-weight: 500;
    font-size: 80px;
    line-height: 0.85;
    letter-spacing: -0.035em;

    &:nth-child(2){
      display: none;
    }
  }
  & > p{
    font-size: 20px;
    line-height: 1.35;
    color: ${colors.foreground};

    margin-top: 40px;
  }

  @media portrait{
    padding: 0 24px 60px;
    margin: 0;
    width: 100%;
    & > h1{
      font-size: 40px; // Gilroy: 45px;
      line-height: 0.91;
      &:nth-child(1){
        display: none;
      }
      &:nth-child(2){
        display: block;
      }
      letter-spacing: -0.025em;
      & span{
        font: inherit;
        letter-spacing: -0.035em;
      }
    }
    & > p{
      /* padding-left: ${114-24}px; */
      font-size: 17.5px; // Gilroy: 20px
      margin-top: 40px;
      & span{
        display: none;
      }
    }
  }
`;
const Selector = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
  justify-content: flex-end;
  margin-right: 70px;

  position: relative;
  margin-top: -15px;

  padding-bottom: 55px;

  & > div{
    font-family: ${secondaryFontFamily};
    font-weight: 500;
    font-size: 50px;
    letter-spacing: -0.025em;

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${colors.secondary};
    color: ${colors.background};
    transition: color 300ms;

    cursor: pointer;

    position: relative;
    &::after{
      content: ' ';
      display: block;
      position: absolute;
      top: 55%;
      left: -5%;
      width: 0%;
      height: 3px;
      background: ${colors.secondary};

      transition: width 400ms;
    }
    transition: color 300ms, text-shadow 300ms;
    &[data-selected=true],&:hover{
      text-shadow: unset;
      color: ${colors.secondary};
    }
    &[data-selected=true]{
      &::after{
        width: 110%;
      }
    }
  }
`;
const GridFade = styled.div`
  position: relative;

  height: 345px;
`;
const Grid = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: opacity 400ms;
  opacity: 1;
  &[data-selected=false]{
    opacity: 0;
    pointer-events: none;
  }

  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 24px;

  &:hover > *{
    opacity: 0.6;
  }
  & > *{
    transition: opacity 500ms;
    opacity: 1;
    &:hover{
      opacity: 1;
    }
  }
`;
const Desktop = styled.div`
  @media portrait{
    display: none;
  }
`;
const LinkVerMais = styled(VerMais)`
  margin-right: 26px;
  @media portrait{
    display: none;
  }
`;
const Tab = styled.div`
  border-top: 1px solid ${colorOpacity(colors.foreground,0.3)};

  height: 58px;
  overflow-y: hidden;
  transition: height 500ms ease-in-out;
  &[data-open=true]{
    height: 318px
  }

  @media landscape{
    display: none;
  }
`;
const TabState = ({
  defaultOpened = false,
  ...props
})=>{
  const [open, setOpen] = useState(defaultOpened);
  const handleClick = useCallback(()=>{
    setOpen(x => !x);
  },[setOpen]);
  const tabRef = useRef();
  useEffect(()=>{
    const tabTitle = tabRef.current.firstElementChild;
    tabTitle.addEventListener('click',handleClick);
    return ()=>{
      tabTitle.removeEventListener('click',handleClick);
    };
  },[tabRef,handleClick]);
  return <Tab ref={tabRef} data-open={open} {...props}/>
};
const TabTitle = styled.h3`
  font-family: ${secondaryFontFamily};
  font-size: 30px;
  line-height: 58px;
  font-weight: 500;
  letter-spacing: -0.015em;
  color: ${colors.secondary};
  padding-left: 26px;

  background: url(${plus}) center right 16px / 16px no-repeat;
  transition: background-image 200ms 200ms;
  [data-open=true] > &{
    background-image: url(${minus});
  }

  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;
const Carousel = styled.div`
  height: 235px;
  overflow-y: hidden;
  & > div{
    height: 251px;
    max-width: 100vw;
    overflow-x: scroll;
    & > div{
      padding: 0 5px;
      height: 100%;
      width: min-content;
      white-space: nowrap;
    }
  }
`;

export default function Section1({...props}){
  
  const decorationRef = useRef();

  const portrait = useMediaQuery(mediaQueries.portrait);

  useAnimationFrame(()=>{
    if(!decorationRef.current)
      return;

    const scrollTop = document.scrollingElement.scrollTop;
    const offsetTop = decorationRef.current.parentElement.parentElement.offsetTop;

    // const ease = bezier(0.91,0.31,.77,.3);
    const ease = bezier(1,.12,1,-0.04)

    const progressOffset = portrait ? 0.11 : 0.055;

    const rawProgress  = (
      1 - Math.max(0,Math.min(1,(offsetTop - scrollTop)/window.innerHeight+progressOffset))
    );
    const progress = ease(rawProgress);

    const minSize = portrait ? 35 : 15;
    const maxSize = portrait ? 155 : 100;
    const size = minSize + progress*(maxSize-minSize);

    const opacityProgress = Math.max(0,Math.min(1,(rawProgress-0.95)/0.05));
    const opacity = (1-opacityProgress);

    Object.assign(decorationRef.current.style,{
      width: `${size}em`,
      height: `${size}em`,
      top: `-${size/2}em`,
      left: `calc(50% - ${size/2}em)`,
      opacity: opacity,
    });

  },[decorationRef,portrait]);

  const [gridOpacity, setGridOpacity] = useState(undefined);

  const [destination, setDestination] = useState('Portugal');

  const handleChangeDestination = useCallback(e=>{
    const destination = e.currentTarget.innerText;
    setDestination(destination);
    setGridOpacity(1);
  },[setDestination,setGridOpacity]);

  const thumbStyle = {style:{opacity:gridOpacity}};

  return (
    <Root {...props}>
      <Decoration><div ref={decorationRef}/></Decoration>
      <TextBox>
        <h1>Mundos<br/> que chamam por nós</h1>
        <h1>Mundos que<br/><span> chamam </span>por nós</h1>
        <p>
          Há um mundo que continua. Há um mundo que anseia nos receber, nos acolher e envolver em novas histórias. Há um mundo em cada um de nós à espera de se conectar a novos lugares e pessoas.
          <span> Reunimos para si as melhores condições para descobrir qualquer lugar no mundo.<br/></span> Conheça as nossas propostas.<br/>
        </p>
      </TextBox>
      <Desktop>
        <Selector onMouseLeave={()=>setGridOpacity(undefined)}>
          <div
            data-selected={destination==='Portugal'}
            onClick={handleChangeDestination}
          >
            Portugal
          </div>
          <div
            data-selected={destination==='Espanha'}
            onClick={handleChangeDestination}
          >
            Espanha
          </div>
          <div
            data-selected={destination==='Europa'}
            onClick={handleChangeDestination}
          >
            Europa
          </div>
          {/* <div
            data-selected={destination==='Mundo'}
            onClick={handleChangeDestination}
          >
            Mundo
          </div> */}
        </Selector>
        <GridFade>
          <Grid data-selected={destination==='Portugal'}>
            <DestinationThumb {...thumbStyle} destination={portugal[0]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[1]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[2]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[3]}/>
          </Grid>
          <Grid data-selected={destination==='Espanha'}>
            <DestinationThumb {...thumbStyle} destination={espanha[0]}/>
            <DestinationThumb {...thumbStyle} destination={espanha[1]}/>
            <DestinationThumb {...thumbStyle} destination={espanha[2]}/>
            <DestinationThumb {...thumbStyle} destination={espanha[3]}/>
          </Grid>
          <Grid data-selected={destination==='Europa'}>
            <DestinationThumb {...thumbStyle} destination={europa[0]}/>
            <DestinationThumb {...thumbStyle} destination={europa[1]}/>
            <DestinationThumb {...thumbStyle} destination={europa[2]}/>
            <DestinationThumb {...thumbStyle} destination={europa[3]}/>
          </Grid>
          {/* <Grid data-selected={destination==='Mundo'}>
            <DestinationThumb {...thumbStyle} destination={portugal[0]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[1]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[2]}/>
            <DestinationThumb {...thumbStyle} destination={portugal[3]}/>
          </Grid> */}
        </GridFade>
        <LinkVerMais to={`/destinos/${destination.toLocaleLowerCase()}`}/>
      </Desktop>
      <TabState defaultOpened={true}>
        <TabTitle>Portugal</TabTitle>
        <Carousel>
          <div>
            <div>
              <MiniDestinationThumb destination={portugal[0]}/>
              <MiniDestinationThumb destination={portugal[1]}/>
              <MiniDestinationThumb destination={portugal[2]}/>
              <MiniDestinationThumb destination={portugal[3]}/>
            </div>
          </div>
        </Carousel>
      </TabState>
      <TabState>
        <TabTitle>Espanha</TabTitle>
        <Carousel>
          <div>
            <div>
              <MiniDestinationThumb destination={espanha[0]}/>
              <MiniDestinationThumb destination={espanha[1]}/>
              <MiniDestinationThumb destination={espanha[2]}/>
              <MiniDestinationThumb destination={espanha[3]}/>
            </div>
          </div>
        </Carousel>
      </TabState>
      <TabState>
        <TabTitle>Europa</TabTitle>
        <Carousel>
          <div>
            <div>
              <MiniDestinationThumb destination={europa[0]}/>
              <MiniDestinationThumb destination={europa[1]}/>
              <MiniDestinationThumb destination={europa[2]}/>
              <MiniDestinationThumb destination={europa[3]}/>
            </div>
          </div>
        </Carousel>
      </TabState>
    </Root>
  );
}
