import colorOpacity from 'color-opacity';
import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import GenericForm from './GenericForm';
import InputNumber from './InputNumber';
import InputRadio from './InputRadio';
import InputText from './InputText';
import close from 'ui-components/assets/close.svg';
import { useLockBodyScroll } from 'lock-body-scroll';
import { sendCustomTrip } from 'api';
import arrowLeft from 'ui-components/assets/arrow-left.svg';

const Root = styled.div`
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  height: 100%;
  width: 100%;
  background: ${colorOpacity(colors.background,0.7)};
  /* backdrop-filter: blur(2px); */

  opacity: 1;
  transition: opacity 500ms;
  &[data-open=false]{
    opacity: 0;
    pointer-events: none;
  }

  @media portrait{
    display: none;
  }
`;
const Content = styled.div`
  width: 656px;
  height: 100%;
  padding: 155px 24px 155px;
  overflow-y: scroll;

  background: linear-gradient(180deg, #2F0283 0%, #4E04D8 100%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 21;
  transition: right 500ms ease-out;
  &[data-open=false]{
    right: -100%;
    transition: right 300ms ease-in;
  }

  @media portrait{
    width: 100%;
    padding: 20px 16px 100px;

    top: 56px;
    height: calc(100% - 56px);
  }
`;
const Title = styled.h1`
  font-family: ${secondaryFontFamily};
  font-size: 50px;
  line-height: 1;
  letter-spacing: -0.025em;
  color: ${colors.secondary};
  font-weight: 500;

  @media portrait{
    font-size: 45px;
    font-weight: 400;
    line-height: 0.91;
    text-align: center;

    margin-top: 40px;
  }
`;
const Form = styled(GenericForm)`
  margin-top: 49px;
  @media portrait{
    margin-top: 40px;
  }
`;
const InputNumberHalfWidth = styled(InputNumber)`
  @media not_mobile{
    display: inline-block;
    width: 230px;
  }
`;
const InputDateHalfWidth = styled(InputNumberHalfWidth).attrs({
  as: InputText,
  type: 'date',
})`
  @media not_mobile{
    width: 230px;
    & ~ &{
      width: calc(100% - 230px);
    }
  }
`;
const TwoLinePlaceholder = styled.div`
  position: relative;
  font: inherit;
  & small{
    font: inherit;
    font-size: 10px;
  }
  top: 4px;
`;
const SubmitButton = styled(Button)`
  width: 292px;
  @media mobile{
    width: 100%;
  }
`;
const FormFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 26px;

  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 28px;
  }

  margin-top: 30px;

  & > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const CloseButton = styled.div`
  width: 28px;
  height: 28px;
  position: absolute; 
  top: 51px;
  right: 45px;

  background: url(${close}) center / contain no-repeat;

  cursor: pointer;

  @media portrait{
    position: relative;
    top: 0;
    right: 0;
    left: 4px;
    background: url(${arrowLeft}) center / contain no-repeat;
  }
`;
const Success = styled.div`
  margin-top: 49px;
  height: 204px;
  background: ${colors.primary};

  color: ${colors.background};
  font-size: 14px;
  line-height: 1.57;
  text-transform: uppercase;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
const Error = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  text-transform: uppercase;
  color: ${colors.secondary};
`;

export default function CustomTripForm({
  open=false,
  setOpen=()=>{},
  ...props
}){

  useLockBodyScroll(open);

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  
  return <>
    <Overlay data-open={open} onClick={()=>setOpen(false)}/>
    <Root {...props}>
      <Content data-open={open}>
        <CloseButton onClick={()=>setOpen(false)}/>
        <Title>Personalizar Viagem</Title>
        {sent ? <Success>
          Mensagem enviada com sucesso.<br/>
          Em breve entramos em contato.
        </Success> : 
        <Form
          setSent={setSent}
          setSending={setSending}
          setError={setError}
          endpoint={sendCustomTrip}
        >
          <InputText name="nome" placeholder="Nome*" required={true}/>
          <InputText name="email" placeholder="Email*" required={true}/>
          <InputText name="telefone" placeholder="Telefone*" required={true}/>
          <InputText name="destino" placeholder="Qual o destino*" required={true}/>
          <InputRadio name="servicos" options={[
            {
              value: 'Hotel',
              label: 'Hotel',
            },
            {
              value: 'Voo',
              label: 'Voo',
            },
            {
              value: 'Voo + Hotel',
              label: 'Voo + Hotel',
            },
          ]}/>
          
          <InputNumberHalfWidth name="adultos" placeholder="Adultos" min={0}max={12}/>
          <InputNumberHalfWidth name="criancas" placeholder={
            <TwoLinePlaceholder>Crianças<br/><small>até 11 anos</small></TwoLinePlaceholder>
          } min={0}max={12}/>
          <InputRadio name="transferes"
            placeholder="Transferes" 
            options={[
              {
                label: 'Sim',
                value: 'Sim',
              },{
                label: 'Não',
                value: 'Não',
              },
            ]}
          />
          <InputDateHalfWidth name="partida" placeholder="Partida"/>
          <InputDateHalfWidth name="chegada" placeholder="Chegada"/>
          <InputText name="orcamento" placeholder="Qual seu orçamento?*" required={true}/>
          <InputText name="observacoes" type="textarea" placeholder="Observações"/>
          <FormFooter>
            <div>
              <SubmitButton disabled={sending}>Pedir proposta</SubmitButton>
            </div>
            <div>
              {error ? (
                <Error>
                  Falha no envio, tente novamente mais tarde.
                </Error>
              ):null}
            </div>
          </FormFooter>
        </Form>}
      </Content>
    </Root>
  </>;
}
