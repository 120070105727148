import colorOpacity from 'color-opacity';
import colors from 'colors';
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import Dropdown from './Dropdown';

const Root = styled.div`
  width: 324px;
  padding: 40px 35px 40px;

  display: grid;
  grid-template-columns: min-content 1fr min-content;
  column-gap: 18px;
`;

const Label = styled.div`
  height: 24px;
  line-height: 27px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colors.primary};
`;
const Slider = styled.div`
  height: 24px;
  position: relative;
`;
const Track = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  height: 2px;
  width: 100%;
  background: ${colorOpacity(colors.secondary,0.3)};
`;
const Knob = styled.div.attrs(({position})=>{return{
  style: {
    left: `calc(${position*100}%)`
  }
}})`
  position: absolute;
  top: 10px;
  height: 17px;
  width: 17px;
  border-radius: 17px;
  background: ${colors.secondary};
  transform-origin: 50% 50%;
  transform: translate(-7px,-7px);
  cursor: pointer;
  touch-action: none;
`;
const TrackHighlight = styled(Track).attrs(({lower,upper})=>{return{
  style: {
    left: `${lower*100}%`,
    width: `${(upper-lower)*100}%`
  }
}})`
  height: 3px;
  background: ${colors.secondary};
`;

export default function FilterRange({
  label,
  options=[],
  range={min:-Infinity,max:Infinity},
  selectedRange={min:-Infinity,max:Infinity},
  setSelectedRange=()=>{},
  formatter=x=>x,
  ...props
}){

  const track = useRef();

  const currentKnob = useRef(); // min | max

  const handleMove = useCallback(ev => {
    ev.preventDefault();
    ev.stopPropagation();

    const rect = track.current.getClientRects()[0];
    const pos = Math.max(0,Math.min(1,(ev.clientX - rect.x)/rect.width));
    const value = (range.max-range.min)*pos + range.min;
    const knob = ev.currentTarget.dataset.knob;

    setSelectedRange(prev => ({
      ...prev,
      [ev.currentTarget.dataset.knob]: (
        knob === 'min' ? Math.min(prev.max,value) : Math.max(prev.min,value)
      )
    }));


  },[range,setSelectedRange]);

  const handleDown = useCallback(ev => {
    ev.currentTarget.onpointermove = handleMove;
    ev.currentTarget.setPointerCapture(ev.pointerId);
    currentKnob.current = ev.currentTarget.dataset.knob;
  },[handleMove]);

  const handleUp = useCallback(ev => {
    ev.currentTarget.onpointermove = null;
    ev.currentTarget.releasePointerCapture(ev.pointerId);
  },[]);
  
  const lower = (selectedRange.min-range.min)/(range.max-range.min);
  const upper = (selectedRange.max-range.min)/(range.max-range.min);
  
  return (
    <Dropdown
      {...props}
      label={selectedRange.min !== range.min || selectedRange.max !== range.max ? (
        <><span>{label}: </span><strong>{formatter(selectedRange.min)} – {formatter(selectedRange.max)}</strong></>
      ):label}
    >
      <Root>
        <Label>
          {formatter(range.min)}
        </Label>
        <Slider>
          <Track ref={track}/>
          <TrackHighlight
            lower={lower}
            upper={upper}
          />
          <Knob
            onPointerDown={handleDown}
            onPointerUp={handleUp}
            position={lower}
            data-knob='min'
          />
          <Knob
            onPointerDown={handleDown}
            onPointerUp={handleUp}
            position={upper}
            data-knob='max'
          />
        </Slider>
        <Label>
          {formatter(range.max)}
        </Label>
      </Root>
    </Dropdown>
  );
}
