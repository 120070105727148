import colors from 'colors';
import { primaryFontFamily, secondaryFontFamily } from 'css-toolkit/fonts';
import { useLockBodyScroll } from 'lock-body-scroll';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import close from './assets/close.svg';
import closeThin from './assets/close-thin.svg';
import logo from './assets/mini-logo.svg';

const Root = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  border-radius: 0;
  opacity: 0;
  
  top: 56px;
  right: 78px;
  &[data-open=true]{
    width: 250vw;
    height: 250vw;
    top: calc(-125vw + 56px);
    right: calc(-125vw + 78px);
    border-radius: 125vw;
    opacity: 1;
    transition:
      width 500ms ease-in,
      height 500ms ease-in,
      top 500ms ease-in,
      right 500ms ease-in,
      border-radius 500ms ease-in,
      opacity 100ms ease-in;
  }
  transition:
    width 100ms 500ms,
    height 100ms 500ms,
    top 100ms 500ms,
    right 100ms 500ms,
    border-radius 100ms 500ms,
    opacity 200ms ease-in 300ms;

  @media portrait{
    top: 32px;
    right: 32px;
    &[data-open=true]{
      width: 250vh;
      height: 250vh;
      top: calc(-125vh + 32px);
      right: calc(-125vh + 32px);
      border-radius: 125vh;
    }
  }

  background: ${colors.secondary};
  z-index: 30;
  overflow: hidden;
`;
const Content = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: -56px;
  right: -78px;
  transition: top 100ms 1s, right 100ms 1s;
  [data-open=true] > &{
    top: calc(125vw - 56px);
    right: calc(125vw - 78px);
    transition: top 500ms ease-in, right 500ms ease-in;
  }
  padding: 120pxvh 170px;

  @media portrait{
    top: -32px;
    right: -32px;
    [data-open=true] > &{
      top: calc(125vh - 32px);
      right: calc(125vh - 32px);
      transition: top 500ms ease-in, right 500ms ease-in;
    }
    padding: 70mpxvh 70px;
  }

`;
const Close = styled.div`
  position: absolute;
  top: ${56 - 32}px;
  right: ${78 - 32}px;
  width: 64px;
  height: 64px;
  background: url(${close}) center / 32px no-repeat;
  border-radius: 32px;
  z-index: 36;

  transition:
    transform 1s cubic-bezier(.25,0,0,1);

  backface-visibility: hidden;
  transform: rotate(-360deg);
  [data-open=true] &{
    transform: rotate(0deg);
  }
  [data-open=true][data-animate-close-button=true] &:hover{
    transform: rotate(360deg);
  }
  [data-open=false][data-animate-close-button=true] &:hover{
    transform: rotate(0deg);
  }

  cursor: pointer;

  @media portrait{
    top: ${32 - 16}px;
    right: ${32 - 16}px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-image: url(${closeThin});
    -webkit-tap-highlight-color: transparent;
  }
`;
const Decoration = styled.div`
  position: absolute;

  z-index: 35;

  background: ${colors.secondary};
  border: 2px solid ${colors.background};

  transition:
    width 1s cubic-bezier(.25,0,0,1),
    height 1s cubic-bezier(.25,0,0,1),
    top 1s cubic-bezier(.25,0,0,1),
    right 1s cubic-bezier(.25,0,0,1),
    border-radius 1s cubic-bezier(.25,0,0,1),
    background-color 1s cubic-bezier(0,1,0,1);

  [data-open=true] &{
    width: 0;
    height: 0;
    width: 30vw;
    height: 30vw;
    top: calc(-15vw + 56px);
    right: calc(-15vw + 78px);
    border-radius: 15vw;
  }
  @media landscape{
    [data-animate-close-button=true] ${Close}:hover + &{
      width: 20vw;
      height: 20vw;
      top: calc(-10vw + 56px);
      right: calc(-10vw + 78px);
      border-radius: 10vw;
    }
  }
  [data-open=false] &&&{
    width: 250vh;
    height: 250vh;
    top: calc(-125vh + 32mpxvh);
    right: calc(-125vh + 32mpxvh);
    border-radius: 125vh;
  }

  @media portrait{
    background-color: transparent;
    pointer-events: none;
    [data-open=true] &{
      width: 0;
      height: 0;
      width: 40vh;
      height: 40vh;
      top: calc(-20vh + 32mpxvh);
      right: calc(-20vh + 32mpxvh);
      border-radius: 20vh;
      z-index: 0;
    }
    [data-open=false] &&&{
      background-color: ${colors.secondary};
      width: 250vh;
      height: 250vh;
      top: calc(-125vh + 32mpxvh);
      right: calc(-125vh + 32mpxvh);
      border-radius: 125vh;
    }
  }
`;
const MainLink = styled(Link)`
  display: block;
  cursor: pointer;

  & > span{
    font-family: ${secondaryFontFamily};
    font-size: 80pxvh;
    line-height: 0.95;
    font-weight: 600;

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${colors.primary};
    color: ${colors.secondary};

    transition: color 300ms;
  }
  &:hover{
    & > span{
      color: ${colors.primary};
      @media portrait{
        color: ${colors.background};
      }
    }
    &::after{
      width: calc(100% + 200px);
      transition: width 1s cubic-bezier(.3,0,0,1);
      @media portrait{
        width: 0;
      }
    }
  }

  width: min-content;

  
  position: relative;
  &::after{
    content: ' ';
    display: block;
    position: absolute;
    top: 55%;
    left: -100px;
    width: 0%;
    height: 2pxvh;
    background: ${colors.primary};

    transition: width 400ms ease-in-out;
  }

  @media portrait{
    & > span{
      font-size: 58mpxvh;
      line-height: 1.1;
      font-weight: 500;

      -webkit-text-stroke-width: 0;
      color: ${colors.primary};
    }
  }
`;
const SecondaryLink = styled(Link)`
  display: block;
  cursor: pointer;
  position: relative;

  & > span{
    font-size: 35pxvh;
    font-weight: 500;
    line-height: 1.30;
    letter-spacing: -0.045em;
    color: ${colors.primary};
    transition: color 300ms;
  }
  &:hover > span{
    color: ${colors.background};
  }

  width: max-content;

  ${MainLink} + &{
    margin-top: 60pxvh;
  }

  @media portrait{
    & > span{
      font-size: 30mpxvh;
      line-height: 1.4;
    }
    ${MainLink} + &{
      margin-top: ${20/30}em;
    }
  }

`;
const Count = styled.div`
  position: absolute;
  pointer-events: none;
  top: -0.4em;;
  left: -20pxvh;

  font-family: ${primaryFontFamily};
  font-weight: 400;
  font-size: 16pxvh;
  line-height: 2.5;

  text-align: right;
  letter-spacing: -0.055em;

  color: ${colors.primary};

  @media portrait{
    font-size: 16mpxvh;
  }
`;
const RightColumn = styled.div`
  position: absolute;
  top: 250px;
  right: 280px;

  @media portrait{
    position: static;
    margin-top: 20mpxvh;
  }
`;
const OtherLink = styled(Link)`
  display: block;

  font-size: 35pxvh;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.045em;
  color: ${colors.primary};
  transition: color 300ms;

  &:hover{
    color: ${colors.background};
  }

  @media portrait{
    font-size: 20mpxvh;
    line-height: 1.5;
    font-weight: 600;
  }
`;
const Contacts = styled.p`
  ${OtherLink} + &{
    margin-top: 10pxvh;
  }
  
  font-size: 11pxvh;
  line-height: 1.25;
  color: ${colors.primary};

  & > a{
    transition: color 300ms;
  }
  & > a:hover{
    color: ${colors.background};
  }

  @media portrait{
    display: none;
  }
`;
const Logo = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 53pxvh;

  & > a{
    display: block;
    width: 38pxvh;
    height: 67pxvh;
    margin: 0 auto;
    background: url(${logo}) center / contain no-repeat;
  }

  @media portrait{
    top: 12mpxvh;
    left: 20pxvh;
    & > a{
      width: 22px;
      height: 38px;
      margin: 0;
    }
  }
`;

export default function Menu({
  open,
  setOpen,
  ...props
}){

  useLockBodyScroll(open);

  const [animateCloseButton, setAnimateCloseButton] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      setAnimateCloseButton(false);
    },500);
  },[open,setAnimateCloseButton]);

  const enableCloseButtonAnimation = useCallback(()=>{
    if(animateCloseButton)
      return;
    setTimeout(()=>{
      setAnimateCloseButton(true);
    },250);
  },[setAnimateCloseButton,animateCloseButton]);

  const close = useCallback(()=>{
    setOpen(false);
  },[setOpen]);
  
  return (
    <Root data-open={open} data-animate-close-button={animateCloseButton} {...props}>
      <Content>
        <Logo>
          <Link aria-label="Home page" to="/" onClick={close}/>
        </Logo>
        <Close onClick={close}/>
        <Decoration data-decoration onMouseMove={enableCloseButtonAnimation}/>
        <MainLink to="/destinos/portugal" onClick={close}>
          <Count>1</Count>
          <span>Portugal</span>
        </MainLink>
        <MainLink to="/destinos/espanha" onClick={close}>
          <Count>2</Count>
          <span>Espanha</span>
        </MainLink>
        <MainLink to="/destinos/europa" onClick={close}>
          <Count>3</Count>
          <span>Europa</span>
        </MainLink>
        <MainLink to="/destinos/mundo" onClick={close}>
          <Count>4</Count>
          <span>Mundo</span>
        </MainLink>
        <SecondaryLink to="/destinos/lua-de-mel" onClick={close}>
          <Count>5</Count>
          <span>Lua de mel</span>
        </SecondaryLink>
        <SecondaryLink to="/destinos/ofertas-especiais" onClick={close}>
          <Count>6</Count>
          <span>Ofertas Especiais</span>
        </SecondaryLink>
        <SecondaryLink to="/corporate" onClick={close}>
          <Count>7</Count>
          <span>Corporate</span>
        </SecondaryLink>
        <RightColumn>
          <OtherLink to="/" onClick={close}>
            Home
          </OtherLink>
          <OtherLink to="/sobre-nos" onClick={close}>
            Sobre nós
          </OtherLink>
          <OtherLink to="/afiliados" onClick={close}>
            Afiliados
          </OtherLink>
          <OtherLink to="/contactos" onClick={close}>
            Contactos
          </OtherLink>
          <Contacts>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/Agencia+De+Viagens+E+Turismo+De+Lousada,+Lda./@41.2779192,-8.2861525,17z/data=!3m1!4b1!4m5!3m4!1s0xd2492ee9f3ebc77:0xc2db49cc1ccdcaa6!8m2!3d41.277962!4d-8.2837779"
            >
              Praça das Pocinhas nº 9<br/>
              4620-657 Lousada<br/>
              Portugal
            </a><br/>
            <br/>
            <a href="mailto:geral@viagenslousada.pt">geral@viagenslousada.pt</a><br/>
            <a href="tel:+351255814009">+351 255 814 009</a><br/>
          </Contacts>
        </RightColumn>
      </Content>
    </Root>
  );
}
