import colors from 'colors';
import colorOpacity from 'color-opacity';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useExternalState from 'use-external-state';
import preloadImages from 'preload-images';

import radioUnselected from './assets/radio-unselected.svg';
import radioSelected from './assets/radio-selected.svg';
import radioHover from './assets/radio-hover.svg';
import radioUnselectedAlt from './assets/radio-unselected-alt.svg';
import radioSelectedAlt from './assets/radio-selected-alt.svg';
import radioHoverAlt from './assets/radio-hover-alt.svg';

import radioUnselectedThin from './assets/radio-unselected-thin.svg';
import radioSelectedThin from './assets/radio-selected-thin.svg';
import radioUnselectedAltThin from './assets/radio-unselected-alt-thin.svg';
import radioSelectedAltThin from './assets/radio-selected-alt-thin.svg';

preloadImages([
  radioUnselected,
  radioSelected,
  radioHover,
  radioUnselectedAlt,
  radioSelectedAlt,
  radioHoverAlt,

  radioUnselectedThin,
  radioSelectedThin,
  radioUnselectedAltThin,
  radioSelectedAltThin,
]);

const Error = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: ${colors.secondary};
  text-transform: uppercase;

  height: 0;
  padding-left: 0.5em;
  position: relative;
  text-align: right;
  top: -30px;
  float: right;

  pointer-events: none;
  display: none;

  @media mobile{
    backdrop-filter: blur(100px);
  }
  width: max-content;
`;
const Root = styled.div`
  width: 100%;

  user-select: none;

  padding: 16px 0 16px 14px;
  border-bottom: 2px solid ${colorOpacity(colors.background,0.3)};
  &[data-single=true]{
    border-top: 2px solid ${colorOpacity(colors.background,0.3)};
  }
  &[data-variant=light]{
    border-top-color: ${colors.foreground};
    border-bottom-color: ${colors.foreground};
  }
  &[data-variant=ultralight]{
    border-top-color: ${colors.background};
    border-bottom-color: ${colors.background};
  }

  @media portrait{
    padding: 16px 0 10px 14px;
    &[data-missing-placeholder=true]{
      padding: 10px 0 10px 14px;
    }
  }
`;
const Placeholder = styled.div`
  display: inline-block;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${colorOpacity(colors.background,0.6)};
  [data-variant=light] > &{
    color: ${colorOpacity(colors.foreground,0.6)};
  }
  [data-variant=ultralight] > &{
    color: ${colorOpacity(colors.foreground,0.3)};
  }
`;
const ControlButton = styled.div`
  display: inline-block;

  height: 18px;

  padding-left: 28px;

  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;

  margin: 0 32px;

  /* color */
  color: ${colorOpacity(colors.background,0.6)};
  &[data-selected=true]{
    color: ${colors.background};
  }
  [data-variant=light] > &{
    color: ${colorOpacity(colors.foreground,0.6)};
    &[data-selected=true]{
      color: ${colors.foreground};
    }
  }
  [data-variant=ultralight] > &{
    color: ${colorOpacity(colors.foreground,0.3)};
    &[data-selected=true]{
      color: ${colors.foreground};
    }
  }


  /* background */
  background: url(${radioUnselectedAlt}) top left / contain no-repeat;
  &:hover{
    background-image: url(${radioHoverAlt});
  }
  &[data-selected=true]{
    background-image: url(${radioSelectedAlt});
  }
  [data-variant=light] > &{
    background-image: url(${radioUnselected});
    &:hover{
      background-image: url(${radioHover});
    }
    &[data-selected=true]{
      background-image: url(${radioSelected});
    }
  }
  [data-variant=ultralight] > &{
    background-image: url(${radioUnselected});
    &:hover{
      background-image: url(${radioHover});
    }
    &[data-selected=true]{
      background-image: url(${radioSelected});
    }
  }

  @media portrait{
    display: block;
    margin: 10px 0;
    height: 40px;
    line-height: 40px;
    padding-left: 54px;

    /* background mobile */
    background: url(${radioUnselectedAltThin}) top left / contain no-repeat;
    &[data-selected=true]{
      background-image: url(${radioSelectedAltThin});
    }
    [data-variant=light] > &{
      background-image: url(${radioUnselectedThin});
      &[data-selected=true]{
        background-image: url(${radioSelectedThin});
      }
    }
    [data-variant=ultralight] > &{
      background-image: url(${radioUnselectedThin});
      &[data-selected=true]{
        background-image: url(${radioSelectedThin});
      }
    }
  }

  cursor: pointer;
`;

const InputRadio = React.forwardRef(function InputRadio({
  variant="dark",
  single="false",
  placeholder=null,
  selectedValue: extSelectedValue=null,
  setSelectedValue: extSetSelectedValue=null,
  min=-Infinity,
  max=+Infinity,
  options=[],
  name,
  ...props
},ref){

  const [selectedValue,setSelectedValue] = useExternalState(
    extSelectedValue,
    extSetSelectedValue,
    0
  );

  const handleSelectOption = useCallback(ev=>{
    setSelectedValue(ev.target.dataset.value);
  },[setSelectedValue]);

  return <>
    <Root
      data-variant={variant}
      data-single={single}
      data-missing-placeholder={!placeholder}
      {...props}
    >
      {name ? <input type="hidden" name={name} value={selectedValue}/> : null}
      {placeholder ? <Placeholder>{placeholder}</Placeholder> : null}
      {options.map((option,idx) => (
        <ControlButton
          key={idx}
          data-value={option.value}
          data-selected={option.value === selectedValue}
          onClick={handleSelectOption}>
          {option.label}
        </ControlButton>
      ))}
    </Root>
    <Error>
      Campo obrigatório
    </Error>
  </>;
});

export default InputRadio;
