import colorOpacity from 'color-opacity';
import colors from 'colors';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui-components/Button';
import CustomTripForm from 'ui-components/CustomTripForm';
import FilterRange from 'ui-components/FilterRange';
import Select from 'ui-components/Select';
import SelectMonth from 'ui-components/SelectMonth';

const Root = styled.div`
  background: ${colors.background};

  padding: 0 85px;

  display: grid;
  grid-template-columns: repeat(4,1fr) 2fr;

  & > div{
    padding: 12px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media portrait{
    padding: 0 16px;
    grid-template-columns: 1fr;
    & > div{
      padding: 0;
      border-bottom: 1px solid ${colorOpacity(colors.foreground,0.3)};
      align-items: flex-start;

      &:nth-last-child(1){
        border: none;
        padding: 8px 0;
      }
    }
  }
`;
const CustomTripButton = styled(Button)`
  width: 370px;

  @media portrait{
    width: 100%;
  }
`;

export default function Filters({
  customTripFormOpen,
  setCustomTripFormOpen,
  ...props
}){

  const destinoOptions = [
    {label:'Portugal',value:'portugal'},
    {label:'Espanha',value:'espanha'},
    {label:'Europa',value:'europa'},
    {label:'Mundo',value:'mundo'},
  ];
  const outrasOfertasOptions = [
    {label:'Lua de mel',value:'lua-de-mel'},
    {label:'Ofertas especiais',value:'ofertas-especiais'},
  ];

  const {category} = useParams();

  const destinoSelectedValue = (
    destinoOptions.some(option => option.value === category) ? (
      category
    ) : null
  );
  const outrasOfertasSelectedValue = (
    outrasOfertasOptions.some(option => option.value === category) ? (
      category
    ) : null
  );

  const navigate = useNavigate();

  const handleSelectOption = useCallback((value)=>{
    navigate(`/destinos/${value}`);
  },[navigate]);

  const [priceRange, setPriceRange] = useState({
    min: 250,
    max: 1000
  });
  
  return <>
    <Root {...props}>
      <div>
        <Select
          label="Destino"
          options={destinoOptions}
          selectedOption={destinoSelectedValue}
          setSelectedOption={handleSelectOption}
        />
      </div>
      <div>
        <Select
          label="Outras ofertas"
          options={outrasOfertasOptions}
          selectedOption={outrasOfertasSelectedValue}
          setSelectedOption={handleSelectOption}
        />
      </div>
      <div>
        <FilterRange
          label="Por preço"
          formatter={v => Math.round(v)+'€'}
          range={{min:250,max:1000}}
          selectedRange={priceRange}
          setSelectedRange={setPriceRange}
        />
      </div>
      <div>
        <SelectMonth
          label="Por mês"
        />
      </div>
      <div>
        <CustomTripButton
          variant="transparent"
          onClick={()=>setCustomTripFormOpen(true)}
        >
          Não encontrei o que pretendia
        </CustomTripButton>
      </div>
    </Root>
    <CustomTripForm open={customTripFormOpen} setOpen={setCustomTripFormOpen}/>
  </>;
}
