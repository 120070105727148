import colors from 'colors';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import arrow from './assets/arrow-right.svg';

const Root = styled.div`
  margin-top: 60px;

  font-size: 20px;
  font-weight: 600;
  color: ${colors.secondary};
  line-height: 0.986;
  

  background: url(${arrow}) center right / contain no-repeat;
  
  text-align: right;

  & > a{
    display: inline-block;
    padding-right: 39.2px;
    cursor: pointer;
    
    //todo: put in a separate file
    &:hover{
      filter: brightness(120%);
    }
  }
  

`;

export default function VerMais({to,...props}){
  
  return (
    <Root {...props}>
      <Link to={to || ''}>
        Ver mais
      </Link>
    </Root>
  );
}
