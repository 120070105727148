export default function requestEndpoint(
  root,
  endpoint,
  body
){
  return new Promise((resolve,reject)=>{
    window.fetch(`${root}${endpoint.resource}`,{
      method: endpoint.method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    }).then(async res => {
      body = await res.json().catch(()=>null);

      if(res.ok)
        resolve(body);
      else
        reject(Object.assign(new Error(),
          body && body.error ? body.error : {
            name: `HTTP ${res.status}`,
            message: res.statusText
          }
        ));
    }).catch(error => reject(error));
  });
}
