import React, { useCallback } from 'react';

const GenericForm = React.forwardRef(function GenericForm({
  onSubmit = null,
  endpoint = null,
  setSent=()=>{},
  setError=()=>{},
  setSending=()=>{},
  ...props
},ref){

  const submit = useCallback(ev=>{
    ev.preventDefault();

    const form = ev.currentTarget;
  
    const data = {};
    for(const el of [...form.elements]){
      if(!el.name)
        continue;
      if(el.tagName === 'TEXTAREA')
        data[el.name] = el.value.replace(/\n/g,' / ');
      else
        data[el.name] = el.value;
    };
    
    setError(null);
    setSending(true);
  
    endpoint(data).then(()=>{
      setSending(false);
      setSent(true);
    }).catch(error => {
      setError(error.toString());
    }).finally(()=>{
      setSending(false);
    });

  },[endpoint,setSending,setSent,setError]);

  const handleSubmit = useCallback(ev=>{
    if(!ev.target.reportValidity()){
      ev.target.dataset.validate = true;
      ev.preventDefault();
      return false;
    }
    if(onSubmit)
      return onSubmit(ev);
    if(endpoint)
      return submit(ev);
    return true;
  },[onSubmit,endpoint,submit]);

  return <form {...props} noValidate={true} onSubmit={handleSubmit} ref={ref}/>
});

export default GenericForm;
