import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import List from 'pages/CorporateAffiliates/List';
import React, { useState } from 'react';
import styled from 'styled-components';
import TopBar from 'ui-components/TopBar';

const Root = styled.div`

`;
const Cover = styled.div`
  height: 530px;
  background: url(${p=>p.$src}) center / cover no-repeat;

  margin-top: -77pxva;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1{
    font-family: ${secondaryFontFamily};
    color: ${colors.secondary};
    font-weight: 500;
    font-size: 80px;
    line-height: 0.85;
    letter-spacing: -0.035em;
  }

  @media portrait{
    height: 242px;
    margin: 0;

    background: 
      linear-gradient(#0004,#0004), 
      url(${p => p.$src}) center / cover no-repeat;

    & > h1{
      font-size: 45px;
      letter-spacing: -0.025em;
      font-weight: 400;
      color: #fff;
    }
  }
`;
const TextBox = styled.p`
  width: 1556px;
  padding: 130px 160px;
  margin-top: -90px;
  margin-left: auto;
  margin-right: auto;

  font-size: 20px;
  line-height: 1.35;

  color: ${colors.foreground};
  background: ${colors.background};



  @media portrait{
    width: 100%;
    margin: 0;

    padding: 75px 16px;
    background: ${colors.background2};
  }

`;
const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding: 32px 340px 30px;

  & > div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  & > div > h2{
    font-family: ${secondaryFontFamily};
    font-size: 50px;
    font-weight: 600;
    line-height: 0.9;
    letter-spacing: -0.025em;
    color: ${colors.primary};
    text-align: left;
  }
  & > div > p{
    font-weight: 700;
    font-size: 25px;
    line-height: 1.28;
    color: ${colors.primary};
    text-align: right;
  } 
  @media portrait{
    display: none;
  }
`;
const People = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 640px;
  @media portrait{
    display: none;
  }
`;
const Person = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  padding: 60px 30px;
  overflow: hidden;

  position: relative;
  background: #181818;
`;
const PersonPicture = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  height: 100%;

  background-color: ${colors.primary};
  background: url(${p => p.$src}) center / cover no-repeat;  
`;
const PersonName = styled.div`
  position: relative;

  font-family: ${secondaryFontFamily};
  font-weight: 400;
  font-size: 35px;
  line-height: 0.71;
  letter-spacing: 0.01em;
  color: ${colors.background};
`;
const PersonTitle = styled.div`
  position: relative;

  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: ${colors.background};

  margin-top: 16px;
`;

export default function AboutUs({...props}){
  
  const [topBar, setTopBar] = useState(null);

  return (
    <Root {...props}>
      <TopBar ref={setTopBar} inverseDesktop={true}/>
      <Cover $src={require(`./assets/cover.jpg`).default}>
        <h1>Sobre nós</h1>
      </Cover>
      <TextBox>
        Desde 1993, a Agência de Viagens e Turismo de Lousada tem o compromisso de trazer a todos os clientes o melhor em termos de custo e qualidade. Somos apaixonados por viagens e por compartilhar as maravilhas do mundo no que se refere a viagens de lazer, e a fornecer aos clientes corporativos serviços de alta qualidade para facilitar suas necessidades de viagens de negócios.
        <br/><br/>
        Somos uma agência de viagens feita de pessoas para pessoas, ancorada por valores, integridade e dedicação a cada cliente.
      </TextBox>
      <TitleGrid>
        <div>
          <h2>A equipa</h2>
        </div>
        <div>
          <p>Somos o melhor de nós ao serviço de cada cliente.</p>
        </div>
      </TitleGrid> 
      <People>
        <Person>
          <PersonPicture $src={require(`./assets/filipa.jpg`).default}/>
          <PersonName>Filipa Machado</PersonName>
          <PersonTitle>Chefe Agência </PersonTitle>
        </Person>
        <Person>
          <PersonPicture $src={require(`./assets/cristiana.jpg`).default}/>
          <PersonName>Cristiana Pinto</PersonName>
          <PersonTitle>Consultora Viagens</PersonTitle>
        </Person>
        <Person>
          <PersonPicture $src={require(`./assets/bruna.jpg`).default}/>
          <PersonName>Bruna Silva</PersonName>
          <PersonTitle>Consultora Viagens</PersonTitle>
        </Person>
      </People>
      <List topBar={topBar} title="O que nos define:">
        <li>Ganhamos confiança tratando os outros com integridade, respeito e justiça.</li>
        <li>Criamos parcerias por meio de uma comunicação aberta e honesta.</li>
        <li>Alcançamos a excelência por sermos inovadores e criativos.</li>
        <li>Somos pessoalmente responsáveis por qualquer desafio que chegue ao nosso conhecimento.</li>
        <li>Colaboramos para apoiar os objetivos da empresa e o desenvolvimento de bons relacionamentos.</li>
        <li>Temos uma cultura onde nos desafiamos a crescer pessoal e profissionalmente por meio de educação, formação e viagens.</li>
        <li>Representamos adequadamente a nossa empresa com cidadania responsável dentro e fora do local de trabalho.</li>
        <li>Temos integridade e construímos as relações entre a empresa e o cliente nos mais elevados padrões éticos.</li>
        <li>Promovemos a felicidade na família de:Lousada.</li>
      </List>
    </Root>
  );
}
