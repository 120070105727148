export const fontsInUse = [
  {
    family: 'Montserrat',
    alternative: 'sans-serif',
    weights: [400,500,600,700],
    italicWeights: [],
  },
  {
    family: 'Poppins',
    alternative: 'sans-serif',
    weights: [200,300,400,500,600,700],
    italicWeights: [],
  },
  {
    family: 'Source Serif Pro',
    alternative: 'serif',
    weights: [],
    italicWeights: [400],
  },
];


export const primaryFontFamily = `'${fontsInUse[0].family}', ${fontsInUse[0].alternative}`;
export const secondaryFontFamily = `'${fontsInUse[1].family}', ${fontsInUse[1].alternative}`;

export const defaultFontFamily = primaryFontFamily;
export const defaultFontWeight = 400;
