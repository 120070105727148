import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { createContext, useContext, useState } from "react";

import Corporate from "pages/CorporateAffiliates/Corporate";
import Affiliates from "pages/CorporateAffiliates/Affiliates";
import Destination from "pages/Destination/Destination";
import Home from "pages/Home/Home";
import Listing from "pages/Listing/Listing";
import Privacy from "pages/Legal/Privacy";
import Footer from "ui-components/Footer";
import AboutUs from "pages/AboutUs/AboutUs";
import ContactUs from "pages/ContactUs/ContactUs";
import Menu from "ui-components/Menu";
import Cookies from "pages/Legal/Cookies";
import Terms from 'pages/Legal/Terms';

const menuContext = createContext(()=>{});
const MenuContextProvider = menuContext.Provider;
export function useSetMenuOpen(){
  return useContext(menuContext);
}

function App() {

  const [menuOpen, setMenuOpen] = useState(false);

  return <MenuContextProvider value={setMenuOpen}>
    <Router>
      
      <Menu open={menuOpen} setOpen={setMenuOpen}/>

      <Routes>

        <Route path="/" element={<>
          <Home/>
        </>}/>

        <Route path="/destinos/:category" element={<>
          <Listing/>
        </>}/>

        <Route path="/destinos/:category/:id" element={<>
          <Destination/>
        </>}/>

        <Route path="/corporate" element={<>
          <Corporate/>
        </>}/>

        <Route path="/afiliados" element={<>
          <Affiliates/>
        </>}/>

        <Route path="/sobre-nos" element={<>
          <AboutUs/>
        </>}/>

        <Route path="/contactos" element={<>
          <ContactUs/>
        </>}/>

        <Route path="/privacidade" element={<>
          <Privacy/>
        </>}/>

        <Route path="/cookies" element={<>
          <Cookies/>
        </>}/>

        <Route path="/condicoes-gerais" element={<>
          <Terms/>
        </>}/>

      </Routes>
      
      <Footer/>
    </Router>
    
  </MenuContextProvider>;
}

export default App;
