import colors from 'colors';
import { secondaryFontFamily } from 'css-toolkit/fonts';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import spiral from './assets/spiral-decoration.svg';
import clouds1 from './assets/clouds1.png';
import clouds2 from './assets/clouds2.png';
import useAnimationFrame from 'use-animation-frame';
import DestinationThumb from 'ui-components/DestinationThumb';
import InputText from 'ui-components/InputText';
import GenericForm from 'ui-components/GenericForm';
import Button from 'ui-components/Button';
import VerMais from 'ui-components/VerMais';
import bezier from 'bezier-easing';
import { sendHoneyMoon } from 'api';
import useMediaQuery from 'use-media-query';
import mediaQueries from 'css-toolkit/media-queries';
import MiniDestinationThumb from 'ui-components/MiniDestinationThumb';
import portugal from 'temp-data/portugal';
import espanha from 'temp-data/espanha';
import europa from 'temp-data/europa';

const Root = styled.div`
  position: relative;

  background:
    url(${clouds1}) top left -100% / 300px no-repeat,
    url(${clouds2}) top right -100% / 404px no-repeat,
    linear-gradient(180deg, #2F0283 0%, #4E04D8 96.76%);

  padding-bottom: 274px;

  @media portrait{
    background: linear-gradient(180deg, #2F0283 0%, #4E04D8 96.76%);
    padding-bottom: 50px;
  }
`;
const Title = styled.h1`
  width: 937px;
  text-align: right;

  padding-top: 275px;
  margin-left: 705px;

  color: ${colors.secondary};
  font-size: 150px; // Gilroy: 160px
  line-height: 0.84;
  font-weight: 200;
  letter-spacing: -0.035em;
  font-family: ${secondaryFontFamily};

  @media portrait{
    max-width: 100%;
    overflow: hidden;
    
    text-align: left;

    padding: 119px 24px 0;
    margin: 0;

    font-size: 65px;
    line-height: 1.1;
    letter-spacing: -0.045em;
    
    & > span{
      /* display: block; */
      position: relative;
      /* &:nth-child(odd){
        left: -0.25em;
      }
      &:nth-child(even){
        left: +0.25em;
      } */
    }
  }
`;
const Text = styled.p`
  width: 608px;
  margin-left: 340px;
  
  color: ${colors.background};
  font-size: 20px;
  line-height: 1.35;
  font-weight: 500;
  
  & > span{
    @media portrait{
      & > span{
        display: none;
      }
    }
  }

  @media portrait{
    font-size: 19px;
    width: 100%;
    margin: 0;
    margin-top: 43px;
    padding: 0 24px;
  }
`;
const Decoration = styled.div`
  position: absolute;
  width: 92.57px;
  height: 130.87px;
  top: 502px;
  left: 118px;
  background: url(${spiral}) center / contain no-repeat;

  @media portrait{
    display: none;
  }
`;
const SampleDestination = styled(DestinationThumb)`
  @media portrait{
    display: none;
    & + div{
      display: none;
    }
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 972px;
  column-gap: 183px;
  margin-top: 91px;

  @media landscape{
    & > :first-child{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  @media portrait{
    margin-top: 60px;
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
`;
const MainForm = styled(GenericForm)`
  width: 700px;
  & button{
    width: 292px;
    @media mobile{
      width: 100%;
    }
  }
  @media portrait{
    width: 100%;
    padding: 25px;
  }
`;
const FormTitle = styled.h2`
  font-family: ${secondaryFontFamily};
  font-size: 50px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: -0.025em;
  color: ${colors.secondary};

  @media landscape{
    & > br{
      display: none;
    }
  }
  @media portrait{
    font-size: 34px;
    line-height: 0.97;
  }
`;
const FormInstructions = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.background};
  margin-top: 23px;
  margin-bottom: 34px;

  @media portrait{
    font-weight: 500;
    
    & > span{
      display: block;
      transition: opacity 500ms;
      margin-top: 1em;
    }
    [data-mobile-form-open=false] &{
      & > span{
        opacity: 0;
        height: 0;
        margin-top: -5px;
      }
    }
  }
`;
const FieldsWrapper = styled.div`
  position: relative;

  @media landscape{
    & + button{
      display: none;
    }
  }

  @media portrait{
    overflow-y: hidden;
    height: 650px;
    [data-mobile-form-open=false] &{
      height: 0px;
    }
    [data-mobile-form-open=true] &{
      & + button{
        display: none;
      }
    }
    transition: height 1s ease-in-out;

    & textarea{
      height: 72px;
      min-height: unset;
      resize: none;
      &:last-of-type{
        height: auto;
        min-height: 155px;
        resize: vertical;
      }
    }
  }
`;
const FormFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 26px;

  margin-top: 30px;

  & > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media portrait{
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
`;
const Error = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  text-transform: uppercase;
  color: ${colors.secondary};
`;
const Success = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.primary};

  color: ${colors.background};
  font-size: 14px;
  line-height: 1.57;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  @media portrait{
    justify-content: flex-end;
    padding-bottom: 100px;
  }
`;
const Carousel = styled.div`
  height: 374px;
  overflow-y: hidden;
  & > div{
    height: 390px;
    max-width: 100vw;
    overflow-x: scroll;
    & > div{
      padding: 0 5px;
      height: 100%;
      width: min-content;
      white-space: nowrap;
    }
  }
  @media landscape{
    display: none;
  }
`;


const sampleDestination = {
  pictures: [require('./temp-pics/phuket.jpg').default],
  title: 'Phuket',
  info: [
    'Pacote ( 7 Noites )',
    'The Village Coconut Island',
  ],
  price: '1540,00 €',
  details: [
    'Preço por pessoa',
  ],
  stars: 5,
};


export default function HoneyMoon({topBar,...props}){
  
  const portrait = useMediaQuery(mediaQueries.portrait);

  const rootRef = useRef();
  const decorationRef = useRef();

  // clouds animation
  useAnimationFrame(()=>{
    if(!rootRef.current)
      return;
    if(portrait){
      rootRef.current.style.removeProperty('background-position');
      return;
    }
    if(!decorationRef.current)
      return;
    if(!topBar)
      return;

    const top = rootRef.current.offsetTop;
    const scrollTop = document.scrollingElement.scrollTop;
    const height = window.innerHeight;
    const topbarHeight = topBar.firstElementChild.offsetHeight; 

    const progress = Math.min(1,Math.max(0,(
      (scrollTop + topbarHeight*3 - top)/(height*1.25)
    )));

    const ease = bezier(.47,.21,.5,.79);

    const p = ease(progress);

    const nextBackgroundPosition = `
      top 4.427vw left -${p*30}%,
      top 27.969vw right -${p*30}%,
      0
    `;
    const nextOpacity = 1 - p;

    if(nextBackgroundPosition !== rootRef.current.style.backgroundPosition)
      rootRef.current.style.backgroundPosition = nextBackgroundPosition;
    if(nextOpacity !== decorationRef.current.style.opacity)
      decorationRef.current.style.opacity = nextOpacity;
  },[rootRef,decorationRef,topBar,portrait]);

  // topbar color animation
  useAnimationFrame(()=>{
    if(!topBar)
      return;
    if(!rootRef.current)
      return;

    const initial = [0x2F,0x02,0x83];
    const final = [0x4E,0x04,0xD8];

    const top = rootRef.current.offsetTop;
    const topbarHeight = topBar.firstElementChild.offsetHeight; 
    const scrollTop = document.scrollingElement.scrollTop;
    const height = rootRef.current.offsetHeight;
    const windowHeight = window.innerHeight;

    const progress = (
      Math.min(1,Math.max(0,(scrollTop - top + topbarHeight)/height))
      + Math.min(1,Math.max(0,(top - scrollTop - topbarHeight)/windowHeight))
    );

    const color = initial.map((_,i)=>(
      initial[i] + (final[i]-initial[i])*progress
    ));

    if(topBar.dataset.opaque === "true" && scrollTop > top-windowHeight && scrollTop < top+height){
      topBar.firstElementChild.style.background = `rgb(${color.join()})`;
      topBar.firstElementChild.style.transition = 'none';
    }else{
      topBar.firstElementChild.style.background = '';
      topBar.firstElementChild.style.transition = '';
    }
  },[topBar]);

  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const [mobileFormOpen, setMobileFormOpen] = useState(false);

  return (
    <Root data-mobile-form-open={mobileFormOpen} ref={rootRef} {...props}>
      <Decoration ref={decorationRef}/>
      <Title>
        <span>A Lua </span>
        <span>de Mel </span>
        <span>Perfeita</span>
      </Title>
      <Text>
        {/* Second level span is omitted on mobile */}
        <span><span>
          Desde a nossa existência organizamos centenas de viagens de Lua de Mel e obtivemos o agradecimento de tantos casais por termos conseguido desenhar a melhor experiência romântica que poderiam esperar. 
          <br/><br/>
        </span></span>
        <span>
          Sabemos <span>por isso</span> que alguns destinos reúnem uma certa magia que potencia o Amor. Conheça as nossas sugestões ou personalize a sua viagem romântica de sonho. 
        </span>
      </Text>
      <Grid>
        <div>
          <Carousel>
            <div>
              <div>
                <MiniDestinationThumb large={true} destination={portugal[2]}/>
                <MiniDestinationThumb large={true} destination={europa[1]}/>
                <MiniDestinationThumb large={true} destination={espanha[3]}/>
                <MiniDestinationThumb large={true} destination={europa[2]}/>
              </div>
            </div>
          </Carousel>
          <SampleDestination
            destination={sampleDestination}
            alt={true}
          />
          <VerMais/>
        </div>
        <MainForm setError={setError} setSent={setSent} setSending={setSending} endpoint={sendHoneyMoon}>
          <FormTitle>
            Uma Lua de Mel<br/> à medida dos seus sonhos
          </FormTitle>
          <FormInstructions>
            Vamos preparar para si uma experiência romântica personalizada e de acordo com o que gosta.<span> Precisamos apenas da seguinte informação:</span>
          </FormInstructions>
          <FieldsWrapper>
            <InputText name="nome" placeholder="Nome" required={true}/>
            <InputText name="email" placeholder="E-mail" type="email" required={true}/>
            <InputText name="telefone" placeholder="Telefone" required={true}/>
            <InputText name="destinos" type={portrait?'textarea':'text'} placeholder="Quando pensa em Lua de Mel que países imagina?"/>
            <InputText name="dias" type={portrait?'textarea':'text'} placeholder="Quantos dias terá a sua Lua de Mel?"/>
            <InputText name="datas" placeholder="Em que data vai acontecer?" required={true}/>
            <InputText name="orcamento" placeholder="Qual o seu orçamento?" required={true}/>
            <InputText name="informacoesAdicionais" type="textarea" placeholder="Partilhe conosco os detalhes da sua viagem romântica de sonho. Será numa cabana numa praia paradisíaca de águas cálidas? :) "/>
            <FormFooter>
              <div>
                <Button disabled={sending}>Pedir proposta</Button>
              </div>
              <div>
                {error ? (
                  <Error>
                    Falha no envio, tente novamente mais tarde.
                  </Error>
                ):null}
              </div>
            </FormFooter>
            {sent ? <Success>
              <div>
                Mensagem enviada com sucesso.<br/>
                Em breve entramos em contato.
              </div>
            </Success> : null}
          </FieldsWrapper>
          <Button onClick={(ev)=>{
            ev.preventDefault();
            setMobileFormOpen(true);
          }}>Pedir proposta</Button>
        </MainForm>
      </Grid>
    </Root>
  );
}
