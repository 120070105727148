import React, { useCallback } from 'react';
import styled from 'styled-components';

import chevron from './assets/chevron-down.svg';
import useExternalState from 'use-external-state';
import colorOpacity from 'color-opacity';
import colors from 'colors';

const Input = styled.div`
  overflow: visible;
  position: relative;
  font-size: 16px;
  user-select: none;

  display: inline-block;
  position: relative;

  font-size: inherit;

  height: ${52/14}em;
  /* width: 100%; */

  width: max-content;

  font-weight: 400;
  & strong{
    font: inherit;
    font-weight: 600;
  }
  text-transform: uppercase;
  line-height: ${55/14}em;

  color: ${colors.primary};
  &:hover,&[data-open=true]{
    color: ${colors.secondary};
  }

  padding-right: 22px;
  &::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 14px;
    top: 0;
    right: 0;
    background: url(${chevron}) center / contain no-repeat;
    

    transition: transform 300ms;
  }
  &[data-variant=black]{
    &::after{
      filter: invert(100%);
    }
  }
  &[data-open=true]::after{
    transform: scaleY(-1);
  }
  &[data-disabled=true]{
    pointer-events: none;
    opacity: 0.5;
  }

  cursor: pointer;
`;
const Label = styled.span`
  pointer-events: none;
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: ${colorOpacity(colors.foreground,0.05)};

  opacity: 1;
  transition: opacity 300ms;
  &[data-open=false]{
    opacity: 0;
    pointer-events: none;
  }

  &[data-variant=black]{
    background: ${colorOpacity(colors.foreground,0.1)};
  }
`;
const Popup = styled.div`
  font-size: inherit;

  position: absolute;
  background: ${colors.background};
  width: min-content;
  height: auto;
  top: calc(100% - 12px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  box-shadow: 0 0 ${40/14}em 0  rgba(21, 35, 51, 0.2);

  @media portrait{
    left: 0;
    transform: none;
  }

  opacity: 1;
  transition: opacity 300ms;
  &[data-open=false]{
    opacity: 0;
    pointer-events: none;
  }

  &[data-variant=black]{
    box-shadow: 0 3em ${60/14}em 4em #000b;
  }

  cursor: default;
`;

export default function Dropdown({
  label,
  children,
  variant="white",
  open: extOpen,
  setOpen: extSetOpen,
  placeholderShown=false,
  disabled=false,
  ...props
}){

  const [open,setOpen] = useExternalState(extOpen,extSetOpen,false);

  const handleClick = useCallback((ev)=>{
    if(ev.currentTarget !== ev.target)
      return;
    setOpen(x=>!x)
  },[setOpen]);

  return <>
    <Overlay data-variant={variant} onClick={()=>setOpen(false)} data-open={open}/>
    <Input
      {...props}
      tabIndex="0"
      data-open={open}
      data-disabled={disabled}
      onClick={handleClick}
      data-variant={variant}
      data-placeholdershown={placeholderShown}
    >
      <Label>{label}</Label>
      <Popup data-open={open} data-variant={variant}>
        {children}
      </Popup>
    </Input>
  </>;
}
