import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import * as PIXI from 'pixi.js'
import picture from './assets/picture.jpg';
import gradient from './assets/gradient.png';
import { useWindowSize } from '@react-hook/window-size/throttled';
import { PixiApp, PixiSprite, PixiStage } from 'Pixi/Pixi';
import useMediaQuery from 'use-media-query';
import mediaQueries from 'css-toolkit/media-queries';

const Root = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > *{
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    /* top: 0;
    left: 0; */
  }

  @media portrait{
    display: none;
  }
`;

export default function HeaderAnimation3({...props}){

  const [windowWidth,windowHeight] = useWindowSize();

  const rendererSize = useMemo(()=>{
    const aspectRatio = 16/9;
    const width = Math.max(windowWidth,windowHeight*aspectRatio);
    const height = width/aspectRatio;
    return {aspectRatio,width,height};
  },[windowWidth,windowHeight]);

  const [app,setApp] = useState();
  const appCallbackRef = useCallback((ref)=>{
    setApp(ref);
  },[setApp]);
  
  const maskTexture = useMemo(()=>{
    if(!app)
      return null;
    const radius = 300;
    const blurSize = 2;
    const circle = new PIXI.Graphics()
      .beginFill(0xFFFFFF) 
      .drawCircle(radius + blurSize, radius + blurSize, radius)
      .endFill();
    circle.filters = [new PIXI.filters.BlurFilter(blurSize)];
    return app.renderer.generateTexture(
      circle,
      PIXI.SCALE_MODES.NEAREST,
      1,
      new PIXI.Rectangle(0, 0, (radius + blurSize) * 2, (radius + blurSize) * 2)
    );
  },[app]);

  const [focusSprite, setFocusSprite] = useState();
  const focusSpriteCallbackRef = useCallback((ref)=>{
    setFocusSprite(ref);
  },[setFocusSprite]);
  
  const [gradientSprite, setGradientSprite] = useState();
  const gradientSpriteCallbackRef = useCallback((ref)=>{
    setGradientSprite(ref);
  },[setGradientSprite]);

  const speedMeasurementInterval = 50;

  const lastCoord = useRef({x:0,y:0});
  const lastSpeedMeasuredTimestamp = useRef(0);
  const lastSpeed = useRef(0);
  const cursorPos = useRef({x:-1000,y:-1000});

  const handleMouseMove = useCallback((ev)=>{
    cursorPos.current = {
      x: ev.data.global.x,
      y: ev.data.global.y,
    };

    const currentCoord = {
      x: ev.data.global.x,
      y: ev.data.global.y
    };
    const currentTimestamp = performance.now();

    cursorPos.current = currentCoord;

    if(currentTimestamp - lastSpeedMeasuredTimestamp.current > speedMeasurementInterval){
      const speed = Math.sqrt(
        Math.pow(lastCoord.current.x - currentCoord.x,2)
        + Math.pow(lastCoord.current.y - currentCoord.y,2)
      ) / (currentTimestamp - lastSpeedMeasuredTimestamp.current);

      lastCoord.current = currentCoord;
      lastSpeedMeasuredTimestamp.current = currentTimestamp;
      lastSpeed.current = speed;
    }

  },[cursorPos]);

  const canvasRef = useRef();

  const [enableCursor, setEnableCursor] = useState(false);

  const animateFocus = useCallback(()=>{
    if(!app)
      return;
    if(!focusSprite)
      return;
    if(!canvasRef.current)
      return;

    if(performance.now() - lastSpeedMeasuredTimestamp.current >= 2*speedMeasurementInterval)
      lastSpeed.current = 0;

    const baseSize = 200/1440 * Math.max(window.innerWidth,(16/9)*window.innerHeight);
    const targetSize = baseSize * (1 + 0.5*Math.min(lastSpeed.current,2));
    
    const increaseRatio = 2/60;
    const decreaseRatio = 2/60;
    const baseRatio = 3/60;

    const ratio = (
      (focusSprite.width > targetSize ? decreaseRatio : increaseRatio)
      + baseRatio * (Math.pow(Math.max(Math.min(Math.abs(
        focusSprite.width - targetSize) / targetSize
      ,0),1),2))
    );

    const nextFocusWidth = Math.round(focusSprite.width * (1-ratio) + targetSize * ratio);
    const nextFocusHeight = nextFocusWidth;
    const nextFocusPos = {
      x: cursorPos.current.x - nextFocusWidth / 2,
      y: cursorPos.current.y - nextFocusHeight / 2,
    };

    const scrollTop = document.scrollingElement.scrollTop;

    const nextGradientY = 0;//-0.5*Math.min(scrollTop,window.innerHeight);

    const ease = x=>-(Math.cos(Math.PI * x) - 1) / 2;

    const gradientHeightProgress = ease(ease(Math.min(1,Math.max(0,scrollTop*5 / rendererSize.height - 0.5))));
    // console.log(Math.round(gradientHeightProgress*100),Math.round(ease(gradientHeightProgress)*100));

    const nextGradientHeight = (1-gradientHeightProgress) * rendererSize.height;
    
    setEnableCursor(cursorPos.current.y > nextGradientY+nextGradientHeight);

    if(
      focusSprite.position.x === nextFocusPos.x
      && focusSprite.position.y === nextFocusPos.y
      && Math.round(focusSprite.width) === nextFocusWidth
      && Math.round(focusSprite.height) === nextFocusHeight
      && gradientSprite.position.y === nextGradientY
      && gradientSprite.height === nextGradientHeight
    )
      return;

    focusSprite.width = nextFocusWidth;
    focusSprite.height = nextFocusHeight;
    focusSprite.position = nextFocusPos;
    gradientSprite.position.y = nextGradientY;
    gradientSprite.height = nextGradientHeight;
    try{
      app.render();
    }catch{
      
    }

  },[
    app,
    focusSprite,
    gradientSprite,
    cursorPos,
    lastSpeedMeasuredTimestamp,
    speedMeasurementInterval,
    rendererSize,
    setEnableCursor,
    canvasRef
  ]);
  
  const loop = useCallback(()=>{
    animateFocus();
  },[animateFocus]);

  const portrait = useMediaQuery(mediaQueries.portrait);

  return portrait ? null : (
    <PixiApp ref={canvasRef} {...props} wrapper={Root} appRef={appCallbackRef}
      options={{
        width: rendererSize.width,
        height: rendererSize.height,
      }}
      loop={loop}
      style={{cursor:enableCursor?'default':'none'}}
    >
      <PixiStage interactive={true} on={{mousemove:handleMouseMove}}>
        <PixiSprite from={picture} width={rendererSize.width} height={rendererSize.height} />
        <PixiSprite elementRef={gradientSpriteCallbackRef} from={gradient} width={rendererSize.width} height={rendererSize.height} position={{x:0,y:0}} />
        <PixiSprite from={picture} mask={focusSprite} width={rendererSize.width} height={rendererSize.height} />
        <PixiSprite elementRef={focusSpriteCallbackRef} texture={maskTexture} position={{x:-1000,y:-1000}} />
      </PixiStage>
    </PixiApp>
  );
}
